import React, {useEffect, useRef, useState} from 'react';
import Pagination from '../../components/ui/Pagination';
import { Breadcrumb } from '../../components/ui/Breadcrumb';
import NewsListing from './NewsListing';
import {PaginationNewsType} from '../../store/news';
import NewsService from '../../infrastructure/api/news';
import { PaginationState, usePagination } from '../../hooks/usePagination';
import useAxios from '../../hooks/useAxios';

const breadcrumbItems = [
  { url: '/', name: 'マイページトップ' },
  { url: '/news', name: 'お知らせ一覧' },
];


const News: React.FC = () => {
  const [newsList, setNewsList] = useState<PaginationNewsType>({data: [], lastPage: 0});
  const [search, setSearch] = useState<PaginationState>({ page: 1 });
  const pagination = usePagination<PaginationState>(search, setSearch, newsList.lastPage);
  const isFirstRun = useRef(false);
  const newsService = new NewsService(useAxios());
  
  useEffect(() => {
    if (!isFirstRun.current) {
      fetchNews();
    }
  }, [search.page]);

  useEffect(() => {
    const { searchParams } = pagination;
    const page = searchParams.get('page');
    isFirstRun.current = false;
    setSearch({ page: page ? parseInt(page) : 1 });
    fetchNews();
    return () => {
      isFirstRun.current = true;
    };
  }, []);

  const fetchNews = () => {
    newsService.getListNews(search.page)
      .then((data) => {
        setNewsList(data);
      });
  };
  
  return (
    <main className='sm:mt-4 md:mt-10'>
      <div className='container mx-auto px-2 sm:px-4'>
        <section className='text-center text-2xl font-bold text-primary-brown md:text-3xl'>
          <h1>お知らせ一覧</h1>
        </section>
        <section>
          <ul className='mt-10 flex flex-col xl:px-[5%]'>
            {newsList.data.length > 0 ? (
              newsList.data.map((news) => (
                <NewsListing
                  key={news.id}
                  id={news.id}
                  title={news.title}
                  post_date={news.post_date}
                  category={news.category}
                  body={news.body}
                />
              ))
            ) : (
              <p>No news available.</p>
            )}
          </ul>
        </section>
        <Pagination {...pagination} />
        <Breadcrumb
          items={breadcrumbItems}
          className='mt-10'
        />
      </div>
    </main>
  );
};
export default News;