import React from 'react';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/20/solid';

interface PaginationProps {
  setNext: () => void;
  setPrevious: () => void;
  setFirst: () => void;
  setLast: () => void;
}

const Pagination: React.FC<PaginationProps> = ({ setNext, setLast, setFirst, setPrevious }) => {

  return (
    <section className='mt-5 sm:mt-10'>
      <div className='flex items-center justify-center gap-3 text-primary-brown'>
        <button
          type='button'
          onClick={setFirst}
          className='flex h-8 w-16 items-center justify-center rounded-full border bg-white border-primary-brown sm:h-10 sm:w-20'>
          <ChevronDoubleLeftIcon className='h-6 sm:h-8 sm:w-10' />
        </button>
        <button
          onClick={setPrevious}
          className='flex h-8 w-16 items-center justify-center rounded-full border bg-white text-sm font-bold border-primary-brown sm:h-10 sm:w-20 sm:text-base'>
          前へ
        </button>
        <button
          type='button'
          onClick={setNext}
          className='flex h-8 w-16 items-center justify-center rounded-full border bg-white text-sm font-bold border-primary-brown sm:h-10 sm:w-20 sm:text-base'>
          次へ
        </button>

        <button
          type='button'
          onClick={setLast}
          className='flex h-8 w-16 items-center justify-center rounded-full border bg-white border-primary-brown sm:h-10 sm:w-20'>
          <ChevronDoubleRightIcon className='h-6 sm:h-8 sm:w-10' />
        </button>
      </div>
    </section>
  );
};

export default Pagination;