import React, { useRef, useState } from 'react';
import { Breadcrumb } from '../../components/ui/Breadcrumb';
import { Link } from 'react-router-dom';
import Image from '../../assets/images/mynum-01-min.png';
import Image2 from '../../assets/images/mynum-02-min.png';
import Image3 from '../../assets/images/mynum-03-min.png';
import Image4 from '../../assets/images/mynum-04-min.png';
import ImageOk1 from '../../assets/images/num-ok-01-min.png';
import ImageOk2 from '../../assets/images/num-ok-02-min.png';
import ImageOk3 from '../../assets/images/num-ok-03-min.png';

import ImageOk from '../../assets/images/my-num-ok-min.png';
import ImageNg from '../../assets/images/my-num-ng-min.png';
import FileInput from '../../components/ui/forms/FileInput';
import SelectField from '../../components/ui/forms/SelectField';
import { ChoiceOptionType } from '../../store';
import MyNumberRegistrationCompleted from '../../components/ui/MyNumberRegistrationCompletd';
import { MyNumberDataType, MyNumberImageType, MyNumberType } from '../../store/account';
import UserService from '../../infrastructure/api/user';
import useAxios from '../../hooks/useAxios';

const breadcrumbItems = [
  { url: '/', name: 'マイページトップ' },
  { url: '/account/edit/mynumber-registration', name: 'マイナンバー登録' },
];


export const myNumberTypeOptions: ChoiceOptionType[] = [
  { value: '0', label: 'マイナンバーカード(表・裏)' },
  { value: '1', label: '通知カード(表・裏)' },
  { value: '2', label: '個人番号が記載された住民票の写し' },
];

const MyNumberRegistration: React.FC = () => {
  const buttonRef = useRef<HTMLButtonElement>(null!);
  const [completed, setCompleted] = useState(false);
  const [type, setType] = useState<MyNumberType>('0');
  const [images, setImages] = useState<MyNumberImageType>({ front: null, back: null, resident: null });
  const userService = new UserService(useAxios());

  const handleSubmit = () => {
    let data: MyNumberDataType;
    buttonRef.current.disabled = true;
    if (type === '2') {
      data = {
        status: 'pending',
        'mynumber_images': [
          { 'image_type': 'resident', 'image_data': images.resident },
        ],
      };
    } else {
      data = {
        status: 'pending',
        'mynumber_images': [
          { 'image_type': 'front', 'image_data': images.front },
          { 'image_type': 'back', 'image_data': images.back },
        ],
      };
    }
    userService.uploadMynumber(data)
      .then(() => {
        setCompleted(true);
      })
      .finally(() => {
        buttonRef.current.disabled = false;
      });
  };

  return (
    <React.Fragment>
      {
        !completed ?
          <main className='sm:mt-4 md:mt-10'>
            <div className='container mx-auto px-2 sm:px-4'>
              <section className='text-center text-2xl font-bold text-secondary-main md:text-3xl'>
                <h1>マイナンバー登録</h1>
              </section>
              <section className='mt-12 bg-primary-light'>
                <div className='px-2 py-8 text-center sm:px-[15%]'>
                  <p className='mb-3 text-left'>当社は各種法令で定められた手続きとして、お客様から源泉徴収を行なった金額を税務署に提出しており、
                    その際に必要となる<b>「法定調書へのマイナンバー記載」</b>を目的としてマイナンバーのご登録をご案内しております。</p>
                  <Link to='/account/edit/corporate-registration'
                        className='text-sm font-bold underline underline-offset-4 text-primary-dark sm:text-base'>
                    法人口座の方はこちらのリンクからお進みください
                  </Link>
                </div>
              </section>
              <section className='mt-12 p-2 bg-secondary-light'>
                <div className='bg-white py-3 text-center text-xl text-secondary-main sm:text-2xl'>本人確認の注意事項
                </div>
                <ul className='py-4 text-lg leading-loose sm:px-10 sm:py-8'>
                  <li>■
                    個人番号カードまたは個人番号通知カードをお持ちでない場合は、現住所の確認できる本人確認書類＋マイナンバーが記載された住民票の写しが必要となります。
                  </li>
                  <li>■ ファイルサイズは1枚あたり10MBまでです。</li>
                  <li>■ 文字が読める画像をご撮影ください。</li>
                  <li>■ ファイル形式は、jpeg / png / gifのいずれかとなります。</li>
                  <li>■ 四隅が写るように写真を撮影してください。</li>
                  <li>■ 文字／マイナンバーが隠れていないものを撮影ください。</li>
                </ul>
                <div className='grid grid-cols-2 gap-10 px-5 py-10 sm:grid-cols-4 sm:px-10'>
                  <div>
                    <figure className='relative rounded-md bg-white'>
                      <img src={Image} alt='mynumber-01' />
                      <img className='absolute -top-3 -left-2 h-10 lg:-top-5 lg:-left-5 lg:h-16 max-[400px]:h-8'
                           src={ImageOk}
                           alt='image-good' />
                    </figure>
                    <p className='mt-2 text-center text-xs sm:text-base lg:text-lg'>問題なし</p>
                  </div>
                  <div>
                    <figure className='relative rounded-md bg-white'>
                      <img src={Image2} alt='mynumber-02' />
                      <img className='absolute -top-3 -left-2 h-10 lg:-top-5 lg:-left-5 lg:h-16 max-[400px]:h-8'
                           src={ImageNg}
                           alt='image-not-good' />
                    </figure>
                    <p className='mt-2 text-center text-xs text-accent-brown sm:text-base lg:text-lg'>ぼやけている</p>
                  </div>
                  <div>
                    <figure className='relative rounded-md bg-white'>
                      <img src={Image3} alt='mynumber-03' />
                      <img className='absolute -top-3 -left-2 h-10 lg:-top-5 lg:-left-5 lg:h-16 max-[400px]:h-8'
                           src={ImageNg}
                           alt='image-not-good' />
                    </figure>
                    <p className='mt-2 text-center text-xs text-accent-brown sm:text-base lg:text-lg'>四隅が見切れている</p>
                  </div>
                  <div>
                    <figure className='relative rounded-md bg-white'>
                      <img src={Image4} alt='mynumber-04' />
                      <img className='absolute -top-3 -left-2 h-10 lg:-top-5 lg:-left-5 lg:h-16 max-[400px]:h-8'
                           src={ImageNg}
                           alt='image-not-good' />
                    </figure>
                    <p className='mt-2 text-center text-xs text-accent-brown sm:text-base lg:text-lg'>文字が隠れている</p>
                  </div>
                </div>
              </section>
              <section className='mt-12 bg-primary-light'>
                <div className='px-2 py-8 text-left sm:px-10 sm:text-center'>
                  <p
                    className='font-bold bg-2 sm:mb-3 sm:text-lg lg:text-xl'>マイナンバーの確認に必要な書類種類(組み合わせ)は下記の通りです<br />
                    この3点の中からどれかおひとつを用意し、ご撮影をお願いいたします</p>
                </div>
                <div className='grid gap-10 px-10 py-5 md:grid-cols-3'>
                  <figure>
                    <img src={ImageOk1} alt='' />
                    <p
                      className='mt-2 text-center text-base font-bold text-primary-dark sm:text-lg'>マイナンバーカード(表・裏)</p>
                  </figure>

                  <figure>
                    <img src={ImageOk2} alt='' />
                    <p className='mt-2 text-center text-base font-bold text-primary-dark sm:text-lg'>通知カード(表・裏)</p>
                  </figure>

                  <figure>
                    <img className='object-cover' src={ImageOk3} alt='' />
                    <p
                      className='mt-2 text-center text-base font-bold text-primary-dark sm:text-lg'>個人番号が記載された住民票の写し</p>
                  </figure>

                </div>
              </section>
              <section className='mt-10 border-y-2 bg-white py-5 border-secondary-main'>
                <h3 className='text-center text-xl sm:text-2xl font-bold text-secondary-main'>マイナンバー登録申請フォーム</h3>
                <div className='mt-5 flex w-full items-center justify-center px-[5%]'>
                  <SelectField
                    options={myNumberTypeOptions}
                    valueKey='value'
                    labelKey='label'
                    label='提出する必要書類の画像組み合わせをお選びください'
                    width='max-[400px]:w-full  w-full'
                    className='w-full'
                    name='myNumberType'
                    required={false}
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value as MyNumberType);
                      setImages({ front: null, back: null, resident: null });
                    }}
                  />
                </div>
                {
                  type === '2' ?
                    <div
                      className='mt-8 mb-5 flex flex-wrap items-center justify-center gap-3 px-2 sm:mt-10 sm:mb-10 sm:gap-5 lg:gap-10'>
                      <FileInput value={images.resident} onChange={(image) => {
                        setImages((prev) => ({ ...prev, resident: image }));
                      }} text='表面の撮影画像を添付する' label='クリックしてファイルを選択' name='resident' />
                    </div>
                    :
                    <div
                      className='mt-8 mb-5 flex flex-wrap items-center justify-center gap-3 px-2 sm:mt-10 sm:mb-10 sm:gap-5 lg:gap-10'>
                      <FileInput value={images.front} onChange={(image) => {
                        setImages((prev) => ({ ...prev, front: image }));
                      }} text='表面の撮影画像を添付する' label='クリックしてファイルを選択' name='front' />
                      <FileInput value={images.back} onChange={(image) => {
                        setImages((prev) => ({ ...prev, back: image }));
                      }} text='裏面の撮影画像を添付する' label='クリックしてファイルを選択' name='back' />
                    </div>
                }
                <div className='mt-8 flex flex-wrap items-center justify-center pb-10'>
                  <button
                    ref={buttonRef}
                    disabled={!(!!(images.front && images.back) || !!(type === '2' && images.resident))}
                    onClick={handleSubmit}
                    className={`${(!!(images.front && images.back) || !!(type === '2' && images.resident)) ? 'bg-primary-dark' : 'bg-primary-text'} flex w-4/5 items-center justify-center rounded-full border px-2 text-sm font-bold text-white border-primary-grey py-2.5 sm:w-80 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full`}>
                    マイナンバーの登録申請をする
                  </button>
                </div>
              </section>
              <Breadcrumb items={breadcrumbItems} className='mt-10' />
            </div>
          </main>
          :
          <MyNumberRegistrationCompleted />
      }
    </React.Fragment>
  );
};

export default MyNumberRegistration;