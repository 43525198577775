export type VirtualAccount = {
  vaBankName: string;
  vaHolderNameKana: string;
  vaBranchName: string;
  vaAccountNumber: string;
  vaType: string
}

export const initialVirtualAccount: VirtualAccount = {
  vaAccountNumber: '',
  vaBankName: '',
  vaBranchName: '',
  vaHolderNameKana: '',
  vaType: '',
};

export type WithdrawType = {
  amountAvailable: number;
  inProgressAmount: number;
  bankAccountNumber: string;
  bankName: string;
  branchName: string;
  accountType: string;
  accountHolder: string;
  bankId: number | null;
  branchId: number | null;
}

export const initialWithdraw: WithdrawType = {
  amountAvailable: 0,
  inProgressAmount: 0,
  bankAccountNumber: '',
  bankName: '',
  branchName: '',
  accountType: '',
  accountHolder: '',
  bankId: null,
  branchId: null,
};


export interface DepositWithdrawType extends VirtualAccount, WithdrawType {
}


export const initialDepositWithdraw: DepositWithdrawType = { ...initialWithdraw, ...initialVirtualAccount };