import React, { useEffect, useRef } from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { useLiveQuery } from 'dexie-react-hooks';

// Import all pages
import {
  AccountInformation,
  BankAccount,
  DepositWithdraw,
  EditMemberInformation,
  EditPersonalInformation,
  FundApplication,
  FundList,
  Home,
  News,
  NewsDetails,
  RootPage,
  StepOne,
  StepThree,
  StepTwo,
} from './pages';

// Import services and utilities
import UserService from './infrastructure/api/user';
import { db } from './db';
import { generateRandomString } from './utlis/crypto';
import InvestmentStatus from './pages/investmentStatus';
import InvestmentFundDetails from './pages/investmentStatus/investmentFundDetails';
import { AccountProvider } from './contexts/account';
import ElectronicDocument from './pages/electronicDocument';
import MyNumberRegistration from './pages/account/myNumberRegistration';
import Index from './pages/restructuringFund';
import CorporateNumberRegistration from './pages/account/corporateNumberRegistration';
import DividendSchedule from './pages/dividendSchedule';
import TradingHistory from './pages/tradingHistory';
import EditEmailAddress from './pages/account/editEmailAddress';
import { useNotification } from './hooks/useNotification';
import EditPassword from './pages/account/editPassword';
import { BACKEND_URL, BACKEND_WS_URL } from './store';
import useAxios from './hooks/useAxios';
import { FundProvider } from './contexts/funds';
import {InvestmentCompletedList} from './pages/investmentStatus/investmentCompletedList';
import InvestmentCompletedDetails from './pages/investmentStatus/investmentCompletedDetail';
import WaitingForCancelApplication from './pages/fund/waitingForCancelApplication';
import PendingApplicationDetail from './pages/investmentStatus/pendingApplicationDetail';
import PointDetail from './pages/pointDetail'


// Define the router configuration
const router = createBrowserRouter([
  {
    path: '/',
    element: <RootPage />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: 'account',
        element: <AccountProvider><Outlet /></AccountProvider>,
        children: [
          { index: true, element: <AccountInformation /> },
          {
            path: 'edit',
            children: [
              { path: 'member', element: <EditMemberInformation /> },
              { path: 'personal', element: <EditPersonalInformation /> },
              { path: 'bank', element: <BankAccount /> },
              { path: 'email', element: <EditEmailAddress /> },
              { path: 'password', element: <EditPassword /> },
              { path: 'mynumber-registration', element: <MyNumberRegistration /> },
              { path: 'corporate-registration', element: <CorporateNumberRegistration /> },
            ],
          },
        ],
      },
      {
        path: 'investor-registration',
        children: [
          { path: 'step1', element: <StepOne /> },
          { path: 'step2', element: <StepTwo /> },
          { path: 'step3', element: <StepThree /> },
        ],
      },
      {
        path: 'funds',
        element: <FundProvider><Outlet /></FundProvider>,
        children: [
          { index: true, element: <FundList /> },
          { path: 'application/:slug', element: <FundApplication /> },
          { path: 'application/wfc/:slug', element: <WaitingForCancelApplication />}
        ],
      },
      {
        path: 'news',
        children: [
          { index: true, element: <News /> },
          { path: '/news/:id', element: <NewsDetails /> },
        ],
      },
      {
        path: 'investment-status',
        children: [
          { index: true, element: <InvestmentStatus /> },
          { path: ':pk', element: <InvestmentFundDetails /> },
          { path: 'completed-list', element: <InvestmentCompletedList /> },
          { path: 'completed-list/:pk', element: <InvestmentCompletedDetails /> },
          { path: 'pending-list/:pk', element: <PendingApplicationDetail /> }
        ],
      },
      { path: 'electronic-document', element: <ElectronicDocument /> },
      { path: 'renewal-procedure', element: <Index /> },
      { path: 'deposit-withdraw', element: <DepositWithdraw /> },
      { path: 'dividend-schedule', element: <DividendSchedule /> },
      { path: 'trading-history', element: <TradingHistory /> },
      { path: 'point/detail', element: <PointDetail /> },
    ],
  },
]);

function App() {
  const ref = useRef(false);
  const socketRef = useRef(false);
  const user = useLiveQuery(() => db.user.get(1), []);
  const { incrementUnread } = useNotification();
  const userService = new UserService(useAxios());

  useEffect(() => {
    const initSession = async () => {
      if (!sessionStorage.getItem('session_id')) {
        sessionStorage.setItem('session_id', generateRandomString());

        const data = await userService.whoami();
        db.table('user').put({ ...data, id: 1 });
        if (!data.isAuthenticated) {
          window.location.replace(`${BACKEND_URL}/mypage/login/?next=${window.location.href}`);
        }

        const csrfToken = await userService.getCsrf();
        if (csrfToken) {
          sessionStorage.setItem('csrf_token', csrfToken);
        }
      }
    };

    const updateLastActivity = () => {
      localStorage.setItem('lastActivity', new Date().toString());
    };

    const checkSessionExpiration = () => {
      const lastActivity = localStorage.getItem('lastActivity') || '';
      const diffMs = Math.abs(new Date(lastActivity).valueOf() - new Date().valueOf());
      const minutes = Math.floor(diffMs / 1000 / 60);

      if (minutes === 30) {
        sessionStorage.removeItem('session_id');
        if (!document.hasFocus()) {
          window.addEventListener('focus', reloadPage);
          db.table('user').put({ id: 1, isAuthenticated: false });
        }
      }
    };

    const reloadPage = () => {
      location.reload();
    };

    const setupWebSocket = () => {
      const socket = new WebSocket(`${BACKEND_WS_URL}/ws/some_url/`);

      socket.onopen = () => {
        socketRef.current = true;
        let sessionId = sessionStorage.getItem('session_id');
        if (!sessionId) {
          sessionId = generateRandomString();
          sessionStorage.setItem('session_id', sessionId);
        }
        socket.send(JSON.stringify({ 'session_id': sessionId }));
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (user?.user) {
          for (const field in data) {
            if (field == 'notification') {
              incrementUnread(1);
            } else if (field == 'user') {
              data.user.forEach((v: string | number | boolean, i: number) => {
                if (i % 2 === 1) {
                  const key = `user.${data.user[i - 1]}`;
                  db.table('user').update(1, { [key]: v });
                }
              });
            } else {
              db.table('user').update(1, { [field]: data[field] });
            }
          }
        }
      };

      return socket;
    };

    let socket: WebSocket | undefined;
    if (!socketRef.current && user) {
      socket = setupWebSocket();
    }

    document.addEventListener('mousemove', updateLastActivity);
    document.addEventListener('click', updateLastActivity);

    const interval = setInterval(checkSessionExpiration, 1000 * 60); // Check every minute

    if (user?.isAuthenticated || !sessionStorage.getItem('session_id')) {
      (async () => {
        await initSession();
      })();
    } else {
      if (!ref.current) {
        sessionStorage.removeItem('session_id');
      }
      ref.current = true;
    }

    return () => {
      if (socket) {
        socket.close();
      }
      clearInterval(interval);
      window.removeEventListener('focus', reloadPage);
      localStorage.removeItem('lastActivity');
    };
  }, [user?.isAuthenticated]);

  return (
    <RouterProvider router={router} />
  );
}

export default App;
