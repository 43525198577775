import React from 'react';

type SkeletonBoxProps = {
  classes?: string;
  children?: React.ReactNode;
}

export const SkeletonBox: React.FC<SkeletonBoxProps> = ({ classes = '', children }) => {
  return (
    <div className='animate-pulse'>
      <div className={`bg-gray-200 rounded mb-4 text-transparent ${classes}`}>
        {children}
      </div>
    </div>
  );
};