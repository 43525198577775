import { Axios } from 'axios';
import {RestructuringFundData} from '../../db';

class FundService {
  axios: Axios;
  csrfToken: string;

  constructor(axios: Axios) {
    this.axios = axios;
    this.csrfToken = (sessionStorage.getItem('csrf_token') || '').replace(/(^"|"$)/g, '');
  }

  getFundList() {
    return this.axios.get('/api/funds/')
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        return { funds: [], comingSoon: null };
      });
  }
  
  getDocuments() {
    return this.axios.get('/api/funds/documents/')
      .then((res) => res.data)
      .catch((err) => { throw JSON.parse(err.request.response);})
  }
  
  putRestructuringFund(id:number, data: RestructuringFundData[]) {
    return this.axios.put(`/api/restructuring/${id}/`, data, {
      headers: {
        'X-CSRFToken': this.csrfToken,
      },
    })
      .then((res) => res.data)
      .catch((err) => {throw JSON.parse(err.request.response)})
  }
  
   getContractDocument(slug: string) {
    return this.axios.get(`/api/funds/documents/${slug}/`)
      .then((res) => res.data)
      .catch((err) => { throw JSON.parse(err.request.response);})
  }

}

export default FundService;
