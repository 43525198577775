import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from '../components/ui/Breadcrumb';
import { ArrowDownCircleIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { ElectronicDocumentType, FundDocumentsType } from '../store/funds';
import FundService from '../infrastructure/api/funds';
import useAxios from '../hooks/useAxios';
import { BACKEND_URL } from '../store';

const breadcrumbItems = [
  { url: '/', name: 'マイページトップ' },
  { url: '/electronic-document', name: '電子交付書面' },
];

export type SectionHeaderProp = {
  title?: string;
  className?: string;
  children?: React.ReactNode;
}

export type TitleLayoutProp = {
  name?: string
  title?: string;
  className?: string;
  border?: string;
  children?: React.ReactNode
}

export type DocumentListProp = {
  title?: string;
  className?: string;
  date?: string;
  slug?: string;
  buttonStyle?: string;
}


const TitleLayout: React.FC<TitleLayoutProp> = ({ name, className, children }) => {
  return (
    <React.Fragment>
      <div className={`${className}`}>
        <h3 className='px-5 py-4 text-2xl font-bold text-white'>{name}</h3>
      </div>
      <div className='w-full'>
        {children}
      </div>
    </React.Fragment>
  );
};

export const DropdownLayout: React.FC<SectionHeaderProp> = ({ title, className, children }) => {
  const [showModel, setShowModel] = useState(false);

  const handleModelClick = () => {
    setShowModel(!showModel);
  };
  return (
    <div className='mt-4 px-1'>
      <div
        className={`${className} flex w-full items-center justify-between p-1 max-[450px]:px-3 cursor-pointer transition duration-1000 ease-in-out`}
        onClick={handleModelClick}>
        <p className='font-bold sm:pl-5 sm:text-xl'>{title}</p>
        <div className='items-end'>
            <span
              className='flex items-center rounded-md py-2 pr-3 pl-5 text-sm font-bold text-white sm:py-3 sm:pl-10 sm:text-xl'>
              {showModel ? (<ChevronUpIcon className='h-6 w-6 fill-accent-main sm:h-8 sm:w-8' />) : (
                <ChevronDownIcon className='h-6 w-6 fill-accent-main sm:h-8 sm:w-8' />)}
            </span>
        </div>
      </div>
      {showModel && (
        <div className='mb-5 transition duration-1000 ease-in-out'>
          {children}
        </div>
      )}
    </div>
  );
};


const DocumentList: React.FC<DocumentListProp> = ({ title, className, date, buttonStyle, slug = '' }) => {
  return (
    <li
      className={`${className} flex items-center justify-between border-b-2 bg-white px-2 sm:px-5 py-3 text-base sm:text-xl font-bold text-secondary-main sm:text-primary-text`}>
      <p>{title}</p>
      <div className='flex flex-col items-end justify-end'>
        {date ? (<time className='text-xs font-normal opacity-50 text-primary-text'>{date} 既読</time>) : null}
        <Link to={slug}
              className={`${buttonStyle} rounded-md border-2 px-8 py-2`}>閲覧</Link>
      </div>
    </li>
  );
};

const ElectronicDocument: React.FC = () => {
  const [documents, setDocuments] = useState<ElectronicDocumentType>();
  const fundService = new FundService(useAxios());

  useEffect(() => {
    fundService.getDocuments()
      .then((data) => {
        setDocuments(data);
      });
  }, []);
  return (
    <main className='sm:mt-4 md:mt-10'>
      <div className='container mx-auto px-2 sm:px-4'>
        <section className='mt-12 bg-primary-light'>
          <div className='flex flex-row flex-wrap justify-center gap-5 py-5 sm:gap-10'>
            <Link to={{ hash: 'fundDoc' }}
                  className='flex h-28 w-52 cursor-pointer flex-col items-center justify-center rounded-2xl border-4 bg-white font-bold border-primary-brown text-primary-brown sm:text-lg max-[452px]:w-36 max-[400px]:text-sm'>
              <h2 className='text-center'>出資済ファンド <br /> 関連書面</h2>
              <ArrowDownCircleIcon className='h-6 w-6' />
            </Link>
            <Link to={{ hash: 'privacyDoc' }}
                  className='flex h-28 w-52 cursor-pointer flex-col items-center justify-center rounded-2xl border-4 bg-white font-bold border-secondary-main text-secondary-main lg:text-lg max-[452px]:w-36 max-[400px]:text-sm'>
              <h2 className='text-center'>投資家関連書面</h2>
              <ArrowDownCircleIcon className='h-6 w-6' />
            </Link>
            <Link to={{ hash: 'financialDoc' }}
                  className='flex h-28 w-52 cursor-pointer flex-col items-center justify-center rounded-2xl border-4 bg-white font-bold border-accent-yellow text-accent-yellow lg:text-lg max-[452px]:w-36 max-[400px]:text-sm'>
              <h2>決算報告書</h2>
              <ArrowDownCircleIcon className='h-6 w-6' />
            </Link>
            <Link to={{ hash: 'annualReport' }}
                  className='flex h-28 w-52 cursor-pointer flex-col items-center justify-center rounded-2xl border-4 bg-white font-bold border-primary-grey text-primary-grey lg:text-lg max-[452px]:w-36 max-[400px]:text-sm'>
              <h2>年間取引報告書</h2>
              <ArrowDownCircleIcon className='h-6 w-6' />
            </Link>
          </div>
        </section>
        <section
                 className='mt-14 before:block before:contents-[" "] before:h-24 before:-mt-24 before:invisible'>
          <div  id='fundDoc' className=''>
            <TitleLayout
              name='出資済ファンド関連書面'
              className='bg-primary-brown'
            >
              {
                documents?.invested_funds.map((doc, index) => (
                  <DropdownLayout key={index} title={doc.fund_name}
                                  className='border-2 bg-secondary-brown border-primary-brown'>
                    {
                      Object.keys(doc.documents).map((key, i) => {
                        if (!doc.documents[key as keyof FundDocumentsType]) return <></>
                        if (key === 'file') return (
                          <DocumentList
                            key={i}
                            title='重要事項説明'
                            className='border-primary-brown'
                            buttonStyle='border-primary-dark text-white bg-primary-dark'
                            slug={doc.documents.file}
                          />
                        );
                        return (
                          <DocumentList
                            key={i}
                            title={doc.documents[key as keyof Omit<FundDocumentsType, 'file'>]?.name}
                            className='border-primary-brown'
                            buttonStyle={`border-primary-dark ${doc.documents[key as keyof Omit<FundDocumentsType, 'file'>]?.last_seen ? 'text-primary-dark bg-white' : 'text-white bg-primary-dark'}`}
                            slug={doc.documents[key as keyof Omit<FundDocumentsType, 'file'>]?.url}
                            date={doc.documents[key as keyof Omit<FundDocumentsType, 'file'>]?.last_seen}
                          />
                        );
                      })
                    }
                  </DropdownLayout>
                ))
              }
            </TitleLayout>
          </div>
          <div id='privacyDoc'>
            <TitleLayout
              name='投資家関連書面'
              className='mt-10 bg-secondary-main'
            >
              <DocumentList
                title='反社会的勢力に該当しないことの表明・確約に関する同意事項'
                className='border-primary-grey'
                buttonStyle='border-primary-dark text-white bg-primary-dark'
                slug={`${BACKEND_URL}/antisocial_policy/`}
              />
              <DocumentList
                title='個人情報の取り扱いに関する同意事項'
                className='border-primary-grey'
                buttonStyle='border-primary-dark text-white bg-primary-dark'
                slug={`${BACKEND_URL}/privacy_protection/`}
              />
              <DocumentList
                title='電磁的方法による書面の交付に関する同意事項'
                className='border-primary-grey'
                buttonStyle='border-primary-dark text-white bg-primary-dark'
                slug={`${BACKEND_URL}/mypage/documents/electronic_delivery_description/`}
              />
            </TitleLayout>
          </div>
          <div id='financialDoc'>
            <TitleLayout
              name='決算報告書'
              className='mt-10 bg-accent-yellow'
            >
              <ul>
                {
                  documents?.financial_statements.map((val, i) => (
                    <DocumentList
                      key={i}
                      title={val.name}
                      className='border-accent-yellow'
                      buttonStyle='border-accent-yellow text-accent-yellow'
                      slug={val.url}
                    />
                  ))
                }
              </ul>
            </TitleLayout>
          </div>
          <div id='annualReport'>
            <TitleLayout
              name='年間取引報告書'
              className='mt-10 bg-primary-grey'
            >
              {
                documents?.annual_transaction_reports.map((val, i) => (
                  <DocumentList
                    key={i}
                    title={val.name}
                    className='border-primary-grey'
                    buttonStyle='border-primary-grey text-primary-grey'
                    slug={val.url}
                  />
                ))
              }
            </TitleLayout>
          </div>
        </section>
        <Breadcrumb items={breadcrumbItems} className='mt-10' />
      </div>
    </main>
  );
};

export default ElectronicDocument;