import React, { useEffect, useState } from 'react';
import { InputField } from '../../components/ui/forms/Input';
import { Breadcrumb } from '../../components/ui/Breadcrumb';
import { Link, useNavigate } from 'react-router-dom';
import { TwoColumnTableLayout } from '../../components/layouts/TwoColumnTableLayout';
import { useAccount } from '../../hooks/useAccount';
import { PersonalInformationType } from '../../store/account';
import BackendService from '../../infrastructure/api';
import UserService from '../../infrastructure/api/user';
import { ErrorsType } from '../../components/ui/forms/ErrorList';
import useAxios from '../../hooks/useAxios';
import { db } from '../../db';


const breadcrumbItems = [
  { url: '/', name: 'マイページトップ' },
  { url: '/account', name: '会員情報' },
  { url: '', name: '投資家情報変更' },

];


const EditPersonalInformation: React.FC = () => {
  const { personalInformation, updatePersonalInformation } = useAccount();
  const [state, setState] = useState<PersonalInformationType>(personalInformation);
  const [errors, setErrors] = useState<ErrorsType>();
  const axios = useAxios();
  const userService = new UserService(axios);
  const backendService = new BackendService(axios);
  const navigate = useNavigate();

  const getAddress = () => {
		backendService
			.getAddress(state.zipcode)
			.then((data) => {
				if (data !== 400)
					setState((prev) => ({...prev, ...data}));
			});
	}
  
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    setState(personalInformation);
  }, [personalInformation]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    userService.updatePersonalInfo(state)
      .then(() => {
        db.table('user').update(1, { 'user.fullName': `${state.last_name} ${state.first_name}` });
        updatePersonalInformation(state);
        navigate(-1);
      })
      .catch((error) => {
        const errorList = document.querySelectorAll(`[name=${Object.keys(error)[0]}]`);
        if (errorList)
          errorList[0].scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'start',
          });
        setErrors(error);
      });
  };

  return (
    <main className='sm:mt-4 md:mt-14'>
      <form className='container mx-auto sm:px-4' onSubmit={handleSubmit}>
        <section className='text-center text-2xl font-bold text-secondary-main md:text-3xl'>
          <h1>投資家情報変更</h1>
        </section>
        <section className='mt-12 pb-10'>
          <div
            className='grid w-full items-center px-5 py-5 bg-secondary-bright sm:py-10 max-[450px]:px-3'>
            <p
              className='text-center font-bold text-secondary-main md:text-lg lg:text-xl'>
              投資家情報を変更すると投資家登録済の方も再度投資家登録が必要となります。<br />
              お手数ですが変更後、再度投資家登録をお願いいたします。
            </p>
          </div>
        </section>
        <TwoColumnTableLayout
          title='氏名'
        >
          <div className='mb-3 flex flex-col flex-wrap px-2 py-5 sm:px-8'>
            <div className='flex flex-col xl:flex-row'>
              <InputField
                onChange={handleChange}
                name='last_name'
                value={state.last_name || ''}
                label='姓'
                width='max-[400px]:w-full lg:w-3/4  xl:w-80 sm:mr-10'
                errors={errors}
                required
              />
              <InputField
                onChange={handleChange}
                name='first_name'
                value={state.first_name || ''}
                label='名'
                width='max-[400px]:w-full lg:w-3/4 xl:w-80 sm:mr-10'
                errors={errors}
                required
              />
            </div>
            <div className='flex flex-col xl:flex-row'>
              <InputField
                name='last_name_kana'
                label='セイ(フリガナ)'
                onChange={handleChange}
                value={state.last_name_kana || ''}
                width='max-[400px]:w-full lg:w-3/4 xl:w-80 sm:mr-10'
                errors={errors}
                required
              />
              <InputField
                name='first_name_kana'
                label='メイ(フリガナ)'
                onChange={handleChange}
                value={state.first_name_kana || ''}
                width='max-[400px]:w-full lg:w-3/4 xl:w-80 sm:mr-10'
                errors={errors}
                required
              />
            </div>
          </div>
        </TwoColumnTableLayout>
        <TwoColumnTableLayout
          title='住所'
          className='mt-5'
        >
          <div className='mb-3 flex flex-col flex-wrap px-2 py-5 sm:px-8'>
            <InputField
              name='zipcode'
              label='郵便番号'
              onChange={handleChange}
              value={state.zipcode || ''}
              width='max-[400px]:w-full lg:w-3/4 sm:mr-10'
              errors={errors}
              suggestion='※ハイフン不要、半角数字でご入力ください'
              required
              button={true}
              buttonText='検索'
              onClick={getAddress}
            />
            <InputField
              name='address1'
              label='都道府県'
              onChange={handleChange}
              value={state.address1 || ''}
              width='max-[400px]:w-full lg:w-3/4 sm:mr-10'
              errors={errors}
              required
            />
            <InputField
              name='address2'
              label='市区町村'
              onChange={handleChange}
              value={state.address2 || ''}
              width='max-[400px]:w-full lg:w-3/4 sm:mr-10'
              errors={errors}
              required
            />
            <InputField
              name='address3'
              label='番地'
              onChange={handleChange}
              value={state.address3 || ''}
              width='max-[400px]:w-full lg:w-3/4 sm:mr-10'
              errors={errors}
              required
            />
            <InputField
              name='address4'
              label='建物名・号数'
              onChange={handleChange}
              value={state.address4 || ''}
              width='max-[400px]:w-full lg:w-3/4 sm:mr-10'
              errors={errors}
            />
          </div>
        </TwoColumnTableLayout>
        <TwoColumnTableLayout
          title='生年月日'
          className='mt-5'
        >
          <div className='mb-3 flex flex-col flex-wrap px-2 py-2 sm:px-8'>
            <InputField
              name='birth_date'
              type='date'
              onChange={handleChange}
              value={state.birth_date || ''}
              width='max-[400px]:w-full w-48 sm:mr-10'
              errors={errors}
              required
            />
          </div>
        </TwoColumnTableLayout>

        <section className='mt-16 mb-10 flex flex-wrap items-center justify-center gap-3 sm:gap-5 lg:gap-10'>
          <Link to='/account'
                className='flex w-4/5 items-center justify-center rounded-full border-2 bg-white px-2 text-sm font-bold border-secondary-main py-2.5 text-secondary-main sm:w-72 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
            会員情報ページに戻る
          </Link>
          <button
            type='submit'
            className='flex w-4/5 items-center justify-center rounded-full border-2 px-2 text-sm font-bold text-white border-primary-dark py-2.5 bg-primary-dark sm:w-72 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
            変更する
          </button>
        </section>
        <Breadcrumb items={breadcrumbItems} />
      </form>
    </main>);
};

export default EditPersonalInformation;