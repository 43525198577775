import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import Images from '../../assets/images/calender.jpg';

export type MenuListType = {
  link: string;
  title: string;
  subTitle: string;
  style?: string;
  image?: string;
  icon?: React.ReactNode;
  skip?: boolean;
  external?: boolean;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

interface CustomLinkProps {
  link: string;
  external: boolean;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  children: React.ReactNode;
  className?: string;
}

const CustomLink: React.FC<CustomLinkProps> = ({
                                                 link,
                                                 external,
                                                 children,
                                                 onClick,
                                                 className,
                                               }) => {
  if (external)
    return <a href={link} onClick={onClick} className={className}>{children}</a>;
  return <Link to={link} onClick={onClick} className={className}>{children}</Link>;
};

export const HamburgerMenuList: React.FC<MenuListType> = ({
                                                            link,
                                                            title,
                                                            subTitle,
                                                            image,
                                                            icon,
                                                            style,
                                                            skip,
                                                            onClick,
                                                            external = false,
                                                          }) => {
  return (
    <CustomLink link={link}
                external={external}
                onClick={onClick}
                className={`relative bg-white border-4 rounded-lg p-4 sm:p-5 overflow-hidden border-${style} ${skip ? 'hidden sm:block' : ''}`}>
      <div>
        <p className={`text-${style} text-lg font-bold`}>{title}</p>
        <span className='text-sm'>{subTitle}</span>
      </div>
      <div className='absolute top-3 right-2 opacity-20'>
        <img className='h-16 w-16' src={image} alt='' />
        {icon}s
      </div>
      <div className='absolute top-6 right-0 sm:hidden max-[380px]:top-7'>
        <ChevronRightIcon className={`max-[380px]:h-8 max-[380px]:w-8 h-10 w-10 text-${style}`} />
      </div>
    </CustomLink>
  );
};

export const MyPageMenuList: React.FC<MenuListType> = ({
                                                         link,
                                                         title,
                                                         subTitle,
                                                         image = Images,
                                                         style,
                                                         skip,
                                                         external = false,
                                                       }) => {
  return (
    <CustomLink link={link}
                external={external}
                className={`relative flex bg-white items-center justify-between border-4 rounded-lg p-4 sm:p-5 drop-shadow-[3px_3px_#36711C58] text-${style} border-${style} ${skip ? 'hidden sm:block' : ''}`}>
      <div>
        <p className='text-lg font-bold sm:text-xl lg:text-2xl'>{title}</p>
        <span className='text-sm'>{subTitle}</span>
      </div>
      <div className='opacity-10 sm:mr-10 sm:opacity-100'>
        <img className='h-16 w-16' src={image} alt='' />
      </div>
      <div className='absolute top-1/2 right-0 -translate-y-1/2 transform'>
        <ChevronRightIcon className='h-10 w-10' />
      </div>
    </CustomLink>
  );
};