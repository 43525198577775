import React, {useEffect} from 'react';
import { CheckIcon, HomeIcon } from '@heroicons/react/24/solid';
import { InvestmentCompleted } from './InvestmentCompleted';

export type ApplicationConformationProp = {
  fundName?: string;
  image?: string;
  kuchi: string;
  investAmount: string;
  cash: string;
  point: string;
  completed: boolean;
  handleApply: () => void;
  handleBack: () => void;
  recruitmentType: 'a' | 'b';
}

const ApplicationConformation: React.FC<ApplicationConformationProp> = (state) => {
  const {
    fundName,
    image,
    kuchi,
    investAmount,
    cash,
    point,
    completed,
    handleApply,
    handleBack,
    recruitmentType
  } = state;
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <React.Fragment>
      {
        !completed ?
          <main className='sm:mt-4 md:mt-10'>
            <div className='container mx-auto sm:px-4'>
              <section className='py-1 bg-secondary-main sm:bg-primary-dark'>
                <h2
                  className='bg-white py-4 text-center text-2xl font-bold text-secondary-main sm:text-primary-dark sm:py-8 md:text-3xl'>お申込内容確認</h2>
              </section>
              <section className='mt-5 md:mt-10'>
                <div className='hidden items-center sm:inline-flex'>
                  <HomeIcon className='h-10 w-10' />
                  <h3 className='text-2xl font-bold'>{fundName}</h3>
                </div>
                <figure
                  className='relative h-80 overflow-hidden px-2 sm:hidden max-[500px]:h-full'>
                  <img className='h-full w-full rounded-2xl object-cover' src={image} alt='' />
                </figure>
                <p
                  className='mt-10 mb-5 hidden text-lg font-bold text-accent-brown sm:flex'>お申込される内容を必ずご確認ください</p>
                <div className='mt-5 flex h-full flex-col sm:gap-5 lg:flex-row'>
                  <div className='flex h-full w-full flex-col lg:w-[48%]'>
                    <div className='flex flex-col bg-white sm:mb-1 sm:flex-row'>
                      <p
                        className='px-4 py-2 font-bold text-white bg-secondary-main sm:w-44 sm:text-center sm:text-xl'>出資口数</p>
                      <p
                        className='flex-1 border bg-white px-4 py-2 border-secondary-brown sm:px-2 sm:text-lg sm:font-bold lg:text-xl'>{kuchi}<span>口</span>
                      </p>
                    </div>
                    <div className='flex flex-col sm:flex-row'>
                      <p
                        className='px-4 py-2 font-bold text-white bg-secondary-main sm:w-44 sm:text-center sm:text-xl'>出資金額</p>
                      <p
                        className='flex-1 border bg-white px-4 py-2 border-secondary-brown sm:px-2 sm:text-lg sm:font-bold lg:text-xl'>{investAmount}<span>円</span>
                      </p>
                    </div>
                  </div>
                  <div className='w-full lg:w-[48%]'>
                    <div
                      className='flex h-full flex-col border-2 border-secondary-main sm:mb-1 sm:flex-row sm:items-stretch'>
                      <p
                        className='flex items-start px-4 py-2 font-bold bg-secondary-bright text-secondary-main sm:w-32 sm:items-center sm:justify-center sm:px-2 sm:text-lg lg:text-xl'>内訳</p>
                      <div className='flex w-full flex-col items-stretch justify-center bg-white px-4 sm:px-2'>
                        <p className='flex items-center justify-between py-2 sm:text-lg sm:font-bold lg:text-xl'>
                          <span>現金</span>
                          <span>{cash}<span>円</span></span>
                        </p>
                        <p className='flex items-center justify-between py-2 sm:text-lg sm:font-bold lg:text-xl'>
                          <span>トモタクポイント利用</span>
                          <span>{point || 0}<span>pt</span></span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className='px-5 sm:px-0'>
                <div className='mt-5 border-2 bg-white py-8 border-secondary-main lg:mt-10'>
                  <h3
                    className='text-center font-bold text-secondary-main sm:text-xl lg:text-2xl'>確認済の契約書面・規約等</h3>
                  <div
                    className='mt-6 flex flex-col justify-center gap-5 font-semibold px-[10%] md:flex-row md:px-0 lg:gap-10 max-[400px]:px-4'>
                    <div className='flex gap-3 sm:text-lg'>
                      <div className='relative h-5 w-5 rounded-full border-2 border-primary-dark sm:h-7 sm:w-7'>
                        <CheckIcon className='-mt-2 h-6 w-6 font-bold text-primary-dark sm:h-8 sm:w-8' />
                      </div>
                      個人情報保護方針規約
                    </div>
                    <div className='flex gap-3 sm:text-lg'>
                      <div className='relative h-5 w-5 rounded-full border-2 border-primary-dark sm:h-7 sm:w-7'>
                        <CheckIcon className='-mt-2 h-6 w-6 font-bold text-primary-dark sm:h-8 sm:w-8' />
                      </div>
                      契約成立前書面
                    </div>
                    <div className='flex gap-3 sm:text-lg'>
                      <div className='relative h-5 w-5 rounded-full border-2 border-primary-dark sm:h-7 sm:w-7'>
                        <CheckIcon className='-mt-2 h-6 w-6 font-bold text-primary-dark sm:h-8 sm:w-8' />
                      </div>
                      電子取引業務による重要事項
                    </div>
                  </div>
                </div>
              </section>

              <section className='mt-5 px-5 py-5 text-sm bg-primary-light sm:mt-10 sm:px-10 sm:py-10 sm:text-base'>
                <ul className='leading-7'>
                  <li>※1口あたりの金額は100,000円（10万円）です。金額をご確認の上、お申込ください。</li>
                  <li>※先着申込または当選確定後にキャンセルされた場合、今後お申込をお受けできない場合がございますのでご注意ください。</li>
                  <li>※抽選でのお申込の場合、お一人様1回限りとさせていただきます。ご了承ください。</li>
                  <li>※出資申込後の出資口数の変更はできません。</li>
                  <li>※出資申込後にトモタクポイントの使用ポイント数を変更することはできません。トモタクポイントを変更する場合は「戻る」ボタンから出資申込画面に戻り、使用ポイント数を入力し直してください。</li>
                </ul>
              </section>
              <p
                className='mt-5 px-3 font-bold sm:mt-10 sm:px-10 sm:text-center sm:text-lg'>上記のご内容で問題なければ「出資申込をする」ボタンを押してください</p>
              <section
                className='mt-5 mb-10 flex flex-col flex-wrap items-center justify-center gap-3 px-2 sm:mt-10 sm:gap-5 sm:px-0 lg:gap-10'>
                <button
                  onClick={handleApply}
                  className='flex w-4/5 items-center justify-center rounded-full border-2 px-2 font-bold text-white border-primary-dark py-2.5 bg-primary-dark sm:w-96 sm:border-4 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
                  出資申込をする
                </button>
                <button 
                  onClick={handleBack}
                  className='flex w-4/5 items-center justify-center rounded-full border-2 px-2 font-bold border-primary-brown py-2.5 text-primary-brown sm:w-96 sm:border-4 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full bg-white'>
                  お申込入力画面に戻る
                </button>
              </section>
            </div>
          </main> :
          <InvestmentCompleted fundName={fundName} lottery={recruitmentType === 'b'} />
      }
    </React.Fragment>
  );
};

export default ApplicationConformation;