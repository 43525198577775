import React, {useEffect, useState} from 'react';
import {Breadcrumb} from '../../components/ui/Breadcrumb';
import {useParams} from 'react-router-dom';
import UserService from '../../infrastructure/api/user';
import useAxios from '../../hooks/useAxios';
import {InvestmentDetailFundType} from '../../store/account';
import {numberWithCommas} from '../../utlis/numbers';
import {convertNumberWeekday} from '../../utlis/date';


const breadcrumbItems = [
	{url: '/', name: 'マイページトップ'},
	{url: '/investment-status', name: '出資状況'}
];


interface FundDataTableProp {
	name?: string;
	children?: React.ReactNode;
}

export const FundDataHeader: React.FC<FundDataTableProp> = ({name, children}) => {
	return (
		<tr className='flex mb-[1px]'>
			<th
				className='w-24 px-2 text-left text-white py-1.5 bg-secondary-main border-secondary-brown sm:px-0.5 sm:w-44 sm:border sm:py-2 sm:text-center sm:text-lg'>{name}
			</th>
			<td className='flex-1 border bg-white px-2 py-1.5 border-secondary-brown sm:py-2 sm:text-lg'>
				{children}
			</td>
		</tr>
	);
};

const InvestmentFundDetails: React.FC = () => {
	const {pk} = useParams();
	const userService = new UserService(useAxios());
	const [state, setState] = useState<InvestmentDetailFundType>();
	useEffect(() => {
		if (pk) {
			userService.getInvestmentDetail(pk)
				.then((data) => {
					if (data) {
						setState(data);
					}
				});
		}
	}, [pk]);
	if (!state) return <></>;
	return (
		<main className='sm:mt-4 md:mt-10'>
			<div className='container mx-auto sm:px-4'>
				<section className='mt-10 sm:mt-14'>
					<h2 className='mb-7 text-xl font-bold text-secondary-main sm:text-3xl'>{state.fund_name}</h2>
					<div className='grid grid-cols-1 justify-center gap-5 md:grid-cols-2 lg:gap-8'>
						<figure
							className='relative h-64 overflow-hidden sm:h-80 sm:rounded-2xl lg:h-[300px] xl:h-[350px] max-[500px]:h-full'>
							<img className='h-full w-full object-cover' src={state.thumbnail} alt=''/>
						</figure>
						<div>
							<div className='px-1 sm:px-0'>
								{
									state.next_dividend &&
                    <div className='font-bold opacity-50 text-primary-text sm:text-lg'>
                        <p>次回分配予定日：{convertNumberWeekday(state.next_dividend.receive_date)}({state.next_dividend.order}/{state.dividends.length}回目)</p>
                        <p>予定分配金：{numberWithCommas(state.next_dividend.receive_money)}円(税引前)</p>
                    </div>
								}
								<table className='mt-1 w-full border-separate border-spacing-1'>
									<tbody>
									<FundDataHeader name='出資日'>{state.def_time}</FundDataHeader>
									<FundDataHeader name='出資口数'>{state.kuchi}口</FundDataHeader>
									<FundDataHeader name='出資総額'>{numberWithCommas(state.amount_of_investment)}円</FundDataHeader>
									<FundDataHeader name='運用期間'><p>{state.duration}{state.duration_type}</p>
										<small
											className='text-primary-grey'>{state.operation_start}〜{state.operation_end}</small></FundDataHeader>
									<FundDataHeader name='予定分配率'>{state.percent}%(年利)※税引前</FundDataHeader>

									</tbody>
								</table>
								<span
									className='text-sm'>※運用期間中にファンドの物件売却が決定し、出資金の早期償還と配当のお支払いを行うことがございます。</span>
							</div>
						</div>
					</div>
				</section>
				<section className='mt-10'>
					<div className='grid grid-cols-1 gap-10 lg:grid-cols-2'>
						<div className='bg-white px-3 py-5 sm:rounded-2xl sm:px-5'>
							<h3 className='mb-2 text-lg font-bold text-secondary-main sm:text-xl lg:text-2xl'>配当スケジュール</h3>
							<ul className='px-3 sm:px-10'>
								{
									state.dividends.map((dividend, index) => (
										<li className='pb-4' key={index}>
											<p className='text-base font-bold tracking-wide sm:text-lg'>{index + 1}回目 <span
												className='pl-3'>{convertNumberWeekday(dividend.receive_date)}</span>
											</p>
											<p
												className='text-xs opacity-50 sm:pl-10'>配当予定金額(税引前):{numberWithCommas(dividend.receive_money)}円</p>
										</li>
									))
								}
							</ul>
						</div>
						<div className='px-3 py-5 bg-secondary-light sm:rounded-2xl sm:px-5'>
							<h3 className='mb-4 text-lg font-bold text-secondary-main sm:text-xl lg:text-2xl'>配当スケジュール</h3>
							<p className='mb-2'>
								{
									state.fund_type === 'T' ?
										'預託金口座に反映されます。 ご出金されたい場合は出金ページより出金申請を行ってください。'
										:
										'書面契約を交わした際にご提出頂いた口座へお振込をいたします。'
								}

							</p>
							<span
								className='text-xs'>※出資法により、元本と配当を保証をすることは禁止されております。あくまで配当予定としてご理解ください。</span>
						</div>
					</div>
				</section>
				<section className='mt-10'>
					<ul>
						<li className='border-2 bg-white px-3 py-4 border-secondary-main sm:px-5'>
							<p className='text-xl font-bold text-secondary-main sm:text-2xl'>交付書面</p>
						</li>
						{
							state.fund_type === 'R' ?
								<>
									<li
										className='flex items-center justify-between border-b-2 bg-white px-2 py-3 text-base font-bold text-secondary-main border-secondary-main sm:text-primary-text sm:px-5 sm:text-xl'>
										<p>重要事項説明</p>
										<div className='flex flex-col items-end justify-end'>
											<a href={state.paper_file || ''}
											   className='rounded-md border-2 bg-white px-8 py-2 border-secondary-main text-secondary-main'>閲覧</a>
										</div>
									</li>
									{
										state.users_property_management_report?.map((val, i) => (
											<li
												key={i}
												className={`flex items-center justify-between border-b-2 px-2 py-3 border-secondary-main text-base text-secondary-main font-bold sm:text-primary-text sm:px-5 sm:text-xl ${val.last_seen ? 'bg-white' : 'bg-secondary-light'}`}>
												<p>{val.name}</p>
												<div className='flex flex-col items-end justify-end'>
													{val.last_seen && <time
                            className='text-xs font-normal opacity-50 text-primary-text'>{val.last_seen} 既読</time>}
													<a href={val.url}
													   className={`rounded-md border-2 px-8 py-2 border-secondary-main ${val.last_seen ? 'bg-white text-secondary-main' : 'bg-secondary-main text-white'}`}>閲覧</a>
												</div>
											</li>
										))
									}
								</> :
								<>
									{
										state.for_user &&
                      <li
                          className={`flex items-center justify-between border-b-2 px-2 py-3 border-secondary-main text-base text-secondary-main font-bold sm:text-primary-text sm:px-5 sm:text-xl ${state.for_user.last_seen ? 'bg-white' : 'bg-secondary-light'}`}>
                          <p>{state.for_user.name}</p>
                          <div className='flex flex-col items-end justify-end'>
		                          {state.for_user.last_seen && <time
                            className='text-xs font-normal opacity-50 text-primary-text'>{state.for_user.last_seen} 既読</time>}
                              <a href={state.for_user.url}
                                 className={`rounded-md border-2 px-8 py-2 border-secondary-main ${state.for_user.last_seen ? 'bg-white text-secondary-main' : 'bg-secondary-main text-white'}`}>閲覧</a>
                          </div>
                      </li>
									}
									{
										state.users_contract_documents &&
                      <li
                          className={`flex items-center justify-between border-b-2 px-2 py-3 border-secondary-main text-base text-secondary-main font-bold sm:text-primary-text sm:px-5 sm:text-xl ${state.users_contract_documents.last_seen ? 'bg-white' : 'bg-secondary-light'}`}>
                          <p>{state.users_contract_documents.name}</p>
                          <div className='flex flex-col items-end justify-end'>
		                          {state.users_contract_documents.last_seen && <time
                            className='text-xs font-normal opacity-50 text-primary-text'>{state.users_contract_documents.last_seen} 既読</time>}
                              <a href={state.users_contract_documents.url}
                                 className={`rounded-md border-2 px-8 py-2 border-secondary-main ${state.users_contract_documents.last_seen ? 'bg-white text-secondary-main' : 'bg-secondary-main text-white'}`}>閲覧</a>
                          </div>
                      </li>
									}
									{
										state.anonymous_partnership_contracts &&
                      <li
                          className={`flex items-center justify-between border-b-2 px-2 py-3 border-secondary-main text-base text-secondary-main font-bold sm:text-primary-text sm:px-5 sm:text-xl ${state.anonymous_partnership_contracts.last_seen ? 'bg-white' : 'bg-secondary-light'}`}>
                          <p>{state.anonymous_partnership_contracts.name}</p>
                          <div className='flex flex-col items-end justify-end'>
		                          {state.anonymous_partnership_contracts.last_seen && <time
                            className='text-xs font-normal opacity-50 text-primary-text'>{state.anonymous_partnership_contracts.last_seen} 既読</time>}
                              <a href={state.anonymous_partnership_contracts.url}
                                 className={`rounded-md border-2 px-8 py-2 border-secondary-main ${state.anonymous_partnership_contracts.last_seen ? 'bg-white text-secondary-main' : 'bg-secondary-main text-white'}`}>閲覧</a>
                          </div>
                      </li>
									}
									{
										state.electronic_transaction &&
                      <li
                          className={`flex items-center justify-between border-b-2 px-2 py-3 border-secondary-main text-base text-secondary-main font-bold sm:text-primary-text sm:px-5 sm:text-xl ${state.electronic_transaction.last_seen ? 'bg-white' : 'bg-secondary-light'}`}>
                          <p>{state.electronic_transaction.name}</p>
                          <div className='flex flex-col items-end justify-end'>
		                          {state.electronic_transaction.last_seen && <time
                            className='text-xs font-normal opacity-50 text-primary-text'>{state.electronic_transaction.last_seen} 既読</time>}
                              <a href={state.electronic_transaction.url}
                                 className={`rounded-md border-2 px-8 py-2 border-secondary-main ${state.electronic_transaction.last_seen ? 'bg-white text-secondary-main' : 'bg-secondary-main text-white'}`}>閲覧</a>
                          </div>
                      </li>
									}
									{
										state.crowd_property_management_report?.map((val, i) => (
											<li
												key={i}
												className={`flex items-center justify-between border-b-2 px-2 py-3 border-secondary-main text-base text-secondary-main font-bold sm:text-primary-text sm:px-5 sm:text-xl ${val.last_seen ? 'bg-white' : 'bg-secondary-light'}`}>
												<p>{val.name}</p>
												<div className='flex flex-col items-end justify-end'>
													{val.last_seen && <time
                            className='text-xs font-normal opacity-50 text-primary-text'>{val.last_seen} 既読</time>}
													<a href={val.url}
													   className={`rounded-md border-2 px-8 py-2 border-secondary-main ${val.last_seen ? 'bg-white text-secondary-main' : 'bg-secondary-main text-white'}`}>閲覧</a>
												</div>
											</li>
										))
									}
								</>
						}
					</ul>
				</section>
				<Breadcrumb
					items={[...breadcrumbItems, {url: '', name: state.fund_name}]}
					className='mt-10'
				/>
			</div>
		</main>
	);
};
export default InvestmentFundDetails;

