import { useEffect } from 'react';
import { authAxios } from '../infrastructure/api/axios';
import { db } from '../db';
import { useLoading } from './useLoading';

const useAxios = () => {
  const {stopLoading, startLoading, incrementActiveLoading, decrementActiveLoading} = useLoading();
  useEffect(() => {
    const requestIntercept = authAxios.interceptors.request.use(
      (config) => {
        startLoading();
        incrementActiveLoading();
        return config;
      },
      (error) => Promise.reject(error)
    );
    
    const responseIntercept = authAxios.interceptors.response.use(
      (response) => {
        decrementActiveLoading()
        stopLoading();
        return response;
      },
      async (error) => {
        decrementActiveLoading();
        stopLoading();
        if (error?.response?.status === 429) {
          alert(JSON.parse(error.request.response).detail)
        }
        else if ((error?.response?.status === 401 || error?.response?.status === 403)) {
          sessionStorage.removeItem('session_id');
          sessionStorage.removeItem('csrf_token');
          db.table('user').put({ id: 1, isAuthenticated: false });
        }
        return Promise.reject(error);
      },
    );
    return () => {
      authAxios.interceptors.request.eject(requestIntercept);
      authAxios.interceptors.response.eject(responseIntercept);
    };
  }, []);

  return authAxios;
};

export default useAxios;