import { useCallback, useContext } from 'react';
import { FundContext } from '../contexts/funds';

export const useFund = () => {
  const [state, setState] = useContext(FundContext);
  const { funds, comingSoon } = state;
  
  if (!FundContext) {
    throw new Error('useNotification should be used inside NotificationProvider');
  }
  
  
  return {
    funds,
    comingSoon,
    setState
  };
};