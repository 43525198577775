import React from 'react';

type TwoColumnTableLayout = {
  title?: string;
  className?: string;
  children?: React.ReactNode;
}

export const TwoColumnTableLayout: React.FC<TwoColumnTableLayout> = ({ title, children, className }) => {
  return (
    <section className={`${className} md:border-2 border-gray-400 flex flex-col md:flex-row w-full`}>
      <div
        className='flex w-full items-center justify-center border bg-gray-200 py-2 text-lg font-bold border-secondary-brown md:w-2/6 md:border-none lg:w-1/5'>
        {title}
      </div>
      <div className='w-full bg-white md:w-4/5 md:border-l-2 md:border-gray-400'>
        {children}
      </div>
    </section>
  );
};