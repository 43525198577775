import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {DownArrow} from '../../components/icons/DownArrow';
import {UpArrow} from '../../components/icons/UpArrow';
import {Transition} from '@headlessui/react';
import {FundListType, FundType, RecruitingType} from '../../store/funds';
import {convertToNumber, numberWithCommas} from '../../utlis/numbers';
import {Progress} from './ProgressBar';
import {parseJapaneseDateTime} from '../../utlis/date';
import {BACKEND_URL} from '../../store';
import {useLiveQuery} from 'dexie-react-hooks'
import {db} from '../../db'

interface SimulationProps {
  totalDays: number;
  duration: string;
  durationType: string;
  percent: number;
}

export const Simulation: React.FC<SimulationProps> = ({totalDays, duration, durationType, percent}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [amount, setAmount] = useState<number>(0);
  const tdRefs = useRef<(HTMLTableDataCellElement | null)[]>(Array(6).fill(null));

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const revenueBefore = Math.round(totalDays * (amount * 10000 * (percent * 0.01) / 365));
    const revenueTax = Math.round(revenueBefore * 0.2042);
    const revenueAfter = Math.round(revenueBefore - revenueTax);
    const bankBefore = Math.round(totalDays * (amount * 10000 * (0.002 * 0.01) / 365));
    const bankTax = Math.round(bankBefore * 0.2042);
    const bankAfter = Math.round(bankBefore - bankTax);
    if (tdRefs.current[0])
      tdRefs.current[0].innerHTML = `${numberWithCommas(bankBefore) || '0'}円`;
    if (tdRefs.current[1])
      tdRefs.current[1].innerHTML = `${numberWithCommas(revenueBefore || '0')}円`;
    if (tdRefs.current[2])
      tdRefs.current[2].innerHTML = `△${numberWithCommas(bankTax) || '0'}円`;
    if (tdRefs.current[3])
      tdRefs.current[3].innerHTML = `△${numberWithCommas(revenueTax) || '0'}円`;
    if (tdRefs.current[4])
      tdRefs.current[4].innerHTML = `${numberWithCommas(bankAfter) || '0'}円`;
    if (tdRefs.current[5])
      tdRefs.current[5].innerHTML = `${numberWithCommas(revenueAfter) || '0'}円`;

  }, [amount]);

  return (
    <div>
      <div
        className={`mt-2 transition-opacity ease-in flex w-full justify-end px-2 sm:px-0 ${!isOpen ? 'opacity-100' : 'opacity-0'}`}>
        <div className='inline-flex items-center font-bold cursor-pointer' onClick={handleButtonClick}>
          <span>投資シミュレーション</span>
          <DownArrow className='h-5 w-5 text-primary-brown sm:h-6 sm:w-6'/>
        </div>
      </div>
      <div className='mt-5 mb-2 lg:px-[3%] overflow-hidden'>
        <Transition
          show={isOpen}
          enter='transition-max-height ease-in duration-300'
          enterFrom='max-h-0'
          enterTo='max-h-[500px]'
          leave='transition-max-height ease-out duration-300'
          leaveFrom='max-h-[500px]'
          leaveTo='max-h-0'
        >
          <div id='simulation' className='max-[480px]:px-1.5 px-3 py-5 bg-primary-light md:px-10 lg:col-span-2'>
            <div>
              <div className='flex w-full flex-wrap items-end justify-end pb-2 sm:items-center sm:justify-between'>
                <p
                  className='text-lg font-bold text-primary-brown lg:text-xl'>投資シミュレーション({duration}{durationType})</p>
                <label htmlFor='input font-bold'> 投資予定額
                  <input
                    value={numberWithCommas(amount)}
                    onChange={(e) => {
                      setAmount(convertToNumber(e.target.value) || 0);
                    }}
                    className='mx-1 border border-gray-300 px-2 py-1 w-[120px] sm:w-[200px]'
                    type='text'
                    maxLength={19}
                    name='input'
                  />
                  <span className='text-base'>万円</span>
                </label>
              </div>
            </div>
            <table className='w-full border-2 text-center bg-white border-[#8D8D8D] text-xs sm:text-base lg:text-lg'>
              <tbody>
              <tr>
                <td className='border-2 border-gray-400 px-1 py-1.5'></td>
                <td className='border-2 border-gray-400 px-1 py-1.5'>銀行定期</td>
                <td
                  className='border-2 border-gray-400 px-1 font-bold py-1.5'>TOMOTA<span
                  className='text-primary-dark'>Q</span>U
                </td>
              </tr>
              <tr>
                <td
                  className='border-2 border-gray-400 px-1 font-bold py-1.5'>税引前収益
                </td>
                <td className='border-2 border-gray-400 px-1 text-right py-1.5' ref={(e) => {
                  tdRefs.current[0] = e;
                }}>
                </td>
                <td
                  className='border-2 border-gray-400 px-1 text-right font-bold py-1.5' ref={(e) => {
                  tdRefs.current[1] = e;
                }}>
                </td>
              </tr>
              <tr>
                <td
                  className='border-2 border-gray-400 px-1 font-bold py-1.5'>△税金(源泉徴収)
                </td>
                <td className='border-2 border-gray-400 px-1 text-right py-1.5' ref={(e) => {
                  tdRefs.current[2] = e;
                }}>
                </td>
                <td
                  className='border-2 border-gray-400 px-1 text-right font-bold py-1.5' ref={(e) => {
                  tdRefs.current[3] = e;
                }}>
                </td>
              </tr>
              <tr>
                <td className='border-2 border-gray-400 px-1 font-bold py-1.5'>税引後収益
                </td>
                <td className='border-2 border-gray-400 px-1 text-right font-bold py-1.5' ref={(e) => {
                  tdRefs.current[4] = e;
                }}>
                </td>
                <td
                  className='border-2 border-gray-400 px-1 text-right font-bold py-1.5 text-primary-dark' ref={(e) => {
                  tdRefs.current[5] = e;
                }}>
                </td>
              </tr>
              </tbody>
            </table>
            <ul className='mt-2 ml-5 list-disc text-sm'>
              <li><p>試算数値はあくまでもシミュレーションであり、将来の成果を約束するものではありません。</p></li>
              <li><p>銀行定期はメガバンク数行の定期預金(1年)のデータをもとに比較しています。</p></li>
              <li><p>シミュレーションの結果は概算値です。</p></li>
              <li><p>2037年12月までの税金(源泉税)には、復興特別所得税が含まれます。</p></li>
            </ul>
          </div>
          <div className='mt-2 flex w-full justify-end px-2 sm:px-0'>
            <div className='inline-flex items-center font-bold cursor-pointer' onClick={handleButtonClick}>
              <span>投資シュミレーション</span>
              <UpArrow className='h-5 w-5 text-primary-brown sm:h-6 sm:w-6'/>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};

interface RecruitingFundProps {
  funds: FundType[];
  state: FundType;
  setState: React.Dispatch<React.SetStateAction<FundListType>>;
}

interface RecruitmentTimeProps extends Omit<RecruitingFundProps, 'state'> {
  recruit: RecruitingType;
}

export const RecruitmentTimer: React.FC<RecruitmentTimeProps> = ({recruit, setState, funds}) => {
  const targetTime = useMemo(() => parseJapaneseDateTime(recruit.start).getTime(), [recruit.start]);

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const timeDifference = targetTime - now;

    if (timeDifference <= 0) {
      setCountdown(`${recruit.name_display}募集開始！`);
      const cpFund = [...funds];
      funds.every((fund, i) => {
        const recruitIndex = fund.recruiting.findIndex((rec) => rec.id === recruit.id);
        if (recruitIndex !== -1) {
          cpFund[i]['current_status'] = '募集中';
          cpFund[i]['current_recruitment'] = recruit;
          return false;
        }
        return true;
      });
      setState((prev) => ({...prev, funds: cpFund}));
    } else {
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      let countdownStr = '';
      if (days > 0) {
        countdownStr += `あと${days}日`;
      }
      if (hours > 0) {
        countdownStr += ` ${hours}時間`;
      }
      if (minutes > 0) {
        countdownStr += ` ${minutes}分`;
      }
      countdownStr += ` ${seconds}秒で${recruit.name_display}募集開始！`;
      setCountdown(countdownStr);
    }
  };

  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    const interval = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(interval);
  }, [recruit.start]);

  if (!countdown) {
    return <></>;
  }

  return (
    <div className='px-1 py-2 text-lg font-semibold bg-secondary-brown text-primary-dark sm:px-3'>
      {countdown}
    </div>
  );
};

export const Recruiting: React.FC<RecruitingFundProps> = ({state, setState, funds}) => {
  const user = useLiveQuery(() => db.user.get(1), []);

  return (
    <div className='relative'>
      <div className='mt-10 grid grid-cols-1 justify-center gap-5 sm:mt-14 md:grid-cols-2 lg:gap-10'>
        <figure
          className='relative h-72 overflow-hidden sm:h-80 sm:rounded-2xl lg:h-[300px] xl:h-[350px] max-[500px]:h-full'>
          <img className='h-full w-full object-cover' src={state.thumbnail} alt=''/>
          <span
            className='absolute top-5 left-5 px-3 py-1 font-bold text-white bg-primary-dark'>{state.current_status}</span>
        </figure>
        <div>
          <h3
            className='px-1 pt-1 pb-3 text-lg font-semibold sm:px-0 sm:text-xl lg:text-2xl'>{state.fund_name}</h3>
          {
            state.current_status === '募集前' && state.next_recruitment &&
            <RecruitmentTimer recruit={state.next_recruitment} setState={setState} funds={funds}/>
          }
          {
            state.recruiting.filter((val) => val.finished || val.id === state.current_recruitment?.id)
              .map((val, index) => (
                <div key={index} className={(val.name === 'a' && val.will_use_wfc ? 'border-dashed border-gray-200 border' : '') + ' p-2'}>
                  <Progress
                    order={index + 1}
                    progress={val.name === 'a' ? 100 - ((val.units_remainder / val.units) * 100) : +((val.units_remainder / val.units) * 100).toFixed(2)}
                    color={val.finished ? 'bg-primary-grey' : 'bg-progress'}
                    label={state.recruiting.length > 1 && state.current_recruitment?.id !== val.id}
                  />
                  {
                    val.name === 'a' && val.will_use_wfc
                    &&
                    <Progress
                      width={'w-1/2'}
                      labelTitle='キャンセル待ち申込率'
                      progress={100 - ((val.wfc_units_remainder / val.wfc_units) * 100)}
                      color={val.finished ? 'bg-red-200' : 'bg-red-300'}
                    />
                  }
                </div>
              ))
          }
          <div className='px-1 sm:px-0'>
            <table className='mt-3 w-full border-separate bg-white border-spacing-0.5'>
              <tbody>
              <tr>
                <th className='w-28 border py-2 text-white bg-secondary-main border-secondary-brown px-0.5 sm:w-32'>募集形式
                </th>
                <td className='border px-2 border-secondary-brown'>
                  {
                    [...state.recruiting].map((val, index) => (
                      <span
                        key={index}
                        className={`${!val.finished ? val.id === state.current_recruitment?.id ? 'font-bold' : 'font-semibold' : ''} after:content-['／'] last:after:hidden`}>{state.recruiting.length > 1 ? `${index + 1}次募集:${val.name_display}` : val.name_display}</span>
                    ))
                  }
                </td>
              </tr>
              <tr>
                <th
                  className='w-28 border py-2 text-white bg-secondary-main border-secondary-brown px-0.5 sm:w-32'>募集金額
                </th>
                <td
                  className='border px-2 border-secondary-brown'>{numberWithCommas(state.amount_raised)}<span>万円</span>
                </td>
              </tr>
              <tr>
                <th
                  className='w-28 border py-2 text-white bg-secondary-main border-secondary-brown px-0.5 sm:w-32'>予定分配率
                </th>
                <td className='border px-2 border-secondary-brown'>{state.percent}<span>%(年利)※税引前</span></td>
              </tr>
              <tr>
                <th
                  className='w-28 border py-2 text-white bg-secondary-main border-secondary-brown px-0.5 sm:w-32'>運用期間
                </th>
                <td className='border px-2 border-secondary-brown'>
                  {state.duration}{state.duration_type}
                  <p className='opacity-50 text-xs'>{state.operation_start}~{state.operation_end}</p>
                </td>
              </tr>
              <tr>
                <th
                  className='w-28 border py-2 text-white bg-secondary-main border-secondary-brown px-0.5 sm:w-32'>募集期間
                </th>
                <td className='border px-2 border-secondary-brown'>
                  <div>
                    {
                      [...state.recruiting].map((val, index) => (
                        <div key={index}>
                          {
                            state.recruiting.length > 1 ?
                              <div>
                                {
                                  state.current_recruitment?.id === val.id ?
                                    <span
                                      className='rounded-md px-4 font-semibold text-primary-dark bg-primary-light py-0.5'>{`${index + 1}次募集:${val.name_display}`}募集中</span>
                                    :
                                    <span className='font-semibold'>{`${index + 1}次募集:${val.name_display}`}</span>
                                }
                              </div> : <div>{val.name_display}</div>
                          }
                          <div>{val.start}〜{val.end}</div>
                          {
                            val.name === 'b' &&
                            <small className='opacity-50 text-xs'>※抽選結果は{val.result_date}</small>
                          }
                        </div>
                      ))
                    }
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Simulation percent={parseFloat(state.percent)} totalDays={state.total_days} duration={state.duration}
                  durationType={state.duration_type}/>
      <div className='mt-10 mb-10 flex flex-wrap items-center justify-center gap-3 px-2 sm:gap-5 sm:px-0 lg:gap-10'>
        <a href={`${BACKEND_URL}/fund_list/${state.slug}/`}
           className='flex w-4/5 items-center justify-center rounded-full border-2 bg-white px-2 text-base font-bold border-primary-dark py-2.5 text-primary-dark sm:w-72 sm:py-3 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
          もっと詳細を見る
        </a>
        {state.current_status === '募集中' ? !state.current_recruitment?.applied ? (
          state.current_recruitment?.name === 'a' && state.current_recruitment.units_remainder <= 0 && state.current_recruitment?.will_use_wfc && state.current_recruitment?.wfc_units_remainder > 0 ?
            <Link to={`application/wfc/${state.slug}`}
                className='flex w-4/5 items-center justify-center rounded-full border-2 px-2 text-base font-bold text-white border-primary-dark py-2.5 bg-primary-dark sm:w-72 sm:py-3 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
            キャンセル待ち申込をする
          </Link> :
          <Link to={`application/${state.slug}`}
                className='flex w-4/5 items-center justify-center rounded-full border-2 px-2 text-base font-bold text-white border-primary-dark py-2.5 bg-primary-dark sm:w-72 sm:py-3 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
            出資申込をする
          </Link>) : (
          <button
            disabled
            className='flex w-4/5 items-center justify-center rounded-full border-2 px-2 text-base font-bold text-white border-gray-500 py-2.5 bg-gray-500 sm:w-72 sm:py-3 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
            出資申込済み
          </button>
        ) : null}
      </div>
      { user?.isBlocked && !state.current_recruitment?.applied && 
        <div className='text-center absolute h-full w-full top-0 left-0'>
          <div className='flex p-10 text-2xl text-white items-center justify-center h-full w-full bg-black/50'>
            <p>
              お申込みをキャンセルされたため、現在、新たなお申込みを承ることができません。<br/>
              再度お申込みをご希望の場合は、お問い合わせフォームからご連絡ください。
            </p>
          </div>
        </div> 
      }
    </div>
  );
};