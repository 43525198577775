import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { AgreementType } from '../../store/funds';

export type SectionHeaderProp = {
  title?: string;
  className?: string
  color?: string;
  width?: string;
  children?: React.ReactNode;
  setAgreement?: () => void;
  isRead: boolean | undefined;
}
const FundPrivacyCheckLayout: React.FC<SectionHeaderProp> = ({ title, className, children, setAgreement, isRead }) => {
  const [showModel, setShowModel] = useState(false);

  const handleModelClick = () => {
    if (!showModel && setAgreement) {
      setAgreement();
    }
    setShowModel(!showModel);
  };
  return (
    <div className='mt-4 px-1'>
      <div
        className={`${className} flex w-full items-center bg-white justify-between border-2 sm:border-4 ${(showModel || isRead) ? 'border-secondary-main' : 'border-accent-brown'} p-2 max-[450px]:px-3 cursor-pointer transition duration-1000 ease-in-out`}
        onClick={handleModelClick}>
        <p className='font-bold sm:pl-5 sm:text-xl'>{title}</p>
        <div className='items-end'>
            <span
              className={`flex items-center ${(showModel || isRead) ? 'bg-secondary-main' : 'bg-primary-dark'} text-white pl-5 sm:pl-10  pr-3 py-2 sm:py-3 rounded-md font-bold text-sm sm:text-xl`}>
              確認する {showModel ? (<ChevronUpIcon className='h-6 w-6 sm:h-9 sm:w-9' />) : (
              <ChevronDownIcon className='h-6 w-6 sm:h-9 sm:w-9' />)}
            </span>
        </div>
      </div>
      {setAgreement && showModel && (
        <div
          className='mt-2 mb-10 overflow-y-scroll px-2 py-8 transition duration-1000 ease-in-out bg-secondary-bright h-[500px] sm:px-5'>
          {children}
        </div>
      )}
    </div>
  );
};

export default FundPrivacyCheckLayout;