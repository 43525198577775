import React from 'react';
import { Breadcrumb } from '../../components/ui/Breadcrumb';
import { RegistrationSteps } from '../../components/ui/RegistrationStep';
import { ArrowRightCircleIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import UserService from '../../infrastructure/api/user';
import useAxios from '../../hooks/useAxios';

const breadcrumbItems = [
  { url: '/', name: '' },
];

const StepTwo: React.FC = () => {
  const userService = new UserService(useAxios());
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    userService.getEKYcUrl()
      .then((url) => {
        if (url)
          window.location.href = url;
      });
  };
  return (
    <main className='sm:mt-4 md:mt-14'>
      <div className='container mx-auto sm:px-4'>
        <section className='text-center text-2xl font-bold text-secondary-main md:text-3xl'>
          <h1>投資家登録</h1>
        </section>
        <RegistrationSteps step={2}/>
        <section className='w-full sm:w-[90%] m-auto '>
          <div
            className='grid w-full items-center py-3 bg-secondary-bright'>
            <p
              className='bg-white py-3 text-center font-bold text-secondary-main md:text-lg lg:text-xl max-[450px]:px-3'>
              スマホでかんたん、本人確認
            </p>
          </div>

          <div
            className='grid w-full items-center py-3 bg-secondary-bright mt-5 sm:mt-10 px-5 max-[450px]:px-3'>
            <p
              className='max-[500px]:text-left text-center md:text-lg lg:text-xl text-secondary-main font-bold py-3'>最短10分で審査が完了。<br /> 本人確認の審査手続きが、全てオンライン上で完結します。
            </p>
            <div
              className='bg-white py-5 mt-3 px-4 sm:px-[10%] font-bold md:text-lg'>
              <p>以下の本人確認書類のうち、いずれかの1点をあらかじめご用意の上、手続きを開始してください</p>
              <ul className='grid max-[480px]:grid-cols-1 grid-cols-2 font-normal gap-2 sm:w-[90%] list-disc ml-5 mt-3'>
                <li>マイナンバーカード</li>
                <li>運転免許書</li>
                <li>保険証*</li>
                <li>在留カード</li>
                <li>住民基本台帳カード</li>
                <li>運転経歴証明書</li>
              </ul>
            </div>
            <p
              className='text-start leading-6 sm:text-center md:text-lg font-bold py-3 mt-2 sm:mt-0'>
              スマートフォンと本人確認書類をお手元にご用意の上、<span
              className='hidden sm:block'></span> 下記の「本人確認の手続きをはじめる」より、お手続きを進めてください。
            </p>
            <div className='flex h-full w-full flex-col items-center mt-3 sm:mt-0 sm:px-10'>
              <Link to=''
                    onClick={handleClick}
                    className='sm:mb-5 flex items-center justify-center rounded-xl border-2 font-bold bg-white py-4 text-center text-lg border-secondary-main shadow-[0px_8px] w-full shadow-secondary-main sm:p-5 text-secondary-main md:text-2xl'>
                本人確認手続きをはじめる
                <ArrowRightCircleIcon className='h-6 w-6 sm:h-8 sm:w-8 ml-3' />
              </Link>
            </div>
            <div className='w-full sm:px-10 flex flex-col gap-3 mt-5'>
              <p><span className='text-accent-brown'>※</span>保険証での本人確認をご希望の場合、登録されているご住所にハガキを郵送いたします。ハガキの到着を持って投資家登録完了となります。登録完了までに1週間ほどお時間いただく場合がございます。ご了承ください。
              </p>
              <p><span className='text-accent-brown'>※</span>ご利用のスマートフォンのOSバージョン、ブラウザ環境によってはご利用いただけない可能性がございます。
              </p>
              <p><span className='text-accent-brown'>※</span>投資家登録時にご入力いただいたご住所と、本人確認書類のご住所が一致しない場合、エラーとなってしまいます。その場合、会員情報ページよりご住所をご変更いただいてから本人確認書類のご提出をお願いいたします。(本人確認書類と完全に一致する現住所をご入力ください)
              </p>
              <p><span className='text-accent-brown'>※</span>本人確認の審査手続きには数時間〜数日かかる場合もございます。ご了承ください。</p>
              <p><span className='text-accent-brown'>※</span>パソコン、タブレット、ガラパゴス携帯からは本人確認が出来ません。「本人確認手続きをはじめる」をクリック後、QRコードが表示されますので、スマートフォンで読み込みお手続きください。
              </p>
            </div>
          </div>
        </section>

        <Breadcrumb items={breadcrumbItems} />
      </div>
    </main>);
};

export default StepTwo;