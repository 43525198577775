import React from 'react';
import { LinkButton } from '../../components/ui/Button';

const PasswordChangeCompleted: React.FC = () => {
  return (
    <main className='sm:mt-4 md:mt-14'>
      <div className='container mx-auto px-2 sm:px-4'>
        <section className='text-center text-2xl font-bold text-secondary-main md:text-3xl'>
          <h1>パスワード変更完了</h1>
        </section>
        <div
          className='my-8 py-10 font-medium sm:my-14 sm:px-10 md:text-lg lg:px-14 max-[400px]:px-2 max-[400px]:text-sm'>
          <p>パスワードの変更が完了いたしました。</p>
        </div>
        <LinkButton
          name='マイページトップへ戻る'
          to='/'
          className='border-2 bg-white border-secondary-main text-secondary-main'
        />
        <div className='border-b-8 border-secondary-brown mt-10 pt-12'></div>
      </div>
    </main>
  );
};
export default PasswordChangeCompleted;