import React from 'react';

export type ErrorsType = {
  [key: string]: string[]
}

export interface ErrorListProps {
  name: string,
  errors?: null | ErrorsType;
}

export const ErrorList: React.FC<ErrorListProps> = ({name, errors}) => {
  return (
    <>
      { errors && errors[name] && <ul className='text-red-500 text-sm error-list'>{
          errors[name].map((val, index) => (
            <li key={index}>{val}</li>
          ))
        }</ul>}
    </>
  )
}