import React, {useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumb } from '../../components/ui/Breadcrumb';
import { ChoiceOptionType } from '../../store';
import { BankAccountType } from '../../store/account';
import { useAccount } from '../../hooks/useAccount';
import UserService from '../../infrastructure/api/user';
import { ErrorsType } from '../../components/ui/forms/ErrorList';
import useAxios from '../../hooks/useAxios';
import {BankDetailInput} from '../../components/layouts/BankDetailInput';

export type BankType = {
  hiragana: string;
  id: number;
  name: string;
  official_name: string;
};

export type BranchType = {
  name: string;
  id: number;
  bank_id: number | string;
  name_hiragana: string;
}

export const accountType: ChoiceOptionType[] = [
  { value: '普通', label: '普通' },
  { value: '当座', label: '当座' },
];

const breadcrumbItems = [
  { url: '/', name: 'マイページトップ' },
  { url: '/account', name: '会員情報' },
  { url: '/account/edit/bank', name: '口座情報変更' },
];

const BankAccount: React.FC = () => {
  const { bankAccount, updateBankAccount, personalInformation } = useAccount();
  const renderCount = React.useRef(0);
  const [state, setState] = useState<BankAccountType>(bankAccount);
  
  const [errors, setErrors] = useState<ErrorsType>();
  const axios = useAxios();
  const userService = new UserService(axios);
  const navigate = useNavigate();
  
  useEffect(() => {
    renderCount.current += 1;
    setState(bankAccount);
  }, [bankAccount])
  
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    userService.updateBankAccount(state)
      .then((data) => {
        updateBankAccount(data);
        navigate(-1);
      })
      .catch((error) => {
        const errorList = document.querySelectorAll(`[name=${Object.keys(error)[0]}]`);
        if (errorList) {
          try {
            errorList[0].scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'start',
            });
          } catch (error) {
            console.log(error);
          }

        }
        setErrors(error);
      });
  };

  return (
    <main className='sm:mt-4 md:mt-14'>
      <form className='container mx-auto sm:px-4' onSubmit={handleSubmit}>
        <section className='text-center text-2xl font-bold text-secondary-main md:text-3xl'>
          <h1>口座情報変更</h1>
        </section>
        <BankDetailInput 
          state={state} 
          setState={setState} 
          errors={errors}
          renderCount={renderCount.current}
          fullNameKana={`${personalInformation.last_name_kana} ${personalInformation.first_name_kana}`} />
        <section
          className='mt-8 sm:mt-16 px-2 mb-5 sm:mb-10 flex flex-wrap items-center justify-center gap-3 sm:gap-5 lg:gap-10'>
          <Link to='/account'
                className='flex w-4/5 items-center justify-center rounded-full border px-2 bg-white text-sm font-bold border-secondary-main py-2.5 text-secondary-main sm:w-72 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
            会員情報ページに戻る
          </Link>
          <button
            type='submit'
            className='flex w-4/5 items-center justify-center rounded-full border px-2 text-sm font-bold text-white border-primary-dark py-2.5 bg-primary-dark sm:w-72 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
            {bankAccount.account_holder ? '変更する' : '登録する'}
          </button>
        </section>
        <Breadcrumb items={breadcrumbItems} />
      </form>
    </main>
  );
};

export default BankAccount;