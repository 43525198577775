import React from 'react';
import {Breadcrumb} from '../../components/ui/Breadcrumb';
import {ArrowDownCircleIcon} from '@heroicons/react/24/solid';
import {Link} from 'react-router-dom';
import {LinkButton} from '../../components/ui/Button';
import {useAccount} from '../../hooks/useAccount';
import {useLiveQuery} from 'dexie-react-hooks';
import {db} from '../../db';

const breadcrumbItems = [
    {url: '/', name: 'マイページトップ'},
    {url: '/account', name: '会員情報'},
];

export type TableLayoutProp = {
    name?: string
    title?: string;
    className?: string;
    border?: string;
    children?: React.ReactNode
}

const myNumberStatus = {
    pending: '確認中',
    completed: '登録済',
    declined: '未登録',
};

const TableLayout: React.FC<TableLayoutProp> = ({title, border, children}) => {
    return (
        <tr
            className={`${border} border border-b-0 flex flex-col sm:flex-row items-start sm:items-center last:border-b`}>
            <th
                className={`${border} border-r border-b sm:border-b-0 px-2 text-start sm:text-center w-full sm:w-52 py-2 sm:py-3 bg-gray-100 text-base md:text-lg`}>{title}</th>
            <td className='flex-1 px-2 py-2 text-base sm:px-3 sm:py-3 lg:text-lg'>
                {children}
            </td>
        </tr>
    );
};
const TableTitleLayout: React.FC<TableLayoutProp> = ({name, className, children}) => {
    return (
        <React.Fragment>
            <div className={`${className}`}>
                <h3 className='px-5 py-4 text-2xl font-bold text-white'>{name}</h3>
            </div>
            <table className='w-full bg-white'>
                <tbody>
                {children}
                </tbody>
            </table>
        </React.Fragment>
    );
};


const AccountInformation: React.FC = () => {
    const userData = useLiveQuery(() => db.user.get(1));
    const {memberInformation, loginInformation, personalInformation, bankAccount} = useAccount();

    return (
        <main className='sm:mt-4 md:mt-14 anchor-scroll'>
            <div className='container mx-auto sm:px-4'>
                <section className='text-center text-2xl font-bold text-primary-brown md:text-3xl'>
                    <h1>会員情報</h1>
                </section>
                <section className='mt-12 bg-primary-light'>
                    <div className='flex flex-row flex-wrap justify-center gap-5 py-5 sm:gap-10'>
                        <Link to={{hash: 'login-information'}}
                              className='flex h-20 w-44 cursor-pointer flex-col items-center justify-center rounded-2xl border-4 bg-white font-bold border-primary-brown text-primary-brown sm:text-lg max-[400px]:w-32 max-[400px]:text-sm'>
                            <h2>ログイン情報</h2>
                            <ArrowDownCircleIcon className='h-6 w-6'/>
                        </Link>
                        <Link to={{hash: 'personal-information'}}
                              className='flex h-20 w-44 cursor-pointer flex-col items-center justify-center rounded-2xl border-4 bg-white font-bold border-secondary-main text-secondary-main lg:text-lg max-[400px]:w-32 max-[400px]:text-sm'>
                            <h2>投資家情報</h2>
                            <ArrowDownCircleIcon className='h-6 w-6'/>
                        </Link>
                        <Link to={{hash: 'member-information'}}
                              className='flex h-20 w-44 cursor-pointer flex-col items-center justify-center rounded-2xl border-4 bg-white font-bold border-accent-yellow text-accent-yellow lg:text-lg max-[400px]:w-32 max-[400px]:text-sm'>
                            <h2>会員登録情報</h2>
                            <ArrowDownCircleIcon className='h-6 w-6'/>
                        </Link>
                        <Link to={{hash: 'bank-account-information'}}
                              className='flex h-20 w-44 cursor-pointer flex-col items-center justify-center rounded-2xl border-4 bg-white font-bold border-primary-grey text-primary-grey lg:text-lg max-[400px]:w-32 max-[400px]:text-sm'>
                            <h2>口座情報</h2>
                            <ArrowDownCircleIcon className='h-6 w-6'/>
                        </Link>
                    </div>
                </section>

                {
                    userData?.registrationStep && userData.registrationStep < 3 &&
                    <section className='mt-5 pb-10'>
                        <div
                            className='grid w-full items-center justify-between px-5 py-5 bg-secondary-bright sm:grid-cols-2 sm:py-10 max-[450px]:px-3'>
                            <p
                                className='text-center font-bold text-accent-brown md:text-lg lg:text-xl'>{userData.registrationStep < 2 ? '投資家登録状態未登録' : '本人確認書類の再提出が必要です'}</p>
                            <div className='font-semibold text-primary-text md:text-lg'>
                                <p>※出資申込には投資家登録が必要です。</p>
                                <p> 投資家登録に必要な本人確認は
                                    <Link to={`/investor-registration/step${userData?.registrationStep}`} className='underline underline-offset-4 text-primary-dark'>こちら</Link>
                                    からお進みください</p>
                            </div>
                        </div>
                    </section>
                }

                <section id='login-information'
                         className='mt-14 before:block before:contents-[" "] before:h-24 before:-mt-24 before:invisible'>
                    <TableTitleLayout
                        name='ログイン情報'
                        className='bg-primary-brown'
                    >
                        <TableLayout title='メールアドレス' border='border-primary-brown'>
                            <p className='text-primary-text'>{loginInformation.email || '未登録'}</p>
                        </TableLayout>
                        <TableLayout title='パスワード' border='border-primary-brown'>
                            <p className='text-primary-text'>*************</p>
                        </TableLayout>
                    </TableTitleLayout>
                    <div className='mt-8 flex flex-wrap items-center justify-center gap-3 pb-10 sm:gap-5 lg:gap-10'>
                        <Link to='edit/email'
                              className='flex w-4/5 items-center justify-center rounded-full border px-2 text-sm font-bold text-white border-primary-brown py-2.5 bg-primary-brown sm:w-80 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
                            メールアドレスを変更する
                        </Link>
                        <Link to='edit/password'
                              className='flex w-4/5 items-center justify-center rounded-full border px-2 text-sm font-bold text-white border-primary-brown py-2.5 bg-primary-brown sm:w-80 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
                            パスワードを変更する
                        </Link>
                    </div>
                </section>

                {
                    userData?.registrationStep && userData.registrationStep > 1 &&
                    <>
                        <section id='personal-information'
                                 className='mt-20 before:block before:contents-[" "] before:h-24 before:-mt-24 before:invisible'>
                            <TableTitleLayout
                                name='投資家情報'
                                className='bg-secondary-main'
                            >
                                <TableLayout title='氏名' border='border-secondary-main'>
                                    <p
                                        className='text-primary-text'>{personalInformation.last_name ? `${personalInformation.last_name} ${personalInformation.first_name}` : '未登録'}</p>
                                </TableLayout>
                                <TableLayout title='氏名(フリガナ)' border='border-secondary-main'>
                                    <p
                                        className='text-primary-text'>{personalInformation.last_name_kana ? `${personalInformation.last_name_kana} ${personalInformation.first_name_kana}` : '未登録'}</p>
                                </TableLayout>
                                <TableLayout title='生年月日' border='border-secondary-main'>
                                    <p
                                        className='text-primary-text'>{personalInformation.birth_date || '未登録'}</p>
                                </TableLayout>
                                <TableLayout title='住所' border='border-secondary-main'>
                                    <p
                                        className='text-primary-text'>{personalInformation.address1 ? `${personalInformation.address1}${personalInformation.address2}${personalInformation.address3}${personalInformation.address4 || ''}` : '未登録'}</p>
                                </TableLayout>
                            </TableTitleLayout>
                            <p>※投資家情報を変更されると、投資家登録済も再度投資家登録が必要となります。</p>
                            <LinkButton
                                name='投資家情報を変更する'
                                to='edit/personal'
                                className='border-2 text-white border-secondary-main bg-secondary-main'
                            />
                        </section>
                        <section id='member-information'
                                 className='mt-20 before:block before:contents-[" "] before:h-24 before:-mt-24 before:invisible'>
                            <TableTitleLayout
                                name='会員登録情報'
                                className='bg-accent-yellow'
                            >
                                <TableLayout title='電話番号' border='border-accent-yellow'>
                                    <p
                                        className='text-primary-text'>{memberInformation.phone || '未登録'}</p>
                                </TableLayout>
                                <TableLayout title='性別' border='border-accent-yellow'>
                                    <p
                                        className='text-primary-text'>{memberInformation.sex || '未登録'}</p>
                                </TableLayout>
                                <TableLayout title='職業' border='border-accent-yellow'>
                                    <p
                                        className='text-primary-text'>{memberInformation.occupation || '未登録'}</p>
                                </TableLayout>
                                <TableLayout title='勤務先' border='border-accent-yellow'>
                                    <p
                                        className='text-primary-text'>{memberInformation.workplace || '未登録'}</p>
                                </TableLayout>
                                <TableLayout title='投資年数' border='border-accent-yellow'>
                                    <p
                                        className='text-primary-text'>{memberInformation.investment_year || '未登録'}</p>
                                </TableLayout>
                                <TableLayout title='投資資金' border='border-accent-yellow'>
                                    <p
                                        className='text-primary-text'>{memberInformation.investment_funds || '未登録'}</p>
                                </TableLayout>
                                <TableLayout title='マイナンバー' border='border-accent-yellow'>
                                    <p
                                        className='text-primary-text'>{userData && userData.mynumberStatus ? myNumberStatus[userData.mynumberStatus] : '未登録'}</p>
                                </TableLayout>
                            </TableTitleLayout>
                            <div
                                className='mt-8 flex flex-wrap items-center justify-center gap-3 pb-10 sm:gap-5 lg:gap-10'>
                                <Link to='edit/member'
                                      className='flex w-4/5 items-center justify-center rounded-full border px-2 text-sm font-bold text-white border-accent-yellow py-2.5 bg-accent-yellow sm:w-72 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
                                    会員情報を変更する
                                </Link>
                                {
                                    userData && userData.mynumberStatus && userData?.mynumberStatus !== 'declined' ||
                                    <Link to='edit/mynumber-registration'
                                          className='flex w-4/5 items-center justify-center rounded-full border px-2 text-sm font-bold text-white border-accent-yellow py-2.5 bg-accent-yellow sm:w-72 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
                                        マイナンバーを登録する
                                    </Link>
                                }
                            </div>
                        </section>
                    </>
                }
                {
                    userData?.registrationStep && userData.registrationStep > 1 &&
                    <section id='bank-account-information'
                             className='mt-20 before:block before:contents-[" "] before:h-24 before:-mt-24 before:invisible'>
                        <TableTitleLayout
                            name='口座情報'
                            className='bg-primary-grey'
                        >
                            <TableLayout border='border-primary-grey' title='銀行名'>
                                <p className='text-primary-text'>{bankAccount.bank_name || '未設定'}</p>
                            </TableLayout>
                            <TableLayout border='border-primary-grey' title='支店名'>
                                <p className='text-primary-text'>{bankAccount.branch_name || '未設定'}</p>
                            </TableLayout>
                            <TableLayout border='border-primary-grey' title='口座種別'>
                                <p className='text-primary-text'>{bankAccount.account_type || '未設定'}</p>
                            </TableLayout>
                            <TableLayout border='border-primary-grey' title='口座番号'>
                                <p className='text-primary-text'>{bankAccount.account_number || '未設定'}</p>
                            </TableLayout>
                            <TableLayout border='border-primary-grey' title='口座人名義'>
                                <p className='text-primary-text'>{bankAccount.account_holder || '未設定'}</p>
                            </TableLayout>
                        </TableTitleLayout>
                        <div className='mt-8 flex flex-wrap items-center justify-center gap-3 pb-10 sm:gap-5 lg:gap-10'>
                            <Link to='edit/bank'
                                  className='flex w-4/5 items-center justify-center rounded-full border px-2 text-sm font-bold text-white border-primary-grey py-2.5 bg-primary-grey sm:w-72 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
                                {bankAccount.account_holder ? '払戻口座情報を変更する' : '払戻口座情報を登録する'}
                            </Link>
                        </div>
                    </section>
                }
                <Breadcrumb items={breadcrumbItems}/>
            </div>
        </main>

    );
};

export default AccountInformation;