import React from 'react';
import { useSearchParams } from 'react-router-dom';

export type PaginationState = {
  page: number;
}


export const usePagination = <T extends PaginationState & Record<string, string | number>>(
  state: T,
  setState: React.Dispatch<React.SetStateAction<T>>,
  totalPages: number,
) => {

  const [searchParams, setSearchParams] = useSearchParams();

  const setParams = (data?: T) => {
    setSearchParams(new URLSearchParams(data as unknown as URLSearchParams || state as unknown as URLSearchParams));
  };

  const setNext = () => {
    setParams({ ...state, page: state.page === totalPages ? totalPages : state.page + 1 });
    setState((prev) => ({ ...prev, page: prev.page === totalPages ? totalPages : prev.page + 1 }));
  };

  const setPrevious = () => {
    setParams({
      ...state, page: state.page < 2 ? 1 : state.page - 1,
    });
    setState((prev) => ({
      ...prev, page: prev.page < 2 ? 1 : prev.page - 1,
    }));
  };

  const setFirst = () => {
    setParams({ ...state, page: 1 });
    setState((prev) => ({ ...prev, page: 1 }));
  };

  const setLast = () => {
    setParams({ ...state, page: totalPages });
    setState((prev) => ({ ...prev, page: totalPages }));
  };

  return {
    setFirst,
    setLast,
    setNext,
    setPrevious,
    searchParams,
    setParams,
  };
};