import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

export const InvestmentCompleted: React.FC<{ fundName?: string, invested?: boolean, lottery?: boolean }> = ({
                                                                                           fundName = '',
                                                                                           invested = false, 
                                                                                           lottery = true
                                                                                         }) => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <main className='sm:mt-4 md:mt-14'>
      {
        invested ?
          <div className='container mx-auto sm:px-4'>
            <section className='text-center text-2xl font-bold text-secondary-main md:text-3xl'>
              <h1>出資完了</h1>
            </section>
            <section className='my-20 text-lg leading-7 px-[8%]'>
              <p className='pb-2'>この度は{fundName}へご出資いただきありがとうございます。</p>
              <p className='pb-2'>{fundName}の契約成立時書面を発行いたしました。<br/>
                書面のご確認は、マイページトップメニュー「電子交付書面」や「出資状況」の出資済みファンドからご確認いただけます。
              </p>
              <p className='pb-2'>ご不明な点がございましたら<Link to='https://tomotaqu.com/faq/'
                                                                  className='text-primary-dark'>よくある質問</Link>をご確認ください。
              </p>
            </section>
            <section
              className='mb-10 flex flex-wrap items-center justify-center gap-3 px-2 py-10 sm:gap-5 sm:px-0 lg:gap-10'>
              <Link to='/'
                    className='flex w-4/5 items-center justify-center rounded-full border-2 bg-white px-2 font-bold border-primary-brown py-2.5 text-primary-brown sm:w-96 sm:border-2 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
                マイページトップへ戻る
              </Link>
              <Link to='/electronic-document'
                    className='flex w-4/5 items-center justify-center rounded-full border-2 bg-white px-2 font-bold text-primary-dark border-primary-dark py-2.5 sm:w-96 sm:border-2 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
                電子交付書面ページを見る
              </Link>
            </section>
          </div> :
          <div className='container mx-auto sm:px-4'>
            <section className='text-center text-2xl font-bold text-secondary-main md:text-3xl'>
              <h1>出資申込完了</h1>
            </section>
            <section className='my-20 text-lg leading-7 px-[8%] text-center'>
              <p className='pb-2'>この度は{fundName}への出資申込いただきありがとうございます。</p>
              <p className='pb-2'>出資申込完了のご案内を登録されたメールアドレスにお送りしました。<br/>
                {lottery && '抽選結果発表まで今しばらくお待ち下さいませ。'}
              </p>
            </section>
            <section
              className='mb-10 flex flex-wrap items-center justify-center gap-3 px-2 py-10 sm:gap-5 sm:px-0 lg:gap-10'>
              <Link to='/'
                    className='flex w-4/5 items-center justify-center rounded-full border-2 bg-white px-2 font-bold border-primary-brown py-2.5 text-primary-brown sm:w-96 sm:border-2 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
                マイページトップへ戻る
              </Link>
            </section>
          </div>
      }
    </main>
  );
};









