import React from 'react';

type TableLayoutProp = {
  title?: string;
  className?: string;
  border?: string;
  children?: React.ReactNode
}
export const TableLayout: React.FC<TableLayoutProp> = ({ title, border, className = 'bg-gray-200', children }) => {
  return (
    <tr
      className={`${border} border border-b-0 flex flex-col sm:flex-row items-stretch last:border-b`}>
      <th
        className={`${border} ${className} border-r flex border-b sm:border-b-0 px-2 justify-items-center items-center justify-center text-start sm:text-center w-full sm:w-52 py-2 sm:py-3 text-base md:text-lg`}>
        {title}
      </th>
      <td className='flex-1 px-2 py-2 text-base sm:px-3 sm:py-3 lg:text-lg'>
        {children}
      </td>
    </tr>
  );
};