import React, { useRef, useState } from 'react';
import { useAccount } from '../../hooks/useAccount';
import { EnvelopeIcon, KeyIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import { Breadcrumb } from '../../components/ui/Breadcrumb';
import VerificationCode from './VerificationCode';
import { ErrorList, ErrorsType } from '../../components/ui/forms/ErrorList';
import UserService from '../../infrastructure/api/user';
import useAxios from '../../hooks/useAxios';

const breadcrumbItems = [
  { url: '/', name: 'マイページトップ' },
  { url: '/account', name: '会員情報' },
  { url: 'edit/email', name: 'メールアドレス変更' },
];

const EditEmailAddress: React.FC = () => {
  const { loginInformation } = useAccount();
  const [state, setState] = useState<{ username: string; password: string }>({ username: '', password: '' });
  const [completed, setCompleted] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null!);
  const buttonRef2 = useRef<HTMLButtonElement>(null!);
  const [errors, setErrors] = useState<ErrorsType>();
  const userService = new UserService(useAxios());

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const getOPT = () => {
    userService.getOTP(state)
      .then(() => {
        setCompleted(true);
      })
      .catch((err) => {
        setErrors(err);
      })
      .finally(() => {
        if (buttonRef.current)
          buttonRef.current.disabled = false;
        if (buttonRef2.current)
          buttonRef2.current.disabled = false;
      });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    buttonRef.current.disabled = true;
    getOPT();
  };


  return (
    <React.Fragment>
      {
        !completed ?
          <main className='sm:mt-4 md:mt-14 anchor-scroll'>
            <div className='container px-2 mx-auto sm:px-4'>
              <section className='text-center text-2xl font-bold text-secondary-main md:text-3xl'>
                <h1>メールアドレスの変更</h1>
              </section>
              <form onSubmit={handleSubmit} method='post' className='mt-12' autoComplete='off'>
                <div className='sm:text-lg'>
                  <p>ご登録されているメールアドレスの変更を行います。</p>
                  <p>現在登録されているメールアドレスをご確認の上、マイページのログインパスワードを入力し「確認コードを受信する」ボタンを押してください。</p>
                </div>
                <div className='bg-white sm:px-20 py-10 mt-5'>
                  <div className='flex flex-col'>
                    <div className='relative sm:text-lg font-bold'>
                      <span className='absolute h-full w-3 bg-secondary-main left-0 top-0'></span>
                      <label className='pl-5 leading-relaxed tracking-wide'>登録メールアドレス</label>
                    </div>
                    <p className='sm:text-lg mt-3'>{loginInformation.email}</p>
                  </div>
                  <div className='flex flex-col mt-8'>
                    <div className='relative sm:text-lg font-bold'>
                      <span className='absolute h-full w-3 bg-secondary-main left-0 top-0'></span>
                      <label className='pl-5 leading-relaxed tracking-wide'>ログインパスワード</label>
                    </div>
                    <div className='relative mt-4'>
                      <input
                        type='password'
                        name='password'
                        value={state.password}
                        onChange={handleChange}
                        readOnly={true}
                        autoComplete='off'
                        onFocus={(e) => {
                          e.currentTarget.removeAttribute('readonly');
                        }}
                        className='h-12 pl-12 pr-2 border-primary-grey rounded-md border w-full' />
                      <div
                        className='pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-secondary-main'>
                        <KeyIcon className='h-8 w-8' />
                      </div>
                    </div>
                    <ErrorList name='password' errors={errors} />
                    <p className='text-xs sm:text-base px-1'>※半角英数字でご入力ください</p>
                  </div>
                  <div className='flex flex-col mt-8'>
                    <div className='relative sm:text-lg font-bold'>
                      <span className='absolute h-full w-3 bg-secondary-main left-0 top-0'></span>
                      <label className='pl-5 leading-relaxed tracking-wide'>変更したいメールアドレスを入力</label>
                    </div>
                    <div className='relative mt-4'>
                      <input
                        type='email'
                        name='username'
                        value={state.username}
                        onChange={handleChange}
                        autoComplete='off'
                        readOnly={true}
                        onFocus={(e) => {
                          e.currentTarget.removeAttribute('readonly');
                        }}
                        className='h-12 pl-12 pr-2 border-primary-grey rounded-md border w-full' />
                      <div
                        className='pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-secondary-main'>
                        <EnvelopeIcon className='h-8 w-8 ' />
                      </div>
                    </div>
                    <p className='text-xs sm:text-base px-1'>※半角小文字英数字でご入力ください</p>
                    <ErrorList name='username' errors={errors} />
                  </div>
                </div>
                <div className='mt-3 text-sm sm:text-base'>
                  <p>ご入力された変更したいメールアドレスに確認コードが送信されます。</p>
                  <p>1時間以内内容をご確認の上、手続きを進めてください。</p>
                  <p> ※1時間以上経過してしまうと、変更手続きがリセットされてしまいます。ご注意ください。</p>
                </div>
                <div className='mt-12 flex flex-col items-center justify-center gap-3 pb-10 sm:gap-5 lg:gap-8'>
                  <button
                    ref={buttonRef}
                    type='submit'
                    className='flex w-4/5 items-center justify-center rounded-full border-2 px-2 text-sm font-bold text-white border-secondary-main py-2.5 bg-secondary-main sm:w-80 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
                    確認コードを受信する
                  </button>
                  <Link to='/account'
                        className='flex w-4/5 items-center justify-center rounded-full border-2 px-2 text-sm font-bold text-secondary-main border-secondary-main py-2.5 bg-white sm:w-80 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
                    会員情報ページへ戻る
                  </Link>
                </div>
              </form>
              <Breadcrumb items={breadcrumbItems} />
            </div>
          </main> : <VerificationCode getOPT={getOPT} email={state.username} buttonRef={buttonRef2} />
      }
    </React.Fragment>
  );
};
export default EditEmailAddress;