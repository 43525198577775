export const MEMBER_INFORMATION_UPDATE = 'MEMBER_INFORMATION_UPDATE';
export const PERSONAL_INFORMATION_UPDATE = 'PERSONAL_INFORMATION_UPDATE';
export const BANK_ACCOUNT_UPDATE = 'BANK_ACCOUNT_UPDATE';
export const LOGIN_INFORMATION_UPDATE = 'LOGIN_INFORMATION_UPDATE';
export const ACCOUNT_STATE_UPDATE = 'ACCOUNT_STATE_UPDATE';
export const NOTIFICATION_UNREAD_INCREMENT = 'NOTIFICATION_UNREAD_INCREMENT';
export const NOTIFICATION_UNREAD_RESET = 'NOTIFICATION_UNREAD_RESET';
export const NOTIFICATION_UPDATE = 'NOTIFICATION_UPDATE';
export const NOTIFICATION_UNREAD_SET = 'NOTIFICATION_UNREAD_SET';
export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const INCREMENT_ACTIVE_LOADING = 'INCREMENT_ACTIVE_LOADING';
export const DECREMENT_ACTIVE_LOADING = 'DECREMENT_ACTIVE_LOADING';