import {
  NOTIFICATION_UNREAD_INCREMENT,
  NOTIFICATION_UNREAD_RESET,
  NOTIFICATION_UNREAD_SET,
  NOTIFICATION_UPDATE,
} from './constants';

export type NotificationType = {
  unread: boolean;
  description: string;
  timestamp: string;
  id: number;
}


export type NotificationStateType = {
  notifications: NotificationType[];
  unreadCount: number;
  needToBeFetched: boolean;
}

export type NotificationActionType =
  | { type: typeof NOTIFICATION_UPDATE; payload: NotificationType[]; }
  | { type: typeof NOTIFICATION_UNREAD_INCREMENT; payload: number; }
  | { type: typeof NOTIFICATION_UNREAD_SET; payload: number; }
  | { type: typeof NOTIFICATION_UNREAD_RESET; }


export const defaultNotificationState: NotificationStateType = {
  notifications: [],
  unreadCount: 0,
  needToBeFetched: false,
};

export const notificationReducer = (state: NotificationStateType = defaultNotificationState, action: NotificationActionType): NotificationStateType => {
  switch (action.type) {
    case NOTIFICATION_UPDATE:
      return {
        ...state,
        notifications: action.payload,
      };
    case NOTIFICATION_UNREAD_SET:
      return {
        ...state,
        unreadCount: action.payload,
        needToBeFetched: true,
      };
    case NOTIFICATION_UNREAD_INCREMENT:
      return {
        ...state,
        unreadCount: state.unreadCount + action.payload,
        needToBeFetched: true,
      };
    case NOTIFICATION_UNREAD_RESET:
      return {
        ...state,
        unreadCount: 0,
        needToBeFetched: false,
      };
    default:
      return state;
  }
};