import React from 'react';
import {SectionHeaderLayout, SectionHeaderProps} from './SectionHeaderLayout';
import {FieldsDisplayLayout} from './FieldsDisplayLayout';
import {Link} from 'react-router-dom';
import {DepositWithdrawType} from '../../store/deposit';
import {convertToNumber, numberWithCommas, zenkakuToHankaku} from '../../utlis/numbers'
import {ErrorList, ErrorsType} from '../../components/ui/forms/ErrorList';

export const withdrawData = [
  {
    title: '出金可能額',
    name: 'amountAvailable',
    money: true,
  },
  {
    title: '払戻不可金額',
    name: 'inProgressAmount',
    money: true,
  },
  {
    title: '払戻先口座',
    name: 'bankAccountNumber',
    className: 'w-full border-primary-text',
    width: 'full',
    color: 'text-white bg-primary-text bg-opacity-100',
  },
];

interface WithdrawType extends SectionHeaderProps {
  state: DepositWithdrawType;
  amount: number;
  setAmount: React.Dispatch<React.SetStateAction<number>>;
  buttonRef: React.Ref<HTMLButtonElement>;
  errors?: ErrorsType;
}

export const Withdraw: React.FC<WithdrawType> = (
  {
    title,
    subTitle,
    image,
    state,
    amount,
    setAmount,
    buttonRef,
    errors,
  },
) => {

  return (
    <SectionHeaderLayout
      title={title}
      subTitle={subTitle}
      image={image}
      className='bg-secondary-bright border-secondary-main text-secondary-main'
    >
      {
        !state.bankAccountNumber ?
          <div className='my-5 w-full justify-end text-base font-bold'>
            払戻口座をご登録ください。
            <Link to='/account/edit/bank'
                  className='text-end underline underline-offset-4 text-primary-dark'>払戻先口座を登録する</Link>
          </div> :
          <>
            <div className='flex flex-wrap justify-between'>

              {withdrawData.map((value, index) =>
                <FieldsDisplayLayout
                  className={value.className || 'text-secondary-main border-secondary-main'}
                  width={value.width || 'w-full lg:w-[48%]'}
                  color={value.color || 'bg-secondary-main'}
                  key={index}
                  title={value.title}
                >
                  <p className='text-primary-text'>{
                    value.name === 'bankAccountNumber' ? `${state.bankName} ${state.branchName} ${state.accountType} ${state.bankAccountNumber} ${state.accountHolder}` :
                      value.money ? `${numberWithCommas(state[value.name as keyof DepositWithdrawType]) || 0}円` :
                        state[value.name as keyof DepositWithdrawType]
                  }</p>
                </FieldsDisplayLayout>,
              )}

              <div
                className='-mt-2 mb-4 flex w-full flex-col items-end justify-end text-base font-bold text-primary-dark'>
                <Link to='/account/edit/bank'
                      className='text-end underline underline-offset-4'>払戻先口座を変更する</Link>
              </div>

              <div className='w-full'>
                <div
                  className='relative flex flex-col text-base border-secondary-main sm:flex-row sm:items-center sm:gap-5 sm:border-2 sm:bg-white md:gap-8 md:text-lg'>
                  <label
                    className='w-44 items-center justify-center px-2 py-3 text-center font-semibold text-white bg-secondary-main sm:w-52 lg:w-60'>
                    払戻申請金額
                  </label>
                  <input
                    type='text'
                    onChange={(e) => {
                      setAmount(convertToNumber(zenkakuToHankaku(e.target.value)) || 0);
                    }}
                    maxLength={16}
                    value={numberWithCommas(amount)}
                    inputMode='numeric'
                    className='h-14 w-full border-2 bg-white px-5 border-secondary-main focus:outline-none sm:h-auto sm:border-0 sm:px-0'
                    placeholder='ご入力ください'/>
                  <span className='absolute right-0 bottom-0 mr-2'>円</span>
                </div>
                <span className='sm:px-40 md:px-44 lg:px-52 xl:px-56'>
                  ※半角数字入力
                </span>
                <div className='sm:px-40 md:px-44 lg:px-52 xl:px-56'>
                  <ErrorList name='amount' errors={errors}/>
                </div>
              </div>
            </div>

            <div className='mt-8'>
              <h3
                className='inline-flex items-center justify-center border-2 bg-white px-4 py-2 text-2xl font-bold text-primary-dark border-primary-dark'>
                ご一読ください
              </h3>

              <div className='mt-3 text-lg'>
                <ul className="square-list">
                  <li>
                    当日の23時59分までに払い戻し申請をいただいてから、当日を含む5営業日以内(土日祝除く)に、ご指定の口座にお振込みいたします。
                  </li>
                  <li>
                    毎月1日から月末まで、初回のお振込みは手数料無料です。同じ月内で2回目以降の払い戻し申請については、GMOあおぞらネット銀行以外への送金の場合、振込手数料145円を差し引いた金額をお振込みいたします。
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="mt-8 flex items-center justify-center px-5 py-4 bg-secondary-light text-secondary-main max-[450px]:px-3">
              <label className='inline-flex cursor-pointer items-center'>
                <input
                  required
                  type='checkbox'
                  className='h-5 w-5 form-checkbox text-secondary-main bg-primary-dark'
                />
                <span className='ml-2 text-base font-bold md:text-lg lg:text-xl'>
                  上記の内容に同意をして払戻を申請する
                </span>
              </label>
            </div>
            <div className='mt-10 flex items-center justify-center'>
              <button
                type='submit'
                disabled={!amount}
                ref={buttonRef}
                className='disabled:opacity-50 flex w-60 items-center justify-center rounded-full border-2 px-2 py-2 text-lg font-bold text-white bg-primary-dark border-primary-dark sm:w-80 sm:py-3 md:text-xl max-[300px]:w-full'>
                確認画面へ進む
              </button>
            </div>
          </>
      }
    </SectionHeaderLayout>
  );
};