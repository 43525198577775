import React from 'react';
import { Link } from 'react-router-dom';


type ButtonsProps = {
  name?: string
  to?: string
  className?: string;
};

export const LinkButton: React.FC<ButtonsProps> = ({ name, to = '', className }) => {
  return (
    <div className='mt-8 flex flex-wrap items-center justify-center gap-3 pb-10 sm:gap-5 lg:gap-10'>
      <Link to={`${to}`}
            className={`${className} flex max-[400px]:w-full w-4/5 items-center justify-center rounded-full px-2 py-2.5 sm:py-4 text-sm font-bold sm:w-72 sm:text-base md:rounded-full md:text-xl lg:w-5/12`}>
        {name}
      </Link>
    </div>
  );
};