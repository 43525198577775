import { SectionHeaderProps } from './SectionHeaderLayout';
import React from 'react';

export const FieldsDisplayLayout: React.FC<SectionHeaderProps> = ({ title, className, width, color, children }) => {
  return (
    <div
      className={`${className} ${width} border-2 flex gap-3 sm:gap-5 md:gap-8 text-base md:text-lg bg-white items-center my-3`}>
        <span
          className={`${color} bg-opacity-20 items-center px-2 w-32 sm:w-36 lg:w-44 text-center py-2 justify-center font-semibold`}>
          {title}
        </span>
      {children}
    </div>
  );
};