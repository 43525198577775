import React from 'react';
import { Link } from 'react-router-dom';
import { numberWithCommas } from '../../utlis/numbers';

interface FundListing {
  fundName: string;
  slug?: string;
  investmentAmount?: number;
  investmentUnit?: number;
  image: string;
  className?: string;
}

export const FundListing: React.FC<FundListing> = ({
                                                     slug = '',
                                                     className,
                                                     fundName,
                                                     investmentAmount,
                                                     investmentUnit,
                                                     image,
                                                   }) => {
  return (
    <Link to={slug} className='flex flex-row bg-white px-2 py-3 sm:px-5 max-[400px]:px-0.5'>
      <figure
        className='overflow-hidden rounded-xl w-[158px] h-[100px] sm:h-[125px] sm:w-48 lg:w-48 max-[400px]:w-[130px] max-[400px]:h-[90px]'>
        <img className='h-full w-full rounded-xl object-cover' src={image} alt='' />
      </figure>
      <article className='flex flex-1 flex-col justify-center pl-2'>
        <h3 className={`${className} text-sm sm:text-base font-bold sm:mb-3`}>{fundName}</h3>
        <div className='text-sm sm:text-base'>
          <p>出資口数:<span>{numberWithCommas(investmentUnit)}口</span></p>
          <p>出資金額:<span>{numberWithCommas(investmentAmount)} 円</span></p>
        </div>
      </article>
    </Link>
  );
};