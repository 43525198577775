import React from 'react';
import Nav from '../ui/Nav';
import Footer from '../ui/Footer';

export const BaseLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div>
      <Nav />
      <div>
        {children}
      </div>
      <Footer />
    </div>
  );
};