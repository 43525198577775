import React, {useEffect, useRef, useState} from 'react'

import withdrawImage from '../../assets/images/syukkin-min.jpg'
import {Withdraw} from './WithdrawApplication'
import WithdrawApplicationCompleted from './WithdrawApplicationCompleted'
import {DepositWithdrawType, initialDepositWithdraw} from '../../store/deposit'
import UserService from '../../infrastructure/api/user'
import {ErrorsType} from '../../components/ui/forms/ErrorList'
import useAxios from '../../hooks/useAxios'
import {useLiveQuery} from 'dexie-react-hooks'
import {db} from '../../db'
import {useNavigate} from 'react-router-dom'
import {numberWithCommas} from '../../utlis/numbers'

const DepositWithdraw: React.FC = () => {
  const navigate = useNavigate()
  const [completed, setCompleted] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [state, setState] = useState<DepositWithdrawType>(initialDepositWithdraw)
  const [amount, setAmount] = useState<number>(0)
  const buttonRef = useRef<HTMLButtonElement>(null!)
  const [errors, setErrors] = useState<ErrorsType>()
  const userService = new UserService(useAxios())
  const userData = useLiveQuery(() => db.user.get(1))

  useEffect(() => {
    userService.getDepositWithdraw()
      .then((data) => {
        if (!data.bankId || !data.branchId) {
          navigate('/account/edit/bank')
        }
        setState(data)
      })
  }, [])

  const handleConfirm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setConfirm(true)
  }
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    buttonRef.current.disabled = true
    userService.applyDepositWithdraw(amount)
      .then(() => {
        setCompleted(true)
      })
      .catch((err) => {
        setErrors(err)
        setConfirm(false)
        buttonRef.current.disabled = false
      })
  }
  return (
    <React.Fragment>
      {
        !completed ?
          <main className="sm:mt-4 md:mt-10">
            <div className="container mx-auto sm:px-4">
              <section className="text-center text-2xl font-bold text-primary-brown md:text-3xl">
                <h1>出金</h1>
              </section>
              {
                userData?.registrationStep && userData.registrationStep > 1 && !confirm
                  ? (
                    <form onSubmit={handleConfirm}>
                      <Withdraw title="出金について" subTitle="払戻のご申請はこちらから"
                                image={withdrawImage} state={state}
                                amount={amount} setAmount={setAmount} buttonRef={buttonRef}
                                errors={errors}/>
                    </form>
                  )
                  : (
                    <form onSubmit={handleSubmit}>
                      <div>
                        <h2 className="text-base font-bold m-auto text-center my-2">
                          出金申請内容確認
                        </h2>
                        <div className="m-auto w-fit">
                          <dl className="p-3 grid grid-cols-2 bg-white">
                            <dt className="text-base font-bold border-r-2">出金金額</dt>
                            <dd className="text-lg font-bold text-primary-dark px-2">{numberWithCommas(amount)}円</dd>
                            <dt className="text-base font-bold border-r-2">銀行名</dt>
                            <dd className="text-lg font-bold text-primary-dark px-2">{state.bankName}</dd>
                            <dt className="text-base font-bold border-r-2">支店名</dt>
                            <dd className="text-lg font-bold text-primary-dark px-2">{state.branchName}</dd>
                            <dt className="text-base font-bold border-r-2">口座種別</dt>
                            <dd className="text-lg font-bold text-primary-dark px-2">{state.accountType}</dd>
                            <dt className="text-base font-bold border-r-2">口座番号</dt>
                            <dd className="text-lg font-bold text-primary-dark px-2">{state.bankAccountNumber}</dd>
                            <dt className="text-base font-bold border-r-2">名義人</dt>
                            <dd className="text-lg font-bold text-primary-dark px-2">{state.accountHolder}</dd>
                          </dl>
                        </div>
                        <div className="mt-10 flex items-center justify-center gap-2 flex-col md:flex-row">
                          <button
                            type="button"
                            onClick={() => setConfirm(false)}
                            className="flex w-60 items-center justify-center rounded-full border-2 px-2 py-2 font-bold text-white bg-gray-500 border-gray-500 sm:w-80 sm:py-3 max-[300px]:w-full">
                            修正する
                          </button>
                          <button
                            type="submit"
                            ref={buttonRef}
                            className="disabled:opacity-50 flex w-60 items-center justify-center rounded-full border-2 px-2 py-2 font-bold text-white bg-primary-dark border-primary-dark sm:w-80 sm:py-3 max-[300px]:w-full">
                            払戻申請をする
                          </button>
                        </div>
                      </div>
                    </form>
                  )
              }
            </div>
          </main> :
          <WithdrawApplicationCompleted/>
      }
    </React.Fragment>
  )
}

export default DepositWithdraw