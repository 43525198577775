import React from 'react';
import { LinkButton } from '../../components/ui/Button';

const WithdrawApplicationCompleted: React.FC = () => {
  return (
    <main className='sm:mt-4 md:mt-14'>
      <div className='container mx-auto sm:px-4'>
        <section className='text-center text-2xl font-bold text-secondary-main md:text-3xl'>
          <h1>払戻申請完了</h1>
        </section>
        <div
          className='my-8 bg-white px-5 py-10 font-semibold opacity-80 sm:my-14 sm:px-10 md:text-lg lg:px-14 max-[400px]:px-2 max-[400px]:text-sm'>
          <p>払戻申請を受け付けました。<br />
            システムよりメールを送信いたしましたので、併せてご確認ください。<br />
            当日を含む5営業日以内に、ご指定の口座にお振込みいたします。<br />
            今しばらくお待ち下さいませ。
          </p>
        </div>
        <LinkButton
          name='マイページトップへ戻る'
          to='/'
          className='border-2 bg-white border-secondary-main text-secondary-main'
        />
      </div>
    </main>);
};

export default WithdrawApplicationCompleted;