import React, { useEffect, useState } from 'react';
import { StepOneConfirm } from './StepOneConfirm';
import { StepOneInput } from './StepOneInput';
import { initialInvestorRegistration, InvestorRegistrationType } from '../../../store/account';
import UserService from '../../../infrastructure/api/user';
import useAxios from '../../../hooks/useAxios';
import {BankType, BranchType} from '../../account/bankAccount';


const StepOne: React.FC = () => {
  const [isConfirm, setIsConfirm] = useState(false);
  const [state, setState] = useState<InvestorRegistrationType>(initialInvestorRegistration);
  const axios = useAxios();
  const userService = new UserService(axios);

  useEffect(() => {
    userService.investorRegistrationInit()
      .then((data) => {
        setState((prev) => ({ ...prev, ...data }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  
  useEffect(() => {
    const elem = window.document.querySelector('html');
    if (elem)
      elem.scrollIntoView();
  }, [isConfirm]);
  
  return (
    <>
      {
        isConfirm ? <StepOneConfirm setIsConfirm={setIsConfirm} state={state} /> :
          <StepOneInput 
            setIsConfirm={setIsConfirm} 
            state={state} 
            setState={setState}
          />
      }
    </>
  );
};

export default StepOne;