import React, { useEffect, useRef, useState } from 'react';
import { Breadcrumb } from '../components/ui/Breadcrumb';
import Image from '../assets/images/haitou.png';
import Pagination from '../components/ui/Pagination';
import { ChevronDownIcon, QuestionMarkCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { DownloadIcon } from '../components/icons/Download';
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';

import 'react-datepicker/dist/react-datepicker.css';
import UserService from '../infrastructure/api/user';
import { GroupedHistoryPaginationType } from '../store/account';
import { PaginationState, usePagination } from '../hooks/usePagination';
import { BACKEND_URL } from '../store';
import useAxios from '../hooks/useAxios';

registerLocale('ja', ja);

const breadcrumbItems = [
  { url: '/', name: 'マイページトップ' },
  { url: '/trading-history', name: '取引履歴' },
];

export const InvestmentHistoryNotice: React.FC = () => {
  return (
    <section className='mt-12 bg-secondary-brown sm:rounded-2xl'>
      <div
        className='grid items-center justify-center gap-5 px-4 pb-10 sm:grid-cols-2 sm:py-14 md:gap-10 lg:px-20'>
        <div className='mx-auto flex items-center justify-center'>
          <img className='w-[80%] sm:w-full lg:w-[80%] max-[480px]:w-full' src={Image} alt='' />
        </div>
        <div
          className='text-lg font-bold leading-7 text-primary-brown sm:text-base lg:text-lg max-[480px]:text-base'>
          出資をすると、このページで<br />
          配当金の予定が確認できるようになります<br />
          お金を増やすために、ちょっとした積立感覚で <br />
          まずはトモタクに出資をしてみましょう
        </div>
      </div>
    </section>
  );
};

interface HistoryLayoutProp {
  date?: string;
  children?: React.ReactNode;
}

export const HistoryLayout: React.FC<HistoryLayoutProp> = ({ children, date }) => {
  return (
    <div className='mt-5 px-2 sm:mt-8 lg:mt-12'>
      <h2
        className='border-b-4 border-opacity-50 border-primary-brown sm:text-[22px]'>{date}</h2>
      <ul
        className='font-bold text-primary-brown sm:text-lg lg:text-xl'>
        {children}
      </ul>
    </div>
  );
};

interface HistoryDataProp {
  type: 'TAX' | 'DIVIDEND' | 'FUND' | 'PAYMENT' | 'WITHDRAWAL' | 'REPAY' | 'RETURN' | 'DEPOSIT' | 'POINT' | 'キャッシュバック';
  typeDisplay: string;
  fundName?: string;
  amount?: string;
  className?: string;
}

const plusList = ['DIVIDEND', 'REPAY', 'RETURN', 'キャッシュバック'];
const minusList = ['TAX'];
const breakdownList = ['DEPOSIT', 'POINT', 'PAYMENT'];

const typeColors = {
  'DIVIDEND': '#F2921C',
  'REPAY': '#3D9D9A',
  'RETURN': '#B5B5B5',
  'TAX': '#8D8D8D',
  'WITHDRAWAL': '#81531A',
  'FUND': '#FFBC05',
  'PAYMENT': '#B75E5E',
  'DEPOSIT': '#8D8D8D',
  'POINT': '#8D8D8D',
  'キャッシュバック': '#3D9D9A',
};

const HistoryData: React.FC<HistoryDataProp> = ({ type, typeDisplay, fundName, amount }) => {
  return (
    <li className='grid pt-2 md:grid-cols-3 md:gap-0 xl:gap-20'>
      <div></div>
      <div className='col-span-2 flex items-center justify-between'>
        <div
          style={{ backgroundColor: typeColors[type] || '' }}
          className='text-white px-2 sm:px-5 tracking-wide md:px-7 py-0.5 sm:py-1 md:py-1.5'>{typeDisplay}</div>
        <div className='text-right'>
          <p className='text-sm font-normal text-opacity-50 text-primary-text'>{fundName || ''}</p>
          <p><span>{plusList.includes(type) ? '＋' : minusList.includes(type) ? '△' : breakdownList.includes(type) && fundName ? '（内訳）' : ''}</span>{amount}{type === 'POINT' ? 'pt' : '円'}</p>
        </div>
      </div>
    </li>
  );
};

interface TradingSearchProp {
  download?: boolean;
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  search: HistorySearchParams;
  setSearch: React.Dispatch<React.SetStateAction<HistorySearchParams>>;
  handleSearch: () => void;
}

const TradingSearch: React.FC<TradingSearchProp> = ({
  download = false,
  startDate,
  setStartDate,
  setSearch,
  search,
  handleSearch,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDivClick = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div
      className={`${download && 'flex flex-col lg:flex-row items-center lg:items-start gap-5 sm:gap-10'} mt-10 bg-secondary-brown lg:grid-cols-2 justify-between py-10 px-2 sm:px-5`}>
      <div>
        <div
          className='flex-1 flex flex-col items-center justify-center gap-5 font-bold text-primary-brown sm:flex-row lg:gap-10'>
          <div className='relative flex w-64 items-center max-[350px]:w-full'>
            <label className='mr-2 sm:text-lg lg:text-xl'>照会区分</label>
            <select
              onChange={(e) => {
                setSearch((prev) => ({ ...prev, 'search_type': e.target.value }));
              }}
              value={search.search_type || 0}
              className='block w-full flex-1 appearance-none rounded bg-white px-2 py-2 pr-8 leading-tight shadow focus:shadow-outline focus:outline-none'>
              <option value=''>選択</option>
              <option value='TAX'>源泉徴収</option>
              <option value='DIVIDEND'>配当</option>
              <option value='FUND'>出資</option>
              <option value='PAYMENT'>入金</option>
              <option value='WITHDRAWAL'>出金</option>
              <option value='REPAY'>償還</option>
              <option value='RETURN'>返還</option>
              <option value='DEPOSIT'>デポジット</option>
              <option value='POINT'>ポイント利用</option>
            </select>
            <div
              className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 bg-primary-brown'>
              <ChevronDownIcon className='h-6 w-6 text-white' />
            </div>
          </div>
          <div className='flex items-center'>
            <label className='mr-2 sm:text-xl'>照会年月</label>
            <DatePicker
              locale='ja'
              dateFormat='yyyy年MMMM'
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              showMonthYearPicker
              className='max-[350px]:w-full sm:w-full flex-1 cursor-default appearance-none rounded bg-white px-2 py-2 leading-tight shadow hover:border-gray-400 focus:shadow-outline focus:outline-none'
            />
          </div>
        </div>
        <div className='mt-4 flex items-center justify-center sm:mt-8'>
          <button
            type='button'
            onClick={handleSearch}
            className='flex w-72 items-center justify-center rounded-full border px-2 font-bold text-white border-primary-brown py-2 bg-primary-brown sm:py-2.5 sm:text-base md:rounded-full md:text-xl lg:w-80 max-[350px]:w-full'>
            検索する
          </button>
        </div>
      </div>
      {download &&
        <div className='w-[70%] sm:w-[60%] lg:w-[40%] flex flex-col flex-wrap'>
          <a
            href={BACKEND_URL + '/api/history/csv/'}
            className='flex w-full items-center justify-center rounded-md border bg-white px-2 text-sm font-bold text-primary-brown border-primary-brown py-2.5 sm:py-4 sm:text-base md:text-xl'>
            全ての履歴をcsvで保存する <DownloadIcon className='ml-2 h-6 w-6' />
          </a>
          <div className='relative'>
            <div className='mt-1 flex cursor-pointer items-center justify-end text-opacity-50 text-primary-text'
              onClick={handleDivClick}>
              <QuestionMarkCircleIcon className='h-5 w-5' />
              <span className='text-sm'>文字化けしてしまう場合</span>
            </div>
            {isModalOpen && (
              <div className='absolute w-full bg-white'>
                <div className='float-right'>
                  <button onClick={handleCloseModal}
                    className='items-center font-bold opacity-50 text-[10px]'>
                    <XMarkIcon
                      className='-mb-1 ml-1 h-4 w-4 font-bold' />close
                  </button>
                </div>
                <div className='px-2 py-3'>
                  <p className='mb-2 font-bold text-primary-brown'>文字化けしてしまう場合</p>
                  <p className='text-[13px]'>ご利用されている端末のos環境/展開しているアプリケーションにより文字化けを起こしてしまう場合がございます。</p>
                  <p
                    className='text-[13px]'> お手数ですがご利用端末のos/アプリケーションで使われている文字コードをお調べの上、保存したファイルをご変更し、ご利用ください。</p>
                </div>
              </div>
            )}
          </div>
        </div>}
    </div>
  );
};

export type HistorySearchParams = PaginationState & {
  search_type: string;
  search_date: string;
}

const defaultHistorySearchParams: HistorySearchParams = {
  page: 1,
  'search_date': '',
  'search_type': '',
};

const TradingHistory: React.FC = () => {
  const [state, setState] = useState<GroupedHistoryPaginationType>({ data: [], 'num_pages': 0 });
  const [hasHistory, setHasHistory] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [search, setSearch] = useState<HistorySearchParams>(defaultHistorySearchParams);
  const pagination = usePagination<HistorySearchParams>(search, setSearch, state.num_pages);
  const isFirstRun = useRef<boolean>(true);
  const userService = new UserService(useAxios());

  useEffect(() => {
    if (!isFirstRun.current) {
      fetchHistories();
    }
  }, [search.page]);

  useEffect(() => {
    const { searchParams } = pagination;
    const searchDate = searchParams.get('search_date');
    const searchType = searchParams.get('search_type');
    const page = searchParams.get('page');
    if (searchDate) {
      setStartDate(new Date(`${searchDate}-1`));
    }
    isFirstRun.current = false;
    fetchHistories(searchParams.toString());
    setSearch({ page: page ? parseInt(page) : 1, 'search_date': searchDate || '', 'search_type': searchType || '' });
    return () => {
      isFirstRun.current = true;
    };
  }, []);

  useEffect(() => {
    if (startDate) {
      setSearch((prev) => ({ ...prev, 'search_date': `${startDate.getFullYear()}-${startDate.getMonth() + 1}` }));
    } else {
      setSearch((prev) => ({ ...prev, 'search_date': '' }));
    }
  }, [startDate]);

  const fetchHistories = (stringParams?: string) => {
    userService.getHistories(search, stringParams)
      .then((data) => {
        setState(data);
      })
      .catch((err) => {
        setHasHistory(false);
      });
  };

  const handleSearch = () => {
    pagination.setParams();
    fetchHistories();
  };

  return (
    <main className='sm:mt-4 md:mt-14'>
      <div className='container mx-auto sm:px-4'>
        <section className='text-center text-2xl font-bold text-primary-brown md:text-3xl'>
          <h1>取引履歴</h1>
        </section>
        {
          !hasHistory ?
            <section className='mt-14 h-[400px]'>
              <p className='text-center'>表示できる履歴はありません</p>
            </section>
            :
            <>
              {
                state.data &&
                <>
                  {
                    pagination.searchParams.toString() &&
                    <TradingSearch setStartDate={setStartDate} startDate={startDate} setSearch={setSearch}
                      search={search} handleSearch={handleSearch} />
                  }
                  {
                    state.data.length === 0 ?
                      <section className='mt-14 h-[400px]'>
                        <p className='text-center'>表示できる履歴はありません</p>
                      </section>
                      :
                      <>
                        {
                          state.data.map((groupedHistory, index) => (
                            <HistoryLayout date={groupedHistory.date} key={index}>
                              {
                                groupedHistory.data.map((history, index) => (
                                  <HistoryData key={index} typeDisplay={history.type_display} type={history.type}
                                    fundName={history.fund}
                                    amount={history.amount} />
                                ))
                              }
                            </HistoryLayout>
                          ))
                        }
                        <div className='mt-10 border-t-4 border-opacity-50 px-2 border-primary-brown'>
                          <Pagination {...pagination} />
                        </div>
                      </>

                  }
                  {
                    !pagination.searchParams.toString() &&
                    <TradingSearch download={true} setStartDate={setStartDate} startDate={startDate}
                      setSearch={setSearch} search={search} handleSearch={handleSearch} />
                  }
                </>
              }
            </>
        }
        <Breadcrumb items={breadcrumbItems} />
      </div>
    </main>);
};

export default TradingHistory;