import React from 'react';
import { ErrorList, ErrorListProps } from './ErrorList';

interface SelectFieldProps<T extends object> extends ErrorListProps {
  options: T[];
  labelKey: keyof T;
  valueKey: keyof T;
  width?: string;
  value?: string;
  label?: string;
  className?: string;
  required?: boolean;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const SelectField = <T extends object>({
                                         options,
                                         labelKey,
                                         valueKey,
                                         name,
                                         value,
                                         width,
                                         label,
                                         onChange,
                                         errors,
                                         className,
                                         required = true,
                                       }: SelectFieldProps<T>): React.ReactElement => {

  return (
    <div className={`${className} flex flex-wrap flex-col mt-3`}>
      <label className='text-base'>
        {label}
        {required && <span className='text-accent-brown'> ※必須</span>}
      </label>
      <ErrorList name={name} errors={errors} />
      <select
        value={value}
        name={name}
        onChange={onChange}
        required={required}
        className={`border border-gray-300 block rounded py-1.5 px-2 ${width}`}
      >
        <option disabled value=''>---選択---</option>
        {options.map((option, index) => (
          <option key={index} value={option[valueKey] as string}>
            {option[labelKey] as string}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectField;