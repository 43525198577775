import React, {useState} from 'react';
import {FundDataHeaderTable} from '../../components/layouts/FundDataHeaderTable';
import FundPrivacyConformation from '../../components/ui/FundPrivacyConformation';
import {RestructuringFund, RestructuringFundData} from '../../db';
import {numberWithCommas} from '../../utlis/numbers';
import {AgreementType, defaultAgreement} from '../../store/funds';

interface RestructuringConformProps {
  data: RestructuringFund;
  state: RestructuringFundData[];
  handleDecline: () => void;
  closeModal: () => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>, i: number) => void;
  handleSubmit: () => void;
}

const RestructuringConform: React.FC<RestructuringConformProps> = ({data, handleDecline, closeModal, state, handleChange, handleSubmit}) => {
  const [agreement, setAgreement] = useState<AgreementType>(defaultAgreement);
  
  return (
    <main className='sm:mt-4 md:mt-12'>
      <div className='container mx-auto sm:px-4'>
        <section className='text-center text-2xl font-bold text-primary-brown md:text-3xl'>
          <h1>出資申込内容確認</h1>
          <p className='mt-4 sm:mt-10 text-base font-medium text-primary-text'>お申込み内容をご確認ください</p>
        </section>

        <section className='mt-10 sm:mt-12'>
          <h2
            className='border-b-4 border-opacity-50 text-lg pb-1 sm:text-2xl text-primary-brown font-bold border-primary-brown'>【{data.fund.restructuring.fund_name}】</h2>
          <div className='lg:px-[10%]'>
            {
              state.map((val, i) => (
                <div key={i} className='mb-10'>
                  <table className='mt-3 w-full border-separate sm:border-spacing-1'>
                    <tbody>
                    <FundDataHeaderTable name='出資口数'>
                      <p>{val.unit}口</p>
                    </FundDataHeaderTable>
                    <FundDataHeaderTable name='出資金額'>
                      <p>{numberWithCommas(val.unit * data.fund.origin.kuchi)}円</p>
                    </FundDataHeaderTable>
                    </tbody>
                  </table>
                  <div
                    className='flex items-center justify-center mx-1 py-3 bg-white max-[450px]:px-3'>
                    <label className='inline-flex cursor-pointer items-center'>
                <span className='mr-2 text-base font-medium md:text-lg'>
                  この出資分を選択肢する
                </span>
                      <input
                        type='checkbox'
                        checked={val.status === 'approved'}
                        onChange={(e) => {handleChange(e, i)}}
                        className='h-5 w-5 form-checkbox text-secondary-main bg-primary-dark'
                      />
                    </label>
                  </div>
                </div>
              ))
            }
          </div>
        </section>
        <div>
          <h3
            className='text-center mb-10 pt-5 sm:text-xl lg:text-2xl font-bold text-accent-brown'>以下の項目を必ずご確認ください</h3>
          <FundPrivacyConformation slug={data.fund.restructuring.slug} setAgreement={setAgreement} agreement={agreement}/>
          <p
            className='sm:text-center mt-10 sm:font-bold px-1 font-medium md:text-lg'>上記の内容で再出資をする場合は「再出資する」ボタンを押してください</p>
        </div>
        <div
          className='mt-8 mb-5 flex flex-wrap items-center justify-between gap-3 px-2 sm:mt-16 sm:mb-10 sm:gap-5 lg:gap-10'>
          <button
            type='button'
            onClick={closeModal}
                className='flex w-4/5 items-center justify-center rounded-full border-2 bg-white px-2 text-sm font-bold text-secondary-main border-secondary-main py-2.5 sm:text-primary-brown sm:border-primary-brown sm:w-72 sm:py-4 sm:text-base md:text-xl lg:w-[45%] max-[400px]:w-full'>
            保留してマイページTOPに戻る
          </button>
          <button
            type='button'
            disabled={!agreement.first || !agreement.second || !agreement.third || state.filter((val) => val.status === 'approved').length === 0}
            onClick={handleSubmit}
            className='disabled:opacity-50 flex w-4/5 items-center justify-center rounded-full border-2 px-2 text-sm font-bold text-white bg-secondary-main sm:bg-primary-dark border-secondary-main sm:border-primary-dark py-2.5 sm:w-72 sm:py-4 sm:text-base md:text-xl lg:w-[45%] max-[400px]:w-full'>
            再出資する
          </button>
        </div>
        <div className='mt-12 flex flex-wrap px-2 items-center justify-center'>
          <button
            type='button'
            onClick={handleDecline}
            className='flex w-4/5 items-center justify-center rounded-full border px-2 text-sm font-bold text-white border-primary-grey py-2.5 bg-primary-grey sm:w-72 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
            更新せず再組成に出資しない
          </button>
        </div>
        <div className='mt-10 border-b-8 pt-12 border-secondary-brown'></div>
      </div>
    </main>
  );
};

export default RestructuringConform;