import React, {useCallback, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import Image from '../../../assets/images/main-logo.png'
import {Notification} from '../../icons/Notification'
import {useNotification} from '../../../hooks/useNotification'
import {Popover, Transition} from '@headlessui/react'
import {usePopper} from 'react-popper'
import UserService from '../../../infrastructure/api/user'
import useAxios from '../../../hooks/useAxios'

type HamburgerButtonProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Logo: React.FC<HamburgerButtonProps> = ({isOpen}) => {
  const {notifications, unreadCount, updateNotification, unreadReset, needToBeFetched} = useNotification()
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const {styles, attributes} = usePopper(referenceElement, popperElement)
  const readRef = useRef<number[]>([])
  const userService = new UserService(useAxios())

  const fetchNotifications = useCallback(() => {
    if (needToBeFetched) {
      userService.getNotifications()
        .then((data) => {
          unreadReset()
          updateNotification(data)
        })
    }
  }, [needToBeFetched])

  return (
    <div className="flex items-center w-full md:w-auto justify-between gap-2 md:justify-items-start">
      <Link to="/" className="z-10">
        <img className="w-44 lg:w-52" src={Image} alt="tomotaqu"/>
      </Link>
      <span className="hidden text-sm font-bold lg:block lg:text-base">マイページ</span>
      {!isOpen &&
        <Popover className="pr-1">
          <Popover.Button
            ref={setReferenceElement}
            onClick={fetchNotifications}
            className="relative inline-flex items-center text-center focus:outline-none border-0 bg-transparent">
            <Notification
              className="h-6 w-6 cursor-pointer"
              fill="#81531A"
            />
            <span className="sr-only">Notifications</span>
            {unreadCount ?
              <div
                className="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-[#DB0000] border-2 border-white rounded-full -bottom-2 -right-2 dark:border-gray-900">
                {unreadCount}
              </div> :
              <></>
            }
          </Popover.Button>
          {
            notifications.length > 0 && (
              <Transition
                enter="transition-opacity duration-100 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-100 ease-out"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Popover.Panel
                  className="absolute z-10"
                  ref={setPopperElement}
                  style={styles.popper}
                  {...attributes.popper}
                >
                  <div
                    className="flex flex-col bg-white rounded-lg divide-y divide-primary-grey/50 shadow-2xl overflow-y-scroll min-w-[260px] max-w-[320px] max-h-[450px]">
                    {
                      notifications.map((notification, index) => (
                        <div key={index}
                             className={`px-4 py-3 ${(() => {
                               if (notification.unread && !readRef.current.includes(notification.id)) {
                                 readRef.current.push(notification.id)
                                 return 'bg-secondary-brown/50'
                               }
                               return ''
                             })()}`}>
                          <span className="text-xs text-primary-grey">{notification.timestamp}</span>
                          <ul>
                            <li className="notification-content"
                                dangerouslySetInnerHTML={{__html: notification.description}}></li>
                          </ul>
                        </div>
                      ))
                    }
                  </div>
                </Popover.Panel>
              </Transition>
            )
          }
        </Popover>
      }
    </div>
  )
}
export default Logo