import { useCallback, useContext } from 'react';
import { NotificationContext } from '../contexts/notification';
import {
  NOTIFICATION_UNREAD_INCREMENT,
  NOTIFICATION_UNREAD_RESET,
  NOTIFICATION_UNREAD_SET,
  NOTIFICATION_UPDATE,
} from '../store/constants';
import { NotificationType } from '../store/notification';

export const useNotification = () => {
  const [state, dispatch] = useContext(NotificationContext);
  const { notifications, unreadCount, needToBeFetched } = state;

  if (!NotificationContext) {
    throw new Error('useNotification should be used inside NotificationProvider');
  }

  const updateNotification = useCallback((data: NotificationType[]) => {
    dispatch({
      type: NOTIFICATION_UPDATE,
      payload: data,
    });
  }, [dispatch]);

  const incrementUnread = useCallback((data: number) => {
    dispatch({
      type: NOTIFICATION_UNREAD_INCREMENT,
      payload: data,
    });
  }, [dispatch]);

  const unreadReset = useCallback(() => {
    dispatch({
      type: NOTIFICATION_UNREAD_RESET,
    });
  }, [dispatch]);

  const unreadSet = useCallback((data: number) => {
    dispatch({
      type: NOTIFICATION_UNREAD_SET,
      payload: data,
    });

  }, [dispatch]);


  return {
    notifications,
    unreadCount,
    updateNotification,
    incrementUnread,
    unreadReset,
    needToBeFetched,
  };
};