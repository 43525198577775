import React from 'react';
import Image from '../../assets/images/haitou.png';

export const InvestmentNotice: React.FC = () => {
  return (
    <section className='bg-secondary-brown mt-10 sm:mt-12 sm:rounded-2xl'>
      <div
        className='grid sm:grid-cols-2 pb-10 sm:py-14 gap-5 px-2 sm:px-4 lg:px-20 md:gap-10 items-center justify-center'>
        <div className='mx-auto flex items-center justify-center'>
          <img className='max-[480px]:w-full w-[80%] sm:w-full lg:w-[80%]' src={Image} alt='' />
        </div>
        <div
          className='text-primary-brown font-bold leading-7 max-[480px]:text-base text-lg sm:text-base lg:text-lg'>出資をすると、このページに出資したファンドが掲載されます
          お金を増やすために、ちょっとした積立感覚で<br /> まずはトモタクに出資をしてみましょう
        </div>
      </div>
    </section>
  );
};