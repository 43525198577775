import React, { useState } from 'react';
import { Breadcrumb } from '../../components/ui/Breadcrumb';
import { Link } from 'react-router-dom';
import Image1 from '../../assets/images/hounnin-01-min.png';
import Image2 from '../../assets/images/hounnin-02-min.png';
import FileInput from '../../components/ui/forms/FileInput';

const breadcrumbItems = [
  { url: '/', name: 'マイページトップ' },
  { url: '/corporate-registration', name: '法人番号登録' },
];
const CorporateNumberRegistration: React.FC = () => {
  const [completed, setCompleted] = useState(false);
  const handleComplete = () => {
    setCompleted(true);
  };
  return (
    <React.Fragment>
      {!completed ?
        <main className='sm:mt-4 md:mt-12'>
          <div className='container mx-auto px-2 sm:px-4'>
            <section className='text-center text-2xl font-bold text-primary-brown md:text-3xl'>
              <h1>法人番号登録</h1>
            </section>
            <section className='mt-10 bg-primary-light'>
              <div className='px-2 py-8 text-center sm:px-[15%]'>
                <p className='mb-3'>法人口座の投資家様には、法人番号の確認できる書類を提出していただいております。</p>
                <Link to='/account/edit/mynumber-registration'
                      className='text-sm font-bold underline underline-offset-4 text-primary-dark sm:text-base'>
                  個人アカウントの方はこちらのリンクからお進みください
                </Link>
              </div>
            </section>
            <section className='mt-12 p-2 bg-secondary-light'>
              <div
                className='bg-white py-3 text-center text-xl font-bold text-secondary-main sm:text-2xl'>法人番号登録時の注意事項
              </div>
              <ul className='py-4 text-lg leading-loose sm:px-10 sm:py-8'>
                <li>■ ファイルサイズは1枚あたり10MBまでです。</li>
                <li>■ 文字が読める画像をご撮影ください。</li>
                <li>■ ファイル形式は、jpeg / png / gifのいずれかとなります。</li>
                <li>■ 文字／法人番号が隠れていないものを撮影ください。</li>
              </ul>
            </section>
            <section className='mt-12 bg-primary-light'>
              <div className='px-2 py-8 text-left sm:px-10 sm:text-center'>
                <p className='bg-2 sm:mb-3 sm:text-lg sm:font-bold lg:text-xl'>
                  法人番号登録の確認に必要な書類データは下記の通りです。<br />
                  この2点の中からどれかおひとつを用意し、法人番号がわかるようにご撮影をお願いいたします
                </p>
              </div>
              <div
                className='grid grid-cols-2 gap-16 py-5 px-[5%] sm:px-[10%] lg:px-20%] xl:px-[25%] max-[500px]:grid-cols-1'>
                <figure>
                  <img src={Image1} alt='' />
                  <p className='mt-2 text-center text-base font-bold text-primary-dark sm:text-lg'>国税庁の法人番号公表サイトの
                    検索結果スクリーンショット</p>
                </figure>
                <figure>
                  <img src={Image2} alt='' />
                  <p
                    className='mt-2 text-center text-base font-bold text-primary-dark sm:text-lg'>履歴事項全部証明書</p>
                </figure>
              </div>
            </section>
            <section className='mt-10 border-y-2 bg-white py-5 border-secondary-main'>
              <h3 className='mb-10 text-center font-bold text-secondary-main'>法人番号登録申請フォーム</h3>
              <p className='sm:text-center'>法人番号と会社名、住所が載った状態の画像をアップロードください。</p>
              <div
                className='mt-8 mb-5 flex flex-wrap items-center justify-center px-2 sm:mb-10'>
                <FileInput value={null} text='撮影画像を添付する' label='クリックしてファイルを選択' name='クリックしてファイルを選択' />
              </div>
              <div className='mt-8 flex items-center justify-center pb-10'>
                <button onClick={handleComplete}
                        disabled
                        className='flex w-4/5 items-center justify-center rounded-full border px-2 text-sm font-bold text-white border-primary-grey py-2.5 bg-primary-grey sm:w-80 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'
                >
                  マイナンバーの登録申請をする
                </button>
              </div>
            </section>
            <Breadcrumb items={breadcrumbItems} className='mt-10' />
          </div>
        </main> :
        <CorporateNumberRegistration />
      }
    </React.Fragment>
  );
};

export default CorporateNumberRegistration;