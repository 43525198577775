import React, { useEffect, useReducer } from 'react';
import {
  defaultNotificationState,
  NotificationActionType,
  notificationReducer,
  NotificationStateType,
} from '../store/notification';
import UserService from '../infrastructure/api/user';
import { NOTIFICATION_UNREAD_SET } from '../store/constants';
import useAxios from '../hooks/useAxios';

type NotificationContextType = [
  NotificationStateType,
  React.Dispatch<NotificationActionType>
]

export const NotificationContext = React.createContext<NotificationContextType>(null!);

interface AccountProviderProps {
  children: React.ReactNode;
}

export const NotificationProvider: React.FC<AccountProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(notificationReducer, defaultNotificationState);
  const userService = new UserService(useAxios());
  useEffect(() => {
    userService.getUnreadNotifications().then((data) => {
      dispatch({
        type: NOTIFICATION_UNREAD_SET,
        payload: data.unread,
      });
    });
  }, []);

  return (
    <NotificationContext.Provider value={[state, dispatch]}>
      {children}
    </NotificationContext.Provider>
  );
};
