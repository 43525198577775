import React from 'react';

export interface ProgressProps {
  progress: number;
  color?: string;
  height?: string;
  label?: boolean;
  kuchi?: string;
  showPercentage?: boolean;
  order?: number;
  width?: string;
  labelTitle?: string;
  lottery?: boolean;
}

export const Progress: React.FC<ProgressProps> = ({
                                                    progress,
                                                    color = 'bg-primary-brown',
                                                    height = 'h-5',
                                                    label,
                                                    kuchi,
                                                    showPercentage, 
                                                    order = 1,
                                                    width = 'w-full',
                                                    labelTitle = '出資率',
                                                    lottery = false
                                                  }) => {
  const progressBar = progress > 100 ? 100 : progress;
  return (

    <div className='mb-1 px-1 sm:px-0'>
      <div className='flex items-center justify-between text-base sm:text-sm'>
        <span>{labelTitle}:{progress ? progress.toFixed(2) : progress}％</span>
        {label && (<span>{order}次募集終了済</span>)}
      </div>
      <div className={` ${height} relative ${width} overflow-hidden rounded-full border bg-white border-secondary-dark`}>
        <div
          className={`min-h-full transition-width transition-slowest ease rounded-full ${color}`}
          style={{ width: `${progressBar}%` }}>
          {showPercentage === true && (
            <div className='absolute flex h-full w-full items-center justify-center py-2 text-[13px]'>
              {progress ? progress.toFixed(2) : progress}%
            </div>)}
        </div>
      </div>
      {kuchi && (<div className='text-center'>{lottery ? '申込口数' : '残り口数'}:{kuchi}口</div>)}
    </div>
  );
};