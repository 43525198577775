import React from 'react';
import { ChevronRightIcon, EnvelopeIcon } from '@heroicons/react/24/solid';
import Image02 from '../../assets/images/register-02.jpg';
import Image03 from '../../assets/images/register-03.jpg';

export const RegistrationSteps: React.FC<{step:number}> = ({step}) => {
  return (
    <section className='mx-auto flex w-full flex-col items-center lg:w-[850px]'>
      <div className='my-8 flex items-stretch gap-1 p-4 sm:gap-4'>
        <div
          className='relative flex h-28 flex-1 flex-col items-center justify-center gap-2 bg-white p-2 shadow-lg w-18 sm:h-28 sm:w-32'>
            
            {step === 1 ? (
              <span className="absolute -top-2 -left-2 flex h-9 w-9 items-center justify-center rounded-full border-2 text-center text-white border-secondary-main bg-secondary-main">
                01
              </span>
            ) : <span className="absolute -top-2 -left-2 flex h-9 w-9 items-center justify-center rounded-full border-2 text-center text-secondary-main border-secondary-main">
                01
              </span>}
          <EnvelopeIcon className='h-10 w-10 text-secondary-main'></EnvelopeIcon>
          {/* <img className='h-10 w-10' src='' alt='envelop icon' /> */}
          <p className='text-center text-xs text-secondary-main'>必要項目<br />の入力</p>
          <span
            className='absolute right-0 bottom-0 h-1/4 w-1/4 border-r-2 border-b-2 border-secondary-main'></span>
        </div>
        <ChevronRightIcon className='m-auto h-4 w-4 fill-secondary-main sm:h-10 sm:w-10' />
        <div
          className='relative flex h-28 flex-1 flex-col items-center justify-center gap-2 bg-white p-2 shadow-lg w-18 sm:h-28 sm:w-32'>
           {step === 2 ? (
              <span className="absolute -top-2 -left-2 flex h-9 w-9 items-center justify-center rounded-full border-2 text-center text-white border-secondary-main bg-secondary-main">
                02
              </span>
            ) : <span className="absolute -top-2 -left-2 flex h-9 w-9 items-center justify-center rounded-full border-2 text-center text-secondary-main border-secondary-main">
                02
              </span>}
          <img className='h-10 w-10' src={Image02} alt='envelop icon' />
          <p className='text-center text-xs text-secondary-main'>本人確認手続き</p>
          <span
            className='absolute right-0 bottom-0 h-1/4 w-1/4 border-r-2 border-b-2 border-secondary-main'></span>
        </div>
        <ChevronRightIcon className='m-auto h-4 w-4 fill-secondary-main sm:h-10 sm:w-10' />
        <div
          className='relative flex h-28 flex-1 flex-col items-center justify-center gap-2 bg-white p-2 shadow-lg w-18 sm:h-28 sm:w-32'>
            {step === 3 ? (
              <span className="absolute -top-2 -left-2 flex h-9 w-9 items-center justify-center rounded-full border-2 text-center text-white border-secondary-main bg-secondary-main">
                03
              </span>
            ) : <span className="absolute -top-2 -left-2 flex h-9 w-9 items-center justify-center rounded-full border-2 text-center text-secondary-main border-secondary-main">
                03
              </span>}
          <img className='h-10 w-10' src={Image03} alt='envelop icon' />
          <p className='text-center text-xs text-secondary-main'>結果を待つ</p>
          <span
            className='absolute right-0 bottom-0 h-1/4 w-1/4 border-r-2 border-b-2 border-secondary-main'></span>
        </div>
      </div>
    </section>
  );
};
