import React, {useEffect, useState} from 'react';
import {DropdownLayout} from './DropdownLayout';
import {FundListing} from './FundListing';
import {Breadcrumb} from '../../components/ui/Breadcrumb';
import {InvestmentNotice} from './InvestmenNotice';
import {Chart} from 'react-google-charts';
import {Link} from 'react-router-dom';
import {InvestmentStatusType} from '../../store/account';
import UserService from '../../infrastructure/api/user';
import useAxios from '../../hooks/useAxios';
import {useWindowSize} from 'usehooks-ts';


const breadcrumbItems = [
  {url: '/', name: 'マイページトップ'},
  {url: '/investment-status', name: '出資状況'},
];

interface Props {
  data: (string | number)[][];
}

export const options = {
  pieHole: 0.4,
  is3D: false,
  legend: 'none',
  pieSliceText: 'none',
  pieStartAngle: 200,
  backgroundColor: 'none',
  chartArea: {
    top: 16,
    left: 16,
    right: 16,
    bottom: 16,
    width: '100%',
    height: '100%',
  },
  slices: {
    0: {color: '#B75E5E'},
    1: {color: '#FFA17A'},
    2: {color: '#00C9C7'},
    3: {color: '#3D9D9A'},
    4: {color: '#DBD28A'},
    5: {color: '#A6DB8A'},
    6: {color: '#B7805E'},
    7: {color: '#7ACCFF'},
    8: {color: '#008BC9'},
    9: {color: '#9F5BA2'},
  },
};


const PieChart: React.FC<Props> = ({data}) => {
  const [fakeControls, setFakeControls] = useState([]);

  const size = useWindowSize();

  useEffect(() => {
    setFakeControls([]);
  }, [size.width]);

  return (
    <div className='relative w-64 h-64 sm:w-[400px] sm:h-[400px]'>
      {
        data.length > 0
          ?
          <Chart
            controls={fakeControls}
            chartType='PieChart'
            width='100%'
            height='100%'
            data={[['ファンド名', '出資口数'], ...data]}
            style={{margin: '0 auto'}}
            options={options}
          />
          :
          <div className="h-full flex items-center">
            <p className="text-center">
              現在、出資されているファンドはございません<br/>
              出資をすると出資中ファンドの割合が一目でわかるグラフが掲載されます
            </p>
          </div>
      }
    </div>
  );
};


const InvestmentStatus: React.FC = () => {
  const [state, setState] = useState<InvestmentStatusType>();
  const [hasFunds, setHasFunds] = useState<boolean>(false);
  const userService = new UserService(useAxios());

  useEffect(() => {
    userService.getInvestmentStatus()
      .then((data) => {
        if (data) {
          setHasFunds(true);
          setState(data);
        }
      });
  }, []);


  return (
    <main className='sm:mt-4 md:mt-10'>
      <div className='container mx-auto sm:px-4'>
        <section className='text-center text-2xl font-bold text-primary-brown md:text-3xl'>
          <h1>出資状況</h1>
        </section>
        {
          !hasFunds ? <InvestmentNotice/> :
            <>
              <section className='mt-10 w-full bg-secondary-brown sm:rounded-2xl'>
                <div className='grid p-2 sm:p-10 lg:grid-cols-2'>
                  {
                    state?.chartData &&
                    <div className='mx-auto'>
                      <PieChart data={state?.chartData}/>
                    </div>
                  }
                  <div
                    className="mt-10 grid grid-cols-2 items-center justify-center gap-3 text-center sm:gap-10 lg:mt-0 max-[400px]:gap-1">
                    {
                      state?.pendingApplications && state.pendingApplications.length > 0 &&
                      <div className="font-bold">
                        <p
                          className="py-3 text-sm bg-primary-main sm:text-lg lg:text-xl max-[400px]:text-xs">申込未完了ファンド件数</p>
                        <p className="bg-white py-3 text-2xl md:text-3xl">{state?.pendingApplications.length}件</p>
                      </div>
                    }
                    <div className="font-bold">
                      <p
                        className="py-3 text-sm bg-primary-main/40 sm:text-lg lg:text-xl max-[400px]:text-xs">運用前ファンド件数</p>
                      <p className="bg-white py-3 text-2xl md:text-3xl">{state?.beforeRunningFunds.length}件</p>
                    </div>
                    <div className="font-bold text-secondary-main">
                      <p
                        className="py-3 text-sm bg-secondary-bright sm:text-lg lg:text-xl max-[400px]:text-xs">運用中ファンド件数</p>
                      <p className="bg-white py-3 text-2xl md:text-3xl">{state?.runningFunds.length}件</p>
                    </div>
                    <div className="font-bold text-primary-brown">
                      <p
                        className="py-3 text-sm bg-primary-light sm:text-lg lg:text-xl max-[400px]:text-xs">運用完了ファンド件数</p>
                      <p className="bg-white py-3 text-2xl md:text-3xl">{state?.finishedFundsCount}件</p>
                    </div>
                  </div>
                </div>
              </section>
              {
                state?.pendingApplications && state.pendingApplications.length > 0 &&
                <DropdownLayout
                  title="申込未完了ファンド一覧"
                  className="bg-primary-main"
                >
                  {
                    state.pendingApplications.map((fund, index) => (
                      <FundListing
                        key={index}
                        fundName={fund.user_finance.fund_name}
                        slug={`pending-list/${fund.id.toString()}`}
                        image={fund.user_finance.thumbnail}
                        investmentAmount={fund.user_finance.amount_of_investment}
                        investmentUnit={fund.user_finance.kuchi}
                        className='text-primary-main'
                      />
                    ))
                  }
                </DropdownLayout>
              }
              {
                state?.beforeRunningFunds && state.beforeRunningFunds.length > 0 &&
                <DropdownLayout
                  title='運用前'
                  className='bg-primary-main/40'
                >
                  {
                    state.beforeRunningFunds.map((fund, index) => (
                      <FundListing
                        key={index}
                        fundName={fund.fund_name}
                        slug={fund.id.toString()}
                        image={fund.thumbnail}
                        investmentAmount={fund.amount_of_investment}
                        investmentUnit={fund.kuchi}
                        className='text-secondary-main'
                      />
                    ))
                  }
                </DropdownLayout>
              }
              {
                state?.runningFunds && state.runningFunds.length > 0 &&
                <DropdownLayout
                  title='運用中'
                  className='bg-secondary-light text-secondary-main'
                >
                  {
                    state.runningFunds.map((fund, index) => (
                      <FundListing
                        key={index}
                        fundName={fund.fund_name}
                        slug={fund.id.toString()}
                        image={fund.thumbnail}
                        investmentAmount={fund.amount_of_investment}
                        investmentUnit={fund.kuchi}
                        className='text-secondary-main'
                      />
                    ))
                  }
                </DropdownLayout>
              }
              {
                state?.finishedFunds && state.finishedFunds.length > 0 &&
                <DropdownLayout
                  title='運用完了'
                  className='bg-secondary-brown text-primary-brown'
                >
                  {
                    state.finishedFunds.map((fund, index) => (
                      <FundListing
                        key={index}
                        fundName={fund.fund_name}
                        slug={`completed-list/${fund.id.toString()}`}
                        image={fund.thumbnail}
                        investmentAmount={fund.amount_of_investment}
                        investmentUnit={fund.kuchi}
                        className='text-primary-brown'
                      />
                    ))
                  }
                  {
                    state.finishedFundsCount > 2 &&
                    <div className='lg:col-span-2 mt-8 flex flex-wrap flex-col items-center justify-center w-full'>
                      <Link to='completed-list'
                            className='flex w-4/5 items-center justify-center rounded-full border px-2 text-sm font-bold text-white border-primary-brown py-2.5 bg-primary-brown sm:w-80 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
                        終了したファンドをもっと見る
                      </Link>
                    </div>
                  }
                </DropdownLayout>
              }
            </>
        }

        <Breadcrumb
          items={breadcrumbItems}
          className='mt-10'
        />
      </div>
    </main>
  );
};
export default InvestmentStatus;

