import { breadcrumbItems, InvestorRegistrationProps } from '../../../store/account';
import { RegistrationSteps } from '../../../components/ui/RegistrationStep';
import { TableLayout } from '../../../components/layouts/TableLayout';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb } from '../../../components/ui/Breadcrumb';
import React from 'react';
import UserService from '../../../infrastructure/api/user';
import useAxios from '../../../hooks/useAxios';
import { db } from '../../../db';

export const StepOneConfirm: React.FC<InvestorRegistrationProps> = ({ setIsConfirm, state }) => {
  const navigate = useNavigate();
  const userService = new UserService(useAxios());
  const handleSave = () => {
    userService.investorRegistration(state, 'post')
      .then(() => {
        db.table('user').update(1, { 'user.fullName': `${state.last_name} ${state.first_name}` });
        navigate('/investor-registration/step2');
      });
  };

  return (
    <main className='sm:mt-4 md:mt-14'>
      <div className='container mx-auto sm:px-4'>
        <section className='text-center text-2xl font-bold text-secondary-main md:text-3xl'>
          <h1>投資家登録</h1>
        </section>
        <RegistrationSteps step={1} />
        <div
          className='grid w-full items-center px-5 py-5 pb-5 max-[450px]:px-3'>
          <p
            className='text-center font-bold text-secondary-main md:text-lg lg:text-xl'>
            入力項目をご確認の上、情報をご登録ください
          </p>
        </div>
        <div className='mx-auto flex w-full flex-col items-center bg-white lg:w-[850px]'>
          <table className='w-full'>
            <tbody>
            <TableLayout
              border='border-secondary-main'
              className='text-secondary-main bg-secondary-bright'
              title='住所'
            >
              <p className='text-primary-text'>
                〒{state.zipcode} <br />
                {state.address1}{state.address2}{state.address3} <br />
                {state.address4 || ''}
              </p>
            </TableLayout>
            <TableLayout
              border='border-secondary-main'
              className='text-secondary-main bg-secondary-bright'
              title='電話番号'
            >
              <p className='text-primary-text'>
                {state.phone}
              </p>
            </TableLayout>
            <TableLayout
              border='border-secondary-main'
              className='text-secondary-main bg-secondary-bright'
              title='性別'
            >
              <p className='text-primary-text'>
                {state.sex}
              </p>
            </TableLayout>
            <TableLayout
              border='border-secondary-main'
              className='text-secondary-main bg-secondary-bright'
              title='ご職業'
            >
              <p className='text-primary-text'>
                {state.occupation}
              </p>
            </TableLayout>
            <TableLayout
              border='border-secondary-main'
              className='text-secondary-main bg-secondary-bright'
              title='勤務先'
            >
              <p className='text-primary-text'>
                {state.workplace}
              </p>
            </TableLayout>
            <TableLayout
              border='border-secondary-main'
              className='text-secondary-main bg-secondary-bright'
              title='年収'
            >
              <p className='text-primary-text'>
                {state.salary}万円
              </p>
            </TableLayout>
            <TableLayout
              border='border-secondary-main'
              className='text-secondary-main bg-secondary-bright'
              title='投資目的'
            >
              <p className='text-primary-text'>
                {state.investment_purpose}
              </p>
            </TableLayout>
            <TableLayout
              border='border-secondary-main'
              className='text-secondary-main bg-secondary-bright'
              title='投資方針'
            >
              <p className='text-primary-text'>
                {state.investment_policy}
              </p>
            </TableLayout>
            <TableLayout
              border='border-secondary-main'
              className='text-secondary-main bg-secondary-bright'
              title='資産状況'
            >
              {state.asset_type.map((val, index) => (
                <p key={index} className='text-primary-text block'>
                  {val.asset_name} {val.asset_amount}万
                </p>
              ))}
              
            </TableLayout>
            <TableLayout
              border='border-secondary-main'
              className='text-secondary-main bg-secondary-bright'
              title='投資経験'
            >
              <p className='text-primary-text whitespace-pre-wrap'>
                {state.investment_experience.join('\n')}
              </p>
            </TableLayout>
            <TableLayout
              border='border-secondary-main'
              className='text-secondary-main bg-secondary-bright'
              title='投資年数'
            >
              <p className='text-primary-text'>
                {state.investment_year}
              </p>
            </TableLayout>
            <TableLayout
              border='border-secondary-main'
              className='text-secondary-main bg-secondary-bright'
              title='投資資金'
            >
              <p className='text-primary-text'>
                {state.investment_funds}
              </p>
            </TableLayout>
            <TableLayout
              border='border-secondary-main'
              className='text-secondary-main bg-secondary-bright'
              title='銀行名'
            >
              <p className='text-primary-text'>
                {state.bank_name}
              </p>
            </TableLayout>
            <TableLayout
              border='border-secondary-main'
              className='text-secondary-main bg-secondary-bright'
              title='支店名'
            >
              <p className='text-primary-text'>
                {state.branch_name}
              </p>
            </TableLayout>
            <TableLayout
              border='border-secondary-main'
              className='text-secondary-main bg-secondary-bright'
              title='口座種別'
            >
              <p className='text-primary-text'>
                {state.account_type}
              </p>
            </TableLayout>
            <TableLayout
              border='border-secondary-main'
              className='text-secondary-main bg-secondary-bright'
              title='口座番号'
            >
              <p className='text-primary-text'>
                {state.account_number}
              </p>
            </TableLayout>
            <TableLayout
              border='border-secondary-main'
              className='text-secondary-main bg-secondary-bright'
              title='口座名義人'
            >
              <p className='text-primary-text'>
                {state.account_holder || `${state.last_name_kana} ${state.first_name_kana}`}
              </p>
            </TableLayout>
            </tbody>
          </table>
        </div>


        <section className='mt-16 mb-10 flex flex-wrap items-center justify-center gap-3 sm:gap-5 lg:gap-10'>
          <button type='button' onClick={() => {
            setIsConfirm(false);
          }}
                  className='flex w-4/5 items-center justify-center rounded-full border-2 bg-white px-2 py-2 text-sm font-bold border-secondary-main text-secondary-main sm:w-72 sm:py-3 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
            内容を修正するために戻る
          </button>
          <button onClick={handleSave}
                  className='flex w-4/5 items-center justify-center rounded-full border-2 px-2 text-sm font-bold text-white border-secondary-main py-2.5 bg-secondary-main sm:w-72 sm:py-3 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
            項目を登録する
          </button>
        </section>
        <Breadcrumb items={breadcrumbItems} />
      </div>
    </main>);
};