import React, { useRef, useState } from 'react';
import EmailChangeCompleted from './EmailChangeCompleted';
import UserService from '../../infrastructure/api/user';
import { ErrorList, ErrorsType } from '../../components/ui/forms/ErrorList';
import { useAccount } from '../../hooks/useAccount';
import useAxios from '../../hooks/useAxios';

interface CodeInputProps {
  handleCode: (ev: React.ChangeEvent<HTMLInputElement>, value: string, index: number) => void;
  handleKey: (ev: React.KeyboardEvent<HTMLInputElement>, index: number) => void;
  char: string;
  index: number;
  maxLength: number;
}

const VerificationInput: React.FC<CodeInputProps> = ({ index, handleCode, handleKey, char, maxLength }) => {

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const pattern = /^\d*$/;
    const target = ev.currentTarget as HTMLInputElement;
    const isValidChar = pattern.test(target.value);

    if (!isValidChar) return;

    handleCode(ev, target.value, index);
  };
  const handleFocus = (ev: React.FocusEvent<HTMLInputElement>) => {
    (ev.currentTarget as HTMLInputElement).select();
  };

  return (
    <input
      type='text'
      inputMode='numeric'
      autoComplete='one-time-code'
      className='w-10 sm:w-12 h-12 sm:h-14 px-3 sm:px-4 border-2 border-gray-500 text-lg font-bold rounded outline-primary-main'
      onChange={handleChange}
      onKeyDown={(ev) => handleKey(ev, index)}
      value={char}
      onFocus={handleFocus}
      maxLength={maxLength}
    />
  );
};

const codeLength = 6;

interface VerificationCodeProps {
  getOPT: () => void;
  email: string;
  buttonRef: React.MutableRefObject<HTMLButtonElement>;
}

const VerificationCode: React.FC<VerificationCodeProps> = ({ email, getOPT, buttonRef }) => {
  const { updateLoginInformation } = useAccount();
  const emptyCode = Array(codeLength).fill('');
  const [code, setCode] = useState(emptyCode);
  const [completed, setCompleted] = useState(false);
  const [errors, setErrors] = useState<ErrorsType>();
  const submitButtonRef = useRef<HTMLButtonElement>(null!);
  const userService = new UserService(useAxios());

  const handleCode = (ev: React.ChangeEvent<HTMLInputElement>, value: string, index: number) => {
    const newCode = [...code];
    const remainingFields = codeLength - index;
    const newValue = value.length ? value.split('', remainingFields) : [''];
    const newValueSize = value.length ? value.length : 1;
    const target = ev.currentTarget as HTMLInputElement;

    newCode.splice(index, newValueSize, ...newValue);
    setCode(newCode);

    if (value.length && value.length < codeLength && index !== codeLength - 1) {
      (target.nextElementSibling as HTMLInputElement || null).focus();
    }
    if (value.length === codeLength) {
      target.blur();
    }
  };

  const handleKey = (ev: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const target = ev.currentTarget as HTMLInputElement;
    if (ev.key === 'Backspace' && target.value === '' && index) {
      (target.previousElementSibling as HTMLInputElement || null).focus();
    }
    if (ev.key === 'ArrowLeft') {
      const prevElement = target.previousElementSibling as HTMLInputElement || null;
      if (prevElement) prevElement.focus();
    }
    if (ev.key === 'ArrowRight') {
      const nextElement = target.nextElementSibling as HTMLInputElement || null;
      if (nextElement) nextElement.focus();
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    submitButtonRef.current.disabled = true;
    e.preventDefault();
    const enteredCode = code.join('');
    userService.verifyOTP(
      { email: email, otp: enteredCode })
      .then(() => {
        updateLoginInformation({ email: email });
        setCompleted(true);
      })
      .catch((err) => {
        setErrors(err);
      })
      .finally(() => {
        submitButtonRef.current.disabled = false;
      });
  };


  return (
    <React.Fragment>
      {
        !completed ?
          <main className='sm:mt-4 md:mt-14 anchor-scroll'>
            <div className='container px-2 mx-auto sm:px-4'>
              <section className='text-center text-2xl font-bold text-secondary-main md:text-3xl'>
                <h1>確認コードの照合</h1>
              </section>
              <form onSubmit={handleSubmit} method='post' className='mt-12'>
                <div className='sm:text-lg'>
                  <p>{email} に確認コードを送信いたしました。</p>
                  <p>確認コードをご入力の上「変更完了」ボタンをクリックください。</p>
                </div>
                <div className='bg-white sm:px-20 py-10 mt-5 flex flex-col gap-5 items-center'>
                  <label className='text-secondary-main text-xl font-bold'>確認コード(数字6桁)</label>
                  <div className='flex max-[300px]:gap-1 max-[480px]:gap-3 sm:gap-3 mt-2'>
                    {code.map((char, index) => (
                      <VerificationInput
                        key={index}
                        handleCode={handleCode}
                        handleKey={handleKey}
                        char={char}
                        index={index}
                        maxLength={codeLength}
                      />
                    ))}
                  </div>
                  <ErrorList name='otp' errors={errors} />
                  <p className='text-lg'>確認コードのメールを再送したい場合は
                    <button ref={buttonRef} onClick={(e) => {
                      e.currentTarget.disabled = true;
                      getOPT();
                    }} className='text-secondary-main'>こちら
                    </button>
                    をクリックください
                  </p>

                </div>
                <div
                  className='mt-12 flex flex-col items-center justify-center max-[500px]:px-2 px-[5%] gap-3 pb-10 sm:gap-5 lg:gap-8'>
                  <button
                    ref={submitButtonRef}
                    type='submit'
                    className='flex w-full items-center justify-center rounded-full border-2 px-2 text-sm font-bold text-white border-primary-dark py-2.5 bg-primary-dark sm:w-full sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-3/5'>
                    変更完了
                  </button>
                  <div className='text-xs sm:text-sm'>
                    <p>メールが届かない場合は、以下の点をご確認ください。<br />
                      ・当社からのメールが迷惑メールとして振り分けられていないか、ご確認ください。<br />
                      ・受信拒否設定により、当社からのメールが届かない場合がございます。<br />
                      「@tomotaqu.com」からのメールを受信できるよう、設定をご確認ください。
                    </p>
                  </div>
                </div>
              </form>
              <div className='border-b-8 border-secondary-brown pt-12'></div>
            </div>
          </main> :
          <EmailChangeCompleted />}
    </React.Fragment>
  );
};

export default VerificationCode;