import React from 'react';

interface FundDataTableProp {
  name?: string;
  children?: React.ReactNode;
}

export const FundDataHeaderTable: React.FC<FundDataTableProp> = ({ name, children }) => {
  return (
    <tr className='flex flex-wrap flex-cols sm:flex-row'>
      <th
        className='w-full px-2 text-left text-white py-1.5 bg-secondary-main border-secondary-brown sm:px-0.5 sm:w-44 sm:border sm:py-2 sm:text-center sm:text-lg lg:text-xl'>{name}
      </th>
      <td className='flex-1 bg-white px-2 py-1.5 border-secondary-brown sm:border sm:py-2 sm:text-lg lg:text-xl'>
        {children}
      </td>
    </tr>
  );
};