import React, { useEffect, useMemo, useState } from 'react';
import { Breadcrumb } from '../components/ui/Breadcrumb';
import Image from '../assets/images/haitou.png';
import { Link } from 'react-router-dom';
import UserService from '../infrastructure/api/user';
import useAxios from '../hooks/useAxios';
import { extractMonth, extractYear, getMonthsUntilNextYear } from '../utlis/date';
import { UserGroupedDividendType } from '../store/account';
import { numberWithCommas } from '../utlis/numbers';

const breadcrumbItems = [
  { url: '/', name: 'マイページトップ' },
  { url: '/dividend-schedule', name: '配当スケジュール' },
];

interface DividendTitleLayoutProp {
  year?: string;
  children?: React.ReactNode;
}

interface DividendDataProp {
  month: string | number;
  amount: string | number;
}

export const InvestmentHistoryNotice: React.FC = () => {


  return (
    <section className='mt-10 sm:mt-12 bg-secondary-brown sm:rounded-2xl'>
      <div
        className='grid items-center justify-center gap-5 px-4 pb-10 sm:grid-cols-2 sm:py-14 md:gap-10 lg:px-20'>
        <div className='mx-auto flex items-center justify-center'>
          <img className='w-[80%] sm:w-full lg:w-[80%] max-[480px]:w-full' src={Image} alt='' />
        </div>
        <div
          className='text-lg font-bold leading-7 text-primary-brown sm:text-base lg:text-lg max-[480px]:text-base'>
          出資をすると、このページで<br />
          配当金の予定が確認できるようになります<br />
          お金を増やすために、ちょっとした積立感覚で <br />
          まずはトモタクに出資をしてみましょう
        </div>
      </div>
    </section>
  );
};


const DividendTitleLayout: React.FC<DividendTitleLayoutProp> = ({ children, year }) => {
  return (
    <div className='mt-12 px-2'>
      <h2 className='border-b-4 text-xl font-bold text-primary-brown border-primary-dark sm:text-3xl'>{year}年</h2>
      <ul
        className='mt-10 grid grid-cols-2 gap-10 font-bold text-primary-brown sm:text-lg md:grid-cols-3 lg:text-[23px] xl:grid-cols-4'>
        {children}
      </ul>
    </div>
  );
};


const DividendData: React.FC<DividendDataProp> = ({ month, amount }) => {
  return (
    <li>
      <span>{month}月</span>
      <div className='border-b-4 pt-2 border-secondary-brown'>
        <p className='text-right'>{numberWithCommas(amount) || 0}<span>円</span></p>
      </div>
    </li>

  );
};

const DividendSchedule: React.FC = () => {
  const [groupedDividend, setGroupedDividend] = useState<UserGroupedDividendType>([]);
  const [hasDividend, setHasDividend] = useState<boolean>(true);
  const userService = new UserService(useAxios());
  useEffect(() => {
    userService.getDividends()
      .then((data) => {
        if (data) {
          setGroupedDividend(data);
        } else {
          setHasDividend(false);
        }
      });
  }, []);

  const memorizedYearMonth = useMemo(() => getMonthsUntilNextYear(), []);

  return (
    <main className='sm:mt-10 md:mt-14'>
      <div className='container mx-auto sm:px-4'>
        <section className='text-center text-2xl font-bold text-primary-brown md:text-3xl'>
          <h1>配当スケジュール</h1>
        </section>
        {
          hasDividend ?
            <>
              {
                Object.keys(memorizedYearMonth).map((key) => (
                  <DividendTitleLayout year={key} key={key}>
                    {
                      memorizedYearMonth[key].map((month, index) => {
                        const dividend = groupedDividend.find((val) => extractYear(val.year) === key && extractMonth(val.month) == month);
                        return <DividendData month={month} amount={dividend ? dividend.total_receive : 0}
                                             key={index} />;
                      })
                    }
                  </DividendTitleLayout>
                ))
              }
              <div className='mt-5 px-2 text-primary-brown'>
                <p>※実際の配当金額は、所得税及び復興所得税(20.42％)を控除させていただきます。</p>
                <p>※記載の数値は想定です。変更となる可能性がございます。</p>
              </div>

              <div className='mt-8 flex items-center justify-center px-2 pb-10'>
                <Link to='/trading-history'
                      className='flex w-4/5 items-center justify-center rounded-full border px-2 text-sm font-bold text-white border-primary-brown py-2.5 bg-primary-brown sm:w-86 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
                  過去の取引履歴はこちら
                </Link>
              </div>
            </> :
            <InvestmentHistoryNotice />
        }
        <Breadcrumb items={breadcrumbItems} />
      </div>
    </main>);
};

export default DividendSchedule;