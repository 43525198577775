import React, { useEffect, useReducer } from 'react';
import { AccountActionType, accountReducer, AccountType, defaultAccountState } from '../store/account';
import UserService from '../infrastructure/api/user';
import { ACCOUNT_STATE_UPDATE } from '../store/constants';
import useAxios from '../hooks/useAxios';

type AccountContextType = [
  AccountType,
  React.Dispatch<AccountActionType>
]

export const AccountContext = React.createContext<AccountContextType>(null!);

interface AccountProviderProps {
  children: React.ReactNode;
}

export const AccountProvider: React.FC<AccountProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, defaultAccountState);
  const userService = new UserService(useAxios());

  useEffect(() => {
    userService.getAccountInfo().then((data) => {
      dispatch({
        type: ACCOUNT_STATE_UPDATE,
        payload: data,
      });
    });
  }, []);

  return (
    <AccountContext.Provider value={[state, dispatch]}>
      {children}
    </AccountContext.Provider>
  );
};
