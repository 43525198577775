import React from 'react';
import { FileImportIcon } from '../../icons/FileImportIcon';

interface FileInputProps {
  value?: string | ArrayBuffer | null;
  text?: string;
  label?: string;
  name?: string;
  required?: boolean;
  onChange?: (file: string | ArrayBuffer | null) => void;
}

const FileInput: React.FC<FileInputProps> = ({ value, name, label, required, text, onChange }) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files, name } = event.target;
    if (files && onChange) {
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.readyState === 2) {
            onChange(reader.result);
          }
        };
        reader.readAsDataURL(files[i]);
      }
    }
  };

  return (
    <div className='w-4/5 items-center rounded-full sm:w-80 lg:w-5/12 max-[400px]:w-full'>
      {text ? <p>{text}</p> : null}
      <label htmlFor={`fileInput-${name}`}
             className={`flex w-full items-center justify-center px-2 text-sm font-bold text-white py-2.5 sm:py-4 sm:text-base rounded-xl md:text-xl ${value ? 'bg-secondary-main' : 'bg-primary-text'}`}>
        <FileImportIcon className='h-6 w-6 mr-2' />
        {label}
      </label>
      <input
        id={`fileInput-${name}`}
        type='file'
        name={name}
        required={required}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </div>
  );
};

export default FileInput;