import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from './Breadcrumb';

const breadcrumbItems = [
  { url: '/', name: 'マイページトップ' },
  { url: '/mynumber-registration', name: 'マイナンバー登録' },
  { url: '', name: 'マイナンバー登録申請完了' },
];

const MyNumberRegistrationCompleted: React.FC = () => {
  return (
    <main className='sm:mt-4 md:mt-14'>
      <div className='container mx-auto sm:px-4'>
        <section className='text-center text-2xl font-bold text-primary-brown md:text-3xl'>
          <h1>マイナンバー登録申請完了</h1>
        </section>
        <div
          className='my-8 px-5 py-10 leading-relaxed tracking-wide opacity-80 bg-secondary-brown sm:my-14 sm:px-10 md:text-lg lg:px-14 max-[400px]:px-2 max-[400px]:text-sm'>
          <p>ご登録申請を承りました。<br />
            申請結果は反映され次第、システムよりメールを送信いたします。<br />
            またマイページのベルマークにも通知されます。<br />
            結果反映まで、今しばらくお待ちください。
          </p>
        </div>
        <div className='mt-8 flex items-center justify-center pb-10'>
          <Link to='/'
                className='flex w-4/5 items-center justify-center rounded-full border-2 px-2 text-sm font-bold text-primary-dark border-primary-dark py-2.5 sm:w-80 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
            マイページトップへ戻る
          </Link>
        </div>
        <Breadcrumb items={breadcrumbItems} />

      </div>
    </main>);
};

export default MyNumberRegistrationCompleted;