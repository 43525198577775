import { Axios } from 'axios';

class NewsService {
  axios: Axios;

  constructor(axios: Axios) {
    this.axios = axios;
  }

  getHomeNews() {
    return this.axios.get('/api/home/news/')
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        return [];
      });
  }

  getListNews(page: string | number) {
    return this.axios.get(`/api/news/list/?page=${page}`)
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        return [];
      });
  }

  getDetailNews(pk: string) {
    return this.axios.get(`/api/news/details/${pk}/`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return [];
      });
  }

}

export default NewsService;
