import React, {useState} from 'react';
import {
  assetTypeOptions,
  breadcrumbItems,
  experienceChoice,
  genderOptions, investmentFundsOptions,
  investmentPolicyOptions,
  InvestorRegistrationInputProps,
  occupationOptions,
  purposeOfInvestOptions,
  yearsOfInvestOptions,
} from '../../../store/account';
import {ChoiceOptionType} from '../../../store';
import {RegistrationSteps} from '../../../components/ui/RegistrationStep';
import {TwoColumnTableLayout} from '../../../components/layouts/TwoColumnTableLayout';
import {InputField} from '../../../components/ui/forms/Input';
import {Alert} from '../../../components/icons/Alert';
import {RadioButtonWithLabel} from '../../../components/ui/forms/RadioInput';
import SelectField from '../../../components/ui/forms/SelectField';
import CheckBox from '../../../components/ui/forms/CheckBox';
import {Breadcrumb} from '../../../components/ui/Breadcrumb';
import CheckBoxWithInput from '../../../components/ui/forms/CheckBoxWithInput';
import UserService from '../../../infrastructure/api/user';
import {ErrorsType} from '../../../components/ui/forms/ErrorList';
import BackendService from '../../../infrastructure/api';
import useAxios from '../../../hooks/useAxios';
import {BankDetailInput} from '../../../components/layouts/BankDetailInput';


export const StepOneInput: React.FC<InvestorRegistrationInputProps> = (props) => {
  const {
    setIsConfirm,
    state,
    setState,
  } = props;
  
	const axios = useAxios();
	const userService = new UserService(axios);
	const backendService = new BackendService(axios);
	const [errors, setErrors] = useState<null | ErrorsType>(null);
	const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
		const {name, value} = e.target;
		setState((prev) => ({...prev, [name]: value}));
	};

	const getAddress = () => {
		backendService
			.getAddress(state.zipcode)
			.then((data) => {
				if (data !== 400)
					setState((prev) => ({...prev, ...data}));
			});
	}

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		userService.investorRegistration(state, 'confirm')
			.then((data) => {
				if (data['detail'] === 'valid') {
					setIsConfirm(true);
				}
			})
			.catch((error) => {
				const errorList = document.querySelectorAll(`[name=${Object.keys(error)[0]}]`);
				if (errorList)
					errorList[0].scrollIntoView({
						behavior: 'smooth',
						block: 'center',
						inline: 'start',
					});
				setErrors(error);
			});
	};
  

	return (
		<main className='sm:mt-4 md:mt-14'>
			<form onSubmit={handleSubmit} className='container mx-auto sm:px-4'>
				<section className='text-center text-2xl font-bold text-secondary-main md:text-3xl'>
					<h1>投資家登録</h1>
				</section>
				<RegistrationSteps step={1}/>
				<div
					className='grid w-full items-center px-5 py-5 pb-5 max-[450px]:px-3'>
					<p
						className='text-center font-bold text-secondary-main md:text-lg lg:text-xl'>
						以下の項目をご入力頂き、確認ボタンを押してください
					</p>
				</div>
				<div className='mx-auto lg:w-[992px]'>
					<TwoColumnTableLayout
						title='氏名'
					>
						<div className='mb-3 flex flex-col flex-wrap px-2 py-5 sm:px-8'>
							<div className='flex flex-col xl:flex-row'>
								<InputField
									name='last_name'
									label='姓'
									width='max-[400px]:w-full lg:w-3/4  xl:w-80 sm:mr-10'
									onChange={handleChange}
									value={state.last_name}
									required
									errors={errors}
								/>
								<InputField
									label='名'
									name='first_name'
									width='max-[400px]:w-full lg:w-3/4 xl:w-80 sm:mr-10'
									onChange={handleChange}
									value={state.first_name}
									required
									errors={errors}
								/>
							</div>
							<div className='flex flex-col xl:flex-row'>
								<InputField
									name='last_name_kana'
									label='セイ(フリガナ)'
									width='max-[400px]:w-full lg:w-3/4 xl:w-80 sm:mr-10'
									onChange={handleChange}
									value={state.last_name_kana}
									required
									errors={errors}
								/>
								<InputField
									label='メイ(フリガナ)'
									name='first_name_kana'
									width='max-[400px]:w-full lg:w-3/4 xl:w-80 sm:mr-10'
									onChange={handleChange}
									value={state.first_name_kana}
									required
									errors={errors}
								/>
							</div>
						</div>
					</TwoColumnTableLayout>
					<TwoColumnTableLayout
						title='住所'
						className='mt-5'
					>
						<div className='mb-3 flex flex-col flex-wrap px-2 py-5 sm:px-8'>
							<InputField
									label='郵便番号'
									name='zipcode'
									width='max-[400px]:w-full lg:w-3/4 sm:mr-10'
									suggestion='※ハイフン不要、半角数字でご入力ください'
									onChange={handleChange}
									value={state.zipcode}
									required
									errors={errors}
									button={true}
									buttonText='検索'
									onClick={getAddress}
								/>
							<InputField
								label='都道府県'
								name='address1'
								width='max-[400px]:w-full lg:w-3/4 sm:mr-10'
								onChange={handleChange}
								value={state.address1}
								required
								errors={errors}
							/>

							<InputField
								label='市区町村'
								name='address2'
								width='max-[400px]:w-full lg:w-3/4 sm:mr-10'
								onChange={handleChange}
								value={state.address2}
								required
								errors={errors}
							/>
							<InputField
								label='番地'
								name='address3'
								width='max-[400px]:w-full lg:w-3/4 sm:mr-10'
								onChange={handleChange}
								value={state.address3}
								required
								errors={errors}
							/>
							<InputField
								name='address4'
								label='建物名・号数'
								onChange={handleChange}
								value={state.address4 || ''}
								width='max-[400px]:w-full lg:w-3/4 sm:mr-10'
								suggestion='※建物名や部屋番号がある方は忘れずにご入力ください'
								required={false}
								errors={errors}
							/>
						</div>
					</TwoColumnTableLayout>
					<section className='bg-[#F7EADA] mt-5 p-5'>
						<div className='items-center gap-10 text-center font-semibold text-accent-brown sm:inline-flex'>
							<p className='inline-flex w-auto items-center'>
								<Alert className='h-10 w-10 fill-primary-dark text-accent-brown'/>
								<span>ご注意下さい</span>
							</p>

							<p
								className='flex-1 text-left'>ご登録されるご住所は、必ず本人確認書類(免許証、保険証、マイナンバーカード等)の住所表記と完全一致になるよう、ご入力ください(市町村漏れ、号数の表記間違いにより本人確認不備になる方が多く見受けられます。ご注意ください)</p>
						</div>

					</section>
					<TwoColumnTableLayout
						title='会員情報'
						className='mt-5'
					>
						<div className='mb-3 flex flex-col flex-wrap px-2 py-5 sm:px-8'>
							<InputField
								label='電話番号'
								name='phone'
								width='max-[400px]:w-full lg:w-3/4 sm:mr-10'
								onChange={handleChange}
								value={state.phone}
								suggestion='※半角数字で入力ください'
								required
								errors={errors}
							/>
							<RadioButtonWithLabel
								<ChoiceOptionType>
								value={state.sex}
								label='性別'
								name='sex'
								options={genderOptions}
								valueKey='value'
								labelKey='label'
								onChange={handleChange}
								required
							/>
							<SelectField
								options={occupationOptions}
								valueKey='value'
								labelKey='label'
								name='occupation'
								value={state.occupation}
								label='職業'
								width='max-[400px]:w-full lg:w-3/4 sm:mr-10'
								required
								onChange={handleChange}
								errors={errors}
							/>
							<InputField
								label='会社名'
								name='workplace'
								onChange={handleChange}
								value={state.workplace}
								width='max-[400px]:w-full lg:w-3/4 sm:mr-10'
								errors={errors}
                required={state.occupation !== '主婦' && state.occupation !== '学生'}
							/>
							<div className='flex w-full items-end'>
								<InputField
									label='年収'
									name='salary'
									onChange={handleChange}
									value={state.salary}
									className='w-full md:w-[65%]'
									width='max-[400px]:w-full  w-full sm:mr-10'
									required
									errors={errors}
								/>
								<span className='ml-2 w-10'> 万円</span>
							</div>
							<SelectField
								options={investmentPolicyOptions}
								valueKey='value'
								labelKey='label'
								name='investment_policy'
								width='max-[400px]:w-full lg:w-3/4 sm:mr-10'
								value={state.investment_policy}
								label='投資方針'
								onChange={handleChange}
								errors={errors}
							/>
							<SelectField
								options={purposeOfInvestOptions}
								valueKey='value'
								labelKey='label'
								name='investment_purpose'
								width='max-[400px]:w-full lg:w-3/4 sm:mr-10'
								value={state.investment_purpose}
								label='投資目的'
								onChange={handleChange}
								errors={errors}
							/>
							<SelectField
								options={yearsOfInvestOptions}
								valueKey='value'
								labelKey='label'
								name='investment_year'
								width='max-[400px]:w-full lg:w-3/4 sm:mr-10'
								value={state.investment_year}
								label='投資年数'
								onChange={handleChange}
								errors={errors}
							/>
							<CheckBoxWithInput
								options={assetTypeOptions}
								values={state.asset_type}
								onChange={(values) => {
									setState((prev) => ({...prev, 'asset_type': values}));
								}}
								valueKey='value'
								labelKey='label'
								inputName='asset_amount'
								checkboxName='asset_name'
								name='asset_type'
								className='ml-2 grid gap-1 sm:grid-cols-2'
								errors={errors}
								label='資産状況(複数選択・入力可)'
							/>
              <RadioButtonWithLabel
								<ChoiceOptionType>
								value={state.investment_funds}
								label='投資資金'
								name='investment_funds'
								options={investmentFundsOptions}
								valueKey='value'
								labelKey='label'
								onChange={handleChange}
								required
							/>
							<CheckBox
								<ChoiceOptionType>
								label='投資経験(複数選択・入力可)'
								options={experienceChoice}
								values={state.investment_experience}
								onChange={(values) => {
									setState((prev) => ({...prev, 'investment_experience': values}));
								}}
								valueKey='value'
								labelKey='label'
								name='investment_experience'
								className='ml-2 grid gap-1 sm:grid-cols-2'
								errors={errors}
							/>
						</div>
					</TwoColumnTableLayout>
          <BankDetailInput
            renderCount={0}
            state={state} 
            setState={setState}
            errors={errors}
            fullNameKana={`${state.last_name_kana} ${state.first_name_kana}`}
          />
				</div>
				<section className='mt-10 mb-10 flex flex-wrap items-center justify-center gap-3 sm:mt-16 sm:gap-5 lg:gap-10'>
					<button type='submit'
					        className='flex w-4/5 items-center justify-center rounded-full border-2 px-2 text-sm font-bold text-white border-secondary-main py-2.5 bg-secondary-main sm:w-72 sm:py-3 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
						入力確認画面へ進む
					</button>
				</section>
				<Breadcrumb items={breadcrumbItems}/>
			</form>
		</main>
	);
};