export type RecruitingTypeWSMessage = {
	id: number;
	units_remainder: number;
	wfc_units_remainder: number;
};

export type RecruitingType = RecruitingTypeWSMessage & {
	start: string;
	end: string;
	result_date: string;
	units: number
	name: 'a' | 'b';
	name_display: string;
	finished: boolean;
	wfc_units: number;
	will_use_wfc: boolean;
	money_transfer_deadline: string;
	wfc_transfer_deadline: string;
	applied: boolean;
}

export type FundType = {
	slug: string;
	amount_raised: number;
	fund_name: string;
	thumbnail: string;
	current_status: string;
	percent: string;
	duration: string;
	duration_type: string;
	operation_start: string;
	operation_end: string;
	total_days: number;
	recruiting: RecruitingType[];
	current_recruitment: RecruitingType | null;
	next_recruitment: RecruitingType | null;
	kuchi: number;
	number_kuchi: number;
	total_dist: number;
	tags: string[];
}

export type ComingSoonDetailType = {
	title: string
	sub_title: string,
	note: string,
	published: string
}

export type ComingSoonFundType = {
	thumbnail: string;
	detail: ComingSoonDetailType;
}

export type FundListType = {
	funds: FundType[];
	comingSoon: ComingSoonFundType | null;
}

export type AgreementType = {
	first: boolean,
	second: boolean,
	third: boolean
}

export const defaultAgreement: AgreementType = {
	first: false,
	second: false,
	third: false,
};

export type FundApplyType = {
	fund: string;
	reserved_point: number;
	units: number;
}


export type WFCType = {
	fund: string;
	point: number;
	units: number;
}


export type DocumentDetailType = {
	name: string;
	url: string;
	last_seen?: string;
}

export type FundDocumentsType = {
	pre_contract?: DocumentDetailType;
	contract?: DocumentDetailType;
	anonymous_partnership_contracts?: DocumentDetailType;
	electronic_transaction?: DocumentDetailType;
	property_data?: DocumentDetailType;
	file?: string;
}

export type InvestedFundDocumentType = {
	fund_name: string;
	documents: FundDocumentsType;
}

export type ElectronicDocumentType = {
	invested_funds: InvestedFundDocumentType[];
	annual_transaction_reports: DocumentDetailType[];
	financial_statements: DocumentDetailType[];
}


export type PropertyListType = {
	id: number,
	land_form_rights: string,
	building_form_rights: string,
	building_type: string,
	land_equity: string,
	land_address: string,
	land_lot_num: string,
	land_ground: string,
	land_area: string,
	building_address: string,
	building_house_num: string,
	building_structure: string,
	building_floor_space: string,
	building_construct_time: string,
	excl_dep_address: string,
	excl_dep_type: string,
	excl_dep_structure: string,
	related_fund: number
}

export type PreContractType = {
	id: number,
	operation_start_time: string,
	operation_finish_time: string,
	transfer_deadline: string,
	property_list: PropertyListType,
	doc_name: string,
	fund_name: string,
	total_investment: number,
	amount_raised: number,
	number_kuchi: number,
	min_num_units: number,
	kuchi: number,
	percent: number,
	comp_name: string,
	trade_name: string,
	comp_address: string,
	name_of_representative: string,
	capital: number,
	shareholders: string,
	name_of_officers: string,
	other_trade_names: string,
	payment_date: string,
	comp_tel: string,
	comp_email: string,
	business_manager: string,
	master_lease: string,
	schedule_sub_invest: string,
	price_of_target_estate: string,
	calc_desc_of_price: string,
	result_method_appraisal: string,
	total_tenants: string,
	total_leased_area: string,
	leasable_area: string,
	rental_occupancy_rate: string,
	type_of_contract: string,
	tenant_name: string,
	master_lease_contract_amount: string,
	rental_area: string,
	contract_period: string,
	contract_renew_methods: string,
	deposit: string,
	important_matters: string,
	payment_status: string,
	total_rental_business_cost: string,
	application_period_method: string,
	recruitment_invest_acquisition: string,
	calc_period_start: string,
	calc_period_finish: string,
	calc_period_month: string,
	new_additional_text: string,
	new_additional_text_2: string,
	matters_pdf: null,
	others: string,
	related_fund: number,
	before_operation_date: string,
}
