import { Axios } from 'axios';

class BackendService {
  axios: Axios;

  constructor(axios: Axios) {
    this.axios = axios;
  }

  getAddress(zipcode: string) {
    return this.axios.get('/api/zipcode/', {
      params: {
        zipcode: zipcode,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        return 400;
      });
  }

  getBank(name: string) {
    return this.axios.post('/banks',
      {
        name: name,
      },
      {
        baseURL: 'https://fcdflwdklg.execute-api.ap-northeast-1.amazonaws.com',
      },
    )
      .then((res) => res.data)
      .catch(() => []);
  }

  getBranch(bankId: number, name: string) {
    return this.axios.post('/branches',
      {
        'bank_id': bankId,
        name: name,
      },
      {
        baseURL: 'https://fcdflwdklg.execute-api.ap-northeast-1.amazonaws.com',
      },
    )
      .then((res) => res.data)
      .catch(() => []);
  }
  
  getCampaigns() {
    return this.axios.get('/api/campaign/')
      .then((res) => res.data)
      .catch(() => []);
  }
}

export default BackendService;