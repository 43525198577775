import React, { useReducer } from 'react';
import { defaultLoading, LoadingActionType, loadingReducer } from '../store/loading';

type LoadinContextType = [
  {
    loading: boolean,
    activeLoading: number
  },
  React.Dispatch<LoadingActionType>
]

export const LoadingContext = React.createContext<LoadinContextType>(null!);

interface LoadingProviderProps {
  children: React.ReactNode;
}

export const LoadingProvider: React.FC<LoadingProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(loadingReducer, defaultLoading);

  return (
    <LoadingContext.Provider value={[state, dispatch]}>
      {
        state.loading &&
        <div className='fixed top-0 left-0 h-screen w-screen flex items-center justify-center z-50 bg-black/90'>
          <div className='text-white font-bold text-3xl relative -top-[3.78em]'>
            <span
              className='relative mr-1 top-[0.63em] inline-block uppercase opacity-0 -rotate-x-90 animate-text-drop animation-delay-[0.3s]'>T</span>
            <span
              className='relative mr-1 top-[0.63em] inline-block uppercase opacity-0 -rotate-x-90 animate-text-drop animation-delay-[0.4s]'>O</span>
            <span
              className='relative mr-1 top-[0.63em] inline-block uppercase opacity-0 -rotate-x-90 animate-text-drop animation-delay-[0.5s]'>M</span>
            <span
              className='relative mr-1 top-[0.63em] inline-block uppercase opacity-0 -rotate-x-90 animate-text-drop animation-delay-[0.6s]'>O</span>
            <span
              className='relative mr-1 top-[0.63em] inline-block uppercase opacity-0 -rotate-x-90 animate-text-drop animation-delay-[0.7s]'>T</span>
            <span
              className='relative mr-1 top-[0.63em] inline-block uppercase opacity-0 -rotate-x-90 animate-text-drop animation-delay-[0.8s]'>A</span>
            <span
              className='relative text-primary-dark mr-1 top-[0.63em] inline-block uppercase opacity-0 -rotate-x-90 animate-text-drop animation-delay-[0.9s]'>Q</span>
            <span
              className='relative mr-1 top-[0.63em] inline-block uppercase opacity-0 -rotate-x-90 animate-text-drop animation-delay-[1s]'>U</span>
          </div>
        </div>
      }
      {children}
    </LoadingContext.Provider>
  );
};
