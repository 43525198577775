import React from 'react';
import { ComingSoonFundType } from '../../store/funds';

export const ComingSoon: React.FC<ComingSoonFundType> = (props) => {
  return (
    <div className='mt-14 grid items-center justify-center gap-5 md:grid-cols-2 lg:gap-10'>
      <figure
        className='h-72 overflow-hidden opacity-50 sm:h-80 sm:rounded-2xl lg:h-[300px] xl:h-[350px] max-[500px]:h-full'>
        <img className='h-full w-full object-fill' src={props.thumbnail} alt='' />
      </figure>
      <div>
        <h2 className='text-center text-4xl font-bold text-secondary-main'>{props.detail.title}</h2>
          <p className="text-xl text-center pt-2">{props.detail.sub_title}</p>
        <p className='mt-5 bg-white px-5 py-5 whitespace-break-spaces text-center'
           dangerouslySetInnerHTML={{ __html: props.detail.note }}>
        </p>
      </div>
    </div>
  );
};