import React, {useEffect, useState} from 'react'
import {InputField} from '../ui/forms/Input'
import {RadioButton, RadioButtonWithLabel} from '../ui/forms/RadioInput'
import {accountType, BankType, BranchType} from '../../pages/account/bankAccount'
import BackendService from '../../infrastructure/api'
import useAxios from '../../hooks/useAxios'
import {BankAccountType} from '../../store/account'
import {ErrorsType} from '../ui/forms/ErrorList'

interface BankDetailInputProps<T> {
  state: T;
  setState: React.Dispatch<React.SetStateAction<T>>;
  fullNameKana: string;
  errors?: ErrorsType | null;
  renderCount: number;
}

export const BankDetailInput = <T extends BankAccountType>(props: BankDetailInputProps<T>) => {
  const {
    state,
    setState,
    errors,
    fullNameKana,
    renderCount
  } = props
  const [banks, setBanks] = useState<BankType[]>([])
  const [branches, setBranches] = useState<BranchType[]>([])
  const [bankSearch, setBankSearch] = useState('')
  const [branchSearch, setBranchSearch] = useState('')
  const backendService = new BackendService(useAxios())
  useEffect(() => {
    setBankSearch(state.bank_name)
    setBranchSearch(state.branch_name)
    if (state.bank_id) {
      setBanks([{
        hiragana: state.bank_hiragana,
        name: state.bank_name,
        'official_name': state.bank_name,
        id: state.bank_id,
      }])
      if (state.branch_id) {
        setBranches([{
          'name_hiragana': state.branch_hiragana,
          'bank_id': state.bank_id,
          name: state.branch_name,
          id: state.branch_id,
        }])
      }
    }
  }, [renderCount])

  const handleBankSearch = () => {
    setState((prev) => ({...prev, 'bank_id': 0, 'branch_id': 0}))
    setBranchSearch('')
    setBranches([])
    backendService.getBank(bankSearch)
      .then((data) => {
        setBanks(data)
      })
  }

  const handleBranchSearch = () => {
    setState((prev) => ({
      ...prev,
      'branch_id': 0,
      'branch_name': '',
      'branch_hiragana': '',
    }))
    backendService.getBranch(state.bank_id, branchSearch)
      .then((data) => {
        setBranches(data)
      })
  }

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const {name, value} = e.target
    setState((prev) => ({...prev, [name]: value}))
  }

  return (
    <section className="mt-12 flex w-full flex-col border-gray-400 md:flex-row md:border-2">
      <div
        className="flex w-full items-center justify-center border bg-gray-200 py-2 text-lg font-bold border-secondary-brown md:w-2/6 md:border-none lg:w-1/5">
        払戻口座情報
      </div>
      <div className="w-full bg-white md:w-4/5 md:border-l-2 md:border-gray-400">
        <div>
          <h3 className="mt-5 w-full px-2 py-2 text-white bg-secondary-main sm:px-8 sm:text-lg">銀行名入力</h3>
          <div className="px-2 py-5 sm:px-8">
            <p>銀行名を検索ください。</p>
            <div className="flex flex-wrap sm:gap-5 flex-col sm:flex-row items-end">
              <InputField
                name="bank_search"
                width="max-[500px]:w-full w-90 sm:w-64 mt-3"
                label="先頭1文字から検索いただけます。"
                className="w-full sm:w-auto"
                onChange={(e) => {
                  setBankSearch(e.target.value)
                }}
                value={bankSearch}
                placeholder="例：みずほ"
              />
              <button
                type="button"
                onClick={handleBankSearch}
                className="mt-3 flex w-auto items-center justify-center rounded-full px-5 text-sm font-bold text-white py-1.5 bg-primary-dark sm:px-8 sm:text-base md:mt-auto md:text-lg">
                検索する
              </button>
            </div>
            {errors && (errors.bank_name || errors.bank_hiragana || errors.bank_id) &&
              <span className="text-red-500 text-sm error-list">銀行名を選択してください。</span>}
            <RadioButtonWithLabel
              options={banks}
              labelKey="official_name"
              valueKey="id"
              value={state.bank_id}
              label=""
              onChange={(e, index) => {
                setState((prev) => ({
                  ...prev,
                  'bank_name': banks[index].official_name,
                  'bank_hiragana': banks[index].hiragana,
                  'bank_id': parseInt(e.target.value),
                  'branch_id': 0,
                  'branch_name': '',
                  'branch_hiragana': '',
                }))
                setBankSearch(banks[index].official_name)
                setBanks([banks[index]])
                setBranches([])
                setBranchSearch('')
              }}
              name="bank_id"
              required
            />
          </div>
        </div>
        <div>
          <h3 className="w-full px-2 py-2 text-white bg-secondary-main sm:px-8 sm:text-lg">支店名入力</h3>
          <div className="px-2 py-5 sm:px-8">
            {!state.bank_id ?
              <p>まずは銀行名を検索し、選択ください</p> :
              <>
                <p>支店名を検索ください。</p>
                <div className="flex flex-wrap flex-col sm:gap-5 sm:flex-row items-end">
                  <InputField
                    name="branch_search"
                    width="max-[500px]:w-full w-90 sm:w-64 mt-3"
                    label="先頭1文字から検索いただけます 。"
                    className="w-full sm:w-auto"
                    onChange={(e) => {
                      setBranchSearch(e.target.value)
                    }}
                    value={branchSearch}
                    placeholder="例：みずほ"
                    disabled={!state.bank_id}
                  />
                  <button
                    type="button"
                    onClick={handleBranchSearch}
                    className="mt-3 flex w-auto items-center justify-center rounded-full px-5 text-sm font-bold text-white py-1.5 bg-primary-dark sm:px-8 sm:text-base md:mt-auto md:text-lg disabled:opacity-50"
                    disabled={!state.bank_id}
                  >
                    検索する
                  </button>
                </div>
                {errors && (errors.branch_id || errors.branch_name || errors.branch_hiragana) &&
                  <span className="text-red-500 text-sm error-list">支店名を選択してください。</span>}
                <RadioButtonWithLabel
                  options={branches}
                  labelKey="name"
                  valueKey="id"
                  value={state.branch_id}
                  label=""
                  onChange={(e, index) => {
                    setState((prev) => ({
                      ...prev,
                      'branch_id': parseInt(e.target.value),
                      'branch_name': branches[index].name,
                      'branch_hiragana': branches[index].name_hiragana,
                    }))
                    setBranchSearch(branches[index].name)
                    setBranches([branches[index]])
                  }}
                  name="branch_id"
                  required
                />
              </>
            }

          </div>
        </div>
        <div>
          <h3
            className="w-full px-2 py-2 text-white bg-secondary-main sm:px-8 sm:text-lg">種別・番号・名義人の入力</h3>
          <div className="px-2 py-5 sm:px-8">
            <label>口座種別 <span className="text-accent-brown">※必須</span></label>
            <div className="mb-3 flex flex-wrap gap-2 sm:gap-5">
              {accountType.map((data, index) => (
                <RadioButton
                  key={index}
                  value={data.value}
                  label={data.label}
                  name="account_type"
                  checked={state.account_type === data.value}
                  onChange={handleChange}
                />
              ))}
            </div>
            <InputField
              width="w-full md:w-1/2"
              name="account_number"
              label="口座番号"
              onChange={handleChange}
              value={state.account_number}
              required
              suggestion="※ハイフン不要、半角数字でご入力ください"
              errors={errors}
            />
            <InputField
              width="w-full md:w-1/2"
              name="account_holder"
              label="口座名義人"
              onChange={handleChange}
              value={state.account_holder || fullNameKana}
              required
              disabled={true}
              errors={errors}
            />
          </div>
        </div>
      </div>
    </section>
  )
}