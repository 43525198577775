// db.ts
import Dexie, { Table } from 'dexie';

export interface RestructuringFundData {
  id: number;
  full_name: string;
  fund: number;
  status: 'approved' | 'declined' | 'waiting';
  timestamp: string;
  unit: number;
}

interface FundType {
  fund_name: string;
  operation_start: string;
  operation_end: string;
  percent: number;
  amount_raised: number;
  subordinated_investment: number;
  duration: string;
  duration_type: string;
  kuchi: number;
  min_num_kuchi: number;
  max_num_kuchi: number;
  number_kuchi: number;
  total_dist: number;
  total_investment: number;
  slug: string;
}

interface RestructuredFund {
  origin: FundType;
  restructuring: FundType;
}

export interface RestructuringFund {
  id: number;
  data: RestructuringFundData[];
  fund: RestructuredFund;
  query_start: string;
  query_end: string;
}

export interface User {
  id?: string;
  user: {
    id: number;
    fullName: string;
    deposit: number;
    reservedPoint: number;
    aggregateAmount: number;
    point: number;
    cumulativeDividends: number;
    restructuringFund: RestructuringFund | null;
    referralCode?: string | null;
  } | null;
  mynumberStatus: 'declined' | 'pending' | 'completed' | null;
  investorRegistrationCompleted: boolean;
  isAuthenticated: boolean;
  registrationStep: number | null;
  hasPendingApplication: boolean;
  isBlocked: boolean;
}

export interface UserInterface {
  hideRestructuring: boolean;
}

export class DB extends Dexie {
  // 'user' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  user!: Table<User>;
  userInterface!: Table<UserInterface>;

  constructor() {
    super('cached_info');
    this.version(1).stores({
      user: '++id, user, isAuthenticated, investorRegistrationCompleted, registrationStep, mynumberStatus, hasPendingApplication',
      userInterface: '++id, hideRestructuring',
    });
  }
}

export const db = new DB();