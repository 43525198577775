import React, { useRef, useState } from 'react';
import { ErrorList, ErrorsType } from '../../components/ui/forms/ErrorList';
import { KeyIcon } from '@heroicons/react/24/solid';
import { Breadcrumb } from '../../components/ui/Breadcrumb';
import { Link } from 'react-router-dom';
import PasswordChangeCompleted from './PasswordChangeCompleted';
import { PasswordChangeType } from '../../store/account';
import UserService from '../../infrastructure/api/user';
import useAxios from '../../hooks/useAxios';

const breadcrumbItems = [
  { url: '/', name: 'マイページトップ' },
  { url: '/account', name: '会員情報' },
  { url: 'edit/password', name: 'パスワードの変更' },
];


const EditPassword: React.FC = () => {
  const [completed, setCompleted] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null!);
  const [errors, setErrors] = useState<ErrorsType>();
  const [state, setState] = useState<PasswordChangeType>({ password: '', 'new_password1': '', 'new_password2': '' });
  const userService = new UserService(useAxios());

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    buttonRef.current.disabled = true;
    userService.changePassword(state)
      .then(() => {
        setCompleted(true);
      })
      .catch((err) => {
        setErrors(err);
      })
      .finally(() => {
        buttonRef.current.disabled = false;
      });
  };

  return (
    <React.Fragment>
      {
        !completed ?
          <main className='sm:mt-4 md:mt-14 anchor-scroll'>
            <div className='container px-2 mx-auto sm:px-4'>
              <section className='text-center text-2xl font-bold text-secondary-main md:text-3xl'>
                <h1>パスワードの変更</h1>
              </section>
              <form onSubmit={handleSubmit} method='post' className='mt-12' autoComplete='off'>
                <div className='bg-white sm:px-20 py-10 mt-5'>
                  <div className='flex flex-col mt-8'>
                    <div className='relative sm:text-lg font-bold'>
                      <span className='absolute h-full w-3 bg-secondary-main left-0 top-0'></span>
                      <label className='pl-5 leading-relaxed tracking-wide'>現在のパスワード</label>
                    </div>
                    <div className='relative mt-4'>
                      <input
                        type='password'
                        name='password'
                        value={state.password}
                        readOnly={true}
                        onChange={handleChange}
                        autoComplete='off'
                        onFocus={(e) => {
                          e.currentTarget.removeAttribute('readonly');
                        }}
                        className='h-12 pl-12 pr-2 border-primary-grey rounded-md border w-full' />
                      <div
                        className='pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-secondary-main'>
                        <KeyIcon className='h-8 w-8' />
                      </div>
                    </div>
                    <ErrorList name='password' errors={errors} />
                    {/* <p className='text-xs sm:text-base px-1'>※半角英数字でご入力ください</p> */}
                  </div>
                  <div className='flex flex-col mt-8'>
                    <div className='relative sm:text-lg font-bold'>
                      <span className='absolute h-full w-3 bg-secondary-main left-0 top-0'></span>
                      <label className='pl-5 leading-relaxed tracking-wide'>新しいパスワード</label>
                    </div>
                    <div className='relative mt-4'>
                      <input
                        type='password'
                        name='new_password1'
                        value={state.new_password1}
                        onChange={handleChange}
                        autoComplete='off'
                        readOnly={true}
                        onFocus={(e) => {
                          e.currentTarget.removeAttribute('readonly');
                        }}
                        className='h-12 pl-12 pr-2 border-primary-grey rounded-md border w-full' />
                      <div
                        className='pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-secondary-main'>
                        <KeyIcon className='h-8 w-8' />
                      </div>
                    </div>
                    {/* <p className='text-xs sm:text-base px-1'></p> */}
                    <ErrorList name='new_password1' errors={errors} />
                  </div>
                  <div className='flex flex-col mt-8'>
                    <div className='relative sm:text-lg font-bold'>
                      <span className='absolute h-full w-3 bg-secondary-main left-0 top-0'></span>
                      <label className='pl-5 leading-relaxed tracking-wide'>新しいパスワードの確認</label>
                    </div>
                    <div className='relative mt-4'>
                      <input
                        type='password'
                        name='new_password2'
                        value={state.new_password2}
                        autoComplete='off'
                        onChange={handleChange}
                        readOnly={true}
                        onFocus={(e) => {
                          e.currentTarget.removeAttribute('readonly');
                        }}
                        className='h-12 pl-12 pr-2 border-primary-grey rounded-md border w-full' />
                      <div
                        className='pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-secondary-main'>
                        <KeyIcon className='h-8 w-8' />
                      </div>
                    </div>
                    {/* <p className='text-xs sm:text-base px-1'>※半角小文字英数字でご入力ください</p> */}
                    <ErrorList name='new_password2' errors={errors} />
                  </div>
                </div>
                <div className='mt-12 flex flex-col items-center justify-center gap-3 pb-10 sm:gap-5 lg:gap-8'>
                  <button
                    ref={buttonRef}
                    type='submit'
                    className='flex w-4/5 items-center justify-center rounded-full border-2 px-2 text-sm font-bold text-white border-secondary-main py-2.5 bg-secondary-main sm:w-80 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
                    パスワードを認証する
                  </button>
                  <Link to='/account'
                        className='flex w-4/5 items-center justify-center rounded-full border-2 px-2 text-sm font-bold text-secondary-main border-secondary-main py-2.5 bg-white sm:w-80 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
                    会員情報ページへ戻る
                  </Link>
                </div>
              </form>
              <Breadcrumb items={breadcrumbItems} />
            </div>
          </main> : <PasswordChangeCompleted />
      }
    </React.Fragment>
  );
};

export default EditPassword;