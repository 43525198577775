import React from 'react';
import {ErrorList, ErrorListProps} from './ErrorList';


interface InputFieldProps extends ErrorListProps {
	width?: string;
	label?: string;
	type?: string;
	placeholder?: string;
	required?: boolean;
	className?: string;
	suggestion?: string;
	value: string | number;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
	button?: boolean;
	buttonText?: React.ReactNode;
	onClick?: () => void;
  onWheel?: (e: React.WheelEvent<HTMLInputElement>) => void;
  labelClassName?: string;
}

export const InputField: React.FC<InputFieldProps> = ({
	                                                      onChange,
	                                                      label,
	                                                      name,
	                                                      value,
	                                                      className,
	                                                      required,
	                                                      placeholder,
	                                                      suggestion,
	                                                      type = 'text',
	                                                      width = 'w-full',
	                                                      disabled = false,
	                                                      errors,
	                                                      buttonText = '',
	                                                      button = false,
	                                                      onClick,
                                                        onWheel,
                                                        labelClassName = ''
                                                      }) => {

	return (
		<div className={`${className} flex flex-wrap flex-col mt-3`}>
			<label className={`text-base ${labelClassName}`}>
				{label}
				{required && <span className='text-accent-brown'> ※必須</span>}
			</label>
			<ErrorList name={name} errors={errors}/>
			<div className={`relative border border-gray-400 rounded disabled:opacity-50 ${width} flex items-center`}>
				<input
					type={type}
					value={value}
					name={name}
					onChange={onChange}
					required={required}
					placeholder={placeholder}
					className='w-full py-1.5 px-2 rounded'
					disabled={disabled}
          onWheel={onWheel}
				/>
				{
					button && <button type='button' onClick={onClick} className='absolute right-2 text-secondary-main text-lg'>
						{buttonText}
            </button>
				}
			</div>
			<span className='text-sm opacity-50'>{suggestion}</span>
		</div>
	);
};