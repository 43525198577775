import React, { useEffect, useState } from 'react';
import { NavLinks } from './Links';
import {Link, NavLink, useLocation} from 'react-router-dom';
import { HamburgerButton, NavMenu } from './Menu';
import Logo from './Logo';
import { BuildingOffice2Icon, CalendarDaysIcon, ChartPieIcon, HomeIcon } from '@heroicons/react/24/solid';
import {useLiveQuery} from 'dexie-react-hooks';
import {db} from '../../../db';

const { user } = db;

const Nav: React.FC = () => {
  const userData = useLiveQuery(() => user.get(1), []);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const location = useLocation();
  
  useEffect(() => {
    const body = document.getElementById('main-body');
    if (isOpen && body) {
      body.style.overflow = 'hidden';
    } else if (!isOpen && body) {
      body.style.overflow = 'auto';
    }
  }, [isOpen]);
  return (
    <div className='sticky top-0 left-0 z-10'>
    <div className='w-full bg-[#F9F9F3] md:bg-white md:drop-shadow'>
      <div className='mx-auto flex h-20 items-center justify-between px-1 lg:container sm:px-4'>
        <Logo isOpen={isOpen} setIsOpen={setIsOpen} />
        <div className='items-center gap-3 lg:gap-5 flex'>
          {!isOpen && <NavLinks />}
          <NavMenu isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
      </div>
      <div
        className={`flex md:hidden w-full px-3 py-2 z-50 fixed bottom-0 left-0 border-gray-300 ${isOpen ? 'bg-transparent border-t-0' : 'bg-white border-t'}`}>
        <ul className={`container mx-auto flex items-center ${isOpen ? 'justify-end' : 'justify-between'}`}>
          <li className={`${isOpen ? 'hidden' : 'block'}`}>
            <NavLink to='/'
                     className={({ isActive }) => `flex flex-col items-center ${isActive ? 'text-primary-dark' : 'text-primary-text text-opacity-50'}`}>
              <HomeIcon className='h-8 w-8 sm:h-10 sm:w-10' />
              <span className='text-xs font-bold sm:text-base'>TOP</span>
            </NavLink>
          </li>
          <li className={`${isOpen ? 'hidden' : 'block'}`}>
            <NavLink to='/investment-status'
                     className={({ isActive }) => `flex flex-col items-center ${isActive ? 'text-primary-dark' : 'text-primary-text text-opacity-50'}`}>
              <ChartPieIcon className='h-8 w-8 sm:h-10 sm:w-10' />
              <span className='text-xs font-bold sm:text-base'>出資状況</span>
            </NavLink>
          </li>
          <li className={`${isOpen ? 'hidden' : 'block'}`}>
            <NavLink to='/funds'
                     className={({ isActive }) => `flex flex-col items-center ${isActive ? 'text-primary-dark' : 'text-primary-text text-opacity-50'}`}>
              <BuildingOffice2Icon className='h-8 w-8 sm:h-10 sm:w-10' />
              <span className='text-xs font-bold sm:text-base'>ファンド</span>
            </NavLink>
          </li>
          <li className={`${isOpen ? 'hidden' : 'block'}`}>
            <NavLink to='/dividend-schedule'
                     className={({ isActive }) => `flex flex-col items-center ${isActive ? 'text-primary-dark' : 'text-primary-text text-opacity-50'}`}>
              <CalendarDaysIcon className='h-8 w-8 sm:h-10 sm:w-10' />
              <span className='text-xs font-bold sm:text-base'>配当予定</span>
            </NavLink>
          </li>
          <li>
            <HamburgerButton isOpen={isOpen} setIsOpen={setIsOpen} />
          </li>
        </ul>
      </div>
    </div>
      { userData?.hasPendingApplication && !['/investment-status/', '/investment-status'].includes(location.pathname)
        && !location.pathname.includes('/investment-status/pending-list')
        && (
        <div className="w-full flex justify-center">
          <div className='flex p-2 items-center justify-center rounded-md w-full lg:w-4/5 bg-secondary-light'>
            <p className='text-center text-secondary-main font-bold'>お申込み中で未入金のファンドがあります。<Link to='/investment-status' className='text-primary-dark'>こちら</Link>からご確認の上、入金手続きをお願いいたします。</p>
          </div>
        </div>
      )}
    </div>
  );
};
export default Nav;