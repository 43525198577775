import React from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { BaseLayout } from '../components/layouts/BaseLayout';

const Root: React.FC = () => {
  return (
    <BaseLayout>
      <Outlet />
      <ScrollRestoration />
    </BaseLayout>
  );
};

export default Root;