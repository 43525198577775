import Calender from '../../../assets/images/calender.jpg';
import Charts from '../../../assets/images/chart.jpg';
import Building from '../../../assets/images/building.jpg';
import BankCard from '../../../assets/images/bankcard.jpg';
import HistoryIcon from '../../../assets/images/history.jpg';
import FaqIcon from '../../../assets/images/faq.jpg';
import WriteIcon from '../../../assets/images/document.jpg';
import ManIcon from '../../../assets/images/user.jpg';
import Contact from '../../../assets/images/contact.jpg';
import Company from '../../../assets/images/company.jpg';
import TomotaquService from '../../../assets/images/tomotaquservice.jpg';
import Topics from '../../../assets/images/hum2.png'
import Logout from '../../../assets/images/hum1.png'


import { MenuListType } from '../MenuComponent';
import { BACKEND_URL } from '../../../store';

export const hamburgerMenuLinks: MenuListType[] = [
  {
    title: '配当スケジュール',
    subTitle: '出資済ファンドの配当予定確認',
    link: '/dividend-schedule',
    image: Calender,
  },
  {
    title: '出資状況',
    subTitle: '出資中/出資終了ファンドを見る',
    link: '/investment-status',
    image: Charts,
  },
  {
    title: 'ファンド一覧',
    subTitle: 'トモタクが組成するファンド一覧',
    link: '/funds',
    image: Building,
  },
  {
    title: '出金',
    subTitle: 'ファンド専用口座、払戻申請はこちらから',
    link: '/deposit-withdraw',
    image: BankCard,
  },
  {
    title: '取引履歴',
    subTitle: '入金/出資/配当/出金等の履歴を見る',
    link: '/trading-history',
    image: HistoryIcon,
  },
  {
    title: '電子交付書面',
    subTitle: '契約書面等、電子書面の確認',
    link: '/electronic-document',
    image: WriteIcon,
  },
  {
    title: '会員情報',
    subTitle: '登録済情報を確認/変更',
    link: '/account',
    image: ManIcon,
  },
  {
    title: 'ログアウト',
    subTitle: 'ログアウトする場合はこちら',
    link: `${BACKEND_URL}/mypage/logout`,
    skip: true,
    external: true,
    image: Logout,
  },
];

export const hamburgerMenuServiceLinks: MenuListType[] = [
  {
    title: '運営会社情報',
    subTitle: 'トモタクの運営会社情報',
    link: `${BACKEND_URL}/company/`,
    image: Company,
    external: true,
  },
  {
    title: 'TOMOTAQUのWebページ',
    subTitle: 'トモタクのトップページへ移動',
    link: `${BACKEND_URL}`,
    image: TomotaquService,
    external: true,
  },
  {
    title: 'トモタクトピックス',
    subTitle: 'あなたの読みたい投資の話はここにある',
    link: `${BACKEND_URL}/topics/`,
    image: Topics,
    external: true,
  },
];


export const MyPageMenuLinks: MenuListType[] = [
  {
    title: '配当スケジュール',
    subTitle: '出資済ファンドの配当予定はこちらから',
    link: '/dividend-schedule',
    image: Calender,
  },
  {
    title: '出資状況',
    subTitle: '出資中/出資終了ファンドの詳細や出資状況の確認',
    link: '/investment-status',
    image: Charts,
  },
  {
    title: 'ファンド一覧',
    subTitle: 'TOMOTAQUの組成するファンド一覧',
    link: '/funds',
    image: Building,
  },
  {
    title: '出金',
    subTitle: 'ファンド専用口座、払戻申請はこちらから',
    link: '/deposit-withdraw',
    image: BankCard,
  },
  {
    title: '取引履歴',
    subTitle: '入金/出資/配当/出金などの履歴はこちらから',
    link: '/trading-history',
    image: HistoryIcon,
  },
  {
    title: 'FAQ',
    subTitle: 'ご利用についての疑問・ご不明点はこちらから',
    link: `${BACKEND_URL}/faq/`,
    image: FaqIcon,
    external: true,
  },
  {
    title: '電子交付書面',
    subTitle: '契約書面等、発行された電子書面はこちらから',
    link: '/electronic-document',
    image: WriteIcon,
  },
  {
    title: '会員情報',
    subTitle: '登録済情報の確認/修正はこちらから',
    link: '/account',
    image: ManIcon,
  },
  {
    title: 'お問い合わせ',
    subTitle: 'メールやお電話でのお問い合わせはこちらから',
    link: `${BACKEND_URL}/contact/`,
    image: Contact,
    external: true,
  },
];

export const MyPageMenuServiceLinks: MenuListType[] = [
  {
    title: '運営会社情報',
    subTitle: 'トモタクの運営会社情報',
    link: `${BACKEND_URL}/company/`,
    image: Company,
    external: true,
  },
  {
    title: 'TOMOTAQUのWebページ',
    subTitle: 'トモタクのトップページへ移動',
    link: `${BACKEND_URL}`,
    image: TomotaquService,
    external: true,
  },
];