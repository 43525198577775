import React from 'react';
import { InputField } from './Input';
import { ErrorList, ErrorListProps } from './ErrorList';

interface MultipleSelectCheckboxProps<T, D extends object> extends ErrorListProps {
  label: string;
  options: T[];
  values: D[];
  labelKey: keyof T;
  valueKey: keyof T;
  className?: string;
  inputName: keyof D;
  checkboxName: keyof D;
  onChange: (values: D[]) => void;
  required?: boolean;
}

const CheckBoxWithInput = <T, D extends object>({
                                                  label,
                                                  options,
                                                  labelKey,
                                                  valueKey,
                                                  name,
                                                  className,
                                                  onChange,
                                                  values,
                                                  checkboxName,
                                                  inputName,
                                                  errors,
                                                  required=true
                                                }: MultipleSelectCheckboxProps<T, D>): React.ReactElement => {
  const handleOptionChange = (optionId: string) => {
    if (values.findIndex((val) => val[checkboxName] === optionId) !== -1) {
      onChange(values.filter((val) => val[checkboxName] !== optionId));
    } else {
      onChange([...values, { [checkboxName]: optionId, [inputName]: '' } as D]);
    }
  };

  const handleInputChange = (value: string, index: number) => {
    const cpData = [...values];
    const i = values.findIndex((val) => val[checkboxName] === options[index][valueKey] as string);
    if (cpData[i]) {
      cpData[i][inputName] = value as D[keyof D];
      onChange(cpData);
    }
  };

  return (
    <div className='w-full mt-3 lg:w-3/4'>
      <label>{label} { required && <span className='text-accent-brown'> ※必須</span> }</label>
      <ErrorList name={name} errors={errors}/>
      <div className={className}>
        {options.map((option, index) => (
          <div key={index} className='flex items-end'>
            <input
              type='checkbox'
              name={name}
              value={option[valueKey] as string}
              checked={values.findIndex((val) => val[checkboxName] === option[valueKey] as string) !== -1}
              onChange={() => handleOptionChange(option[valueKey] as string)}
              className='mr-2 w-6 h-6 mb-2 peer'
            />
            <InputField
              label={option[labelKey] as string}
              name={`${inputName as string}_${index}`}
              onChange={(e) => handleInputChange(e.target.value, index)}
              value={(() => {
                const i = values.findIndex((val) => val[checkboxName] === option[valueKey] as string);
                return values[i] ? values[i][inputName] as string : '';
              })()}
              className='w-full md:w-[65%] pointer-events-none opacity-75 peer-checked:opacity-100 peer-checked:pointer-events-auto'
              width='max-[400px]:w-full  w-full sm:mr-10'
              required={false}
            />
            <span className='ml-2 w-10 whitespace-nowrap'> 万円</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CheckBoxWithInput;