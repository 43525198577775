import React from 'react';
import { ErrorList, ErrorListProps } from './ErrorList';

interface RadioButtonProps extends ErrorListProps {
  value: string | number;
  label: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
}

export const RadioButton: React.FC<RadioButtonProps> = ({ value, label, name, checked, onChange }) => {

  return (
    <label className='flex items-center'>
      <input
        type='radio'
        value={value}
        name={name}
        checked={checked}
        onChange={onChange}
        className='mr-2 h-5 w-5 accent-secondary-main'
      />
      {label}
    </label>
  );
};


interface RadioButtonWithLabelProps<T extends object> extends Omit<Omit<RadioButtonProps, 'checked'>, 'onChange'> {
  options: T[];
  labelKey: keyof T;
  valueKey: keyof T;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
}

export const RadioButtonWithLabel = <T extends object>({
                                                         value,
                                                         label,
                                                         name,
                                                         onChange,
                                                         options,
                                                         labelKey,
                                                         valueKey,
                                                         errors,
                                                         required = true,
                                                       }: RadioButtonWithLabelProps<T>): React.ReactElement => {

  return (
    <div className='mt-3'>
      <label>{label} {required && label && <span className='text-accent-brown'>※必須</span>}</label>
      <ErrorList name={name} errors={errors} />
      <div className='mb-3 flex flex-wrap gap-2 sm:gap-5'>
        {options.map((data, index) => (
          <label className='flex items-center' key={index}>
            <input
              type='radio'
              value={data[valueKey] as string}
              name={name}
              checked={value === data[valueKey]}
              onChange={(e) => {
                onChange(e, index);
              }}
              className='mr-2 accent-secondary-main h-5 w-5'
              required={required}
            />
            {data[labelKey] as string}
          </label>
        ))}
      </div>
    </div>
  );
};