import React from 'react';
import { Link } from 'react-router-dom';

export const ApplicationCompleted: React.FC<{ fundName?: string }> = ({
                                                                       fundName = '',
                                                                     }) => {
  return (
    <main className='sm:mt-4 md:mt-14'>
      <div className='container mx-auto sm:px-4'>
        <section className='text-center text-2xl font-bold text-secondary-main md:text-3xl'>
          <h1>キャンセル待ち申込完了</h1>
        </section>
        <section className='my-20 text-lg leading-7 px-[8%] text-center'>
          <p className='pb-2'>この度は{fundName}へのキャンセル待ち申込いただきありがとうございます。</p>
          <p className='pb-2'>キャンセル待ち申込完了のご案内を登録されたメールアドレスにお送りしました。<br/>
          </p>
        </section>
        <section
          className='mb-10 flex flex-wrap items-center justify-center gap-3 px-2 py-10 sm:gap-5 sm:px-0 lg:gap-10'>
          <Link to='/'
                className='flex w-4/5 items-center justify-center rounded-full border-2 bg-white px-2 font-bold border-primary-brown py-2.5 text-primary-brown sm:w-96 sm:border-2 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
            マイページトップへ戻る
          </Link>
        </section>
      </div>
    </main>
  );
};









