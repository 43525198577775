import React, {useEffect, useState} from 'react';
import {Breadcrumb} from '../../components/ui/Breadcrumb';
import {Link, useParams} from 'react-router-dom';
import UserService from '../../infrastructure/api/user';
import useAxios from '../../hooks/useAxios';
import {InvestmentDetailFundType} from '../../store/account';
import {numberWithCommas} from '../../utlis/numbers';
import {convertNumberWeekday} from '../../utlis/date';


const breadcrumbItems = [
	{url: '/', name: 'マイページトップ'},
	{url: '/investment-status', name: '出資状況'}
];


interface FundDataTableProp {
	name?: string;
	children?: React.ReactNode;
}

export const FundDataHeader: React.FC<FundDataTableProp> = ({name, children}) => {
	return (
		<tr className='flex mb-[1px]'>
			<th
				className='w-24 px-2 text-left text-white py-1.5 bg-primary-brown border-secondary-brown sm:px-0.5 sm:w-44 sm:border sm:py-2 sm:text-center sm:text-lg'>{name}
			</th>
			<td className='flex-1 border bg-white px-2 py-1.5 border-secondary-brown sm:py-2 sm:text-lg'>
				{children}
			</td>
		</tr>
	);
};

const InvestmentCompletedDetails: React.FC = () => {
	const {pk} = useParams();
	const userService = new UserService(useAxios());
	const [state, setState] = useState<InvestmentDetailFundType>();
	useEffect(() => {
		if (pk) {
			userService.getInvestmentDetail(pk)
				.then((data) => {
					if (data) {
						setState(data);
					}
				});
		}
	}, [pk]);
	if (!state) return <></>;
	return (
		<main className='sm:mt-4 md:mt-10'>
			<div className='container mx-auto sm:px-4'>
				<section className='mt-10 sm:mt-14'>
					<h2 className='mb-7 text-xl font-bold text-primary-brown sm:text-3xl'>{state.fund_name}</h2>
					<div className='grid grid-cols-1 justify-center gap-5 md:grid-cols-2 lg:gap-8'>
						<figure
							className='relative h-64 overflow-hidden sm:h-80 sm:rounded-2xl lg:h-[300px] xl:h-[350px] max-[500px]:h-full'>
							<img className='h-full w-full object-cover' src={state.thumbnail} alt=''/>
						</figure>
						<div>
							<div className='px-1 sm:px-0'>
								{
									state.next_dividend &&
                    <div className='font-bold opacity-50 text-primary-text sm:text-lg'>
                        <p>次回分配予定日：{convertNumberWeekday(state.next_dividend.receive_date)}({state.next_dividend.order}/{state.dividends.length}回目)</p>
                        <p>予定分配金：{numberWithCommas(state.next_dividend.receive_money)}円(税引前)</p>
                    </div>
								}
								<table className='mt-1 w-full border-separate border-spacing-1'>
									<tbody>
									<FundDataHeader name='出資日'>{state.def_time}</FundDataHeader>
									<FundDataHeader name='出資口数'>{state.kuchi}口</FundDataHeader>
									<FundDataHeader name='出資総額'>{numberWithCommas(state.amount_of_investment)}円</FundDataHeader>
									<FundDataHeader name='運用期間'><p>{state.duration}{state.duration_type}</p>
										<small
											className='text-primary-grey'>{state.operation_start}〜{state.operation_end}</small></FundDataHeader>
									<FundDataHeader name='予定分配率'>{state.percent}%(年利)※税引前</FundDataHeader>

									</tbody>
								</table>
								<p className='text-sm'>このファンドは運用を{state.operation_end}づけで終了いたしました。</p>
								<p className='text-sm'>配当履歴にかんしては<Link to="/trading-history" className="text-primary-dark font-semibold">取引履歴</Link>にてご確認ください。</p>
							</div>
						</div>
					</div>
				</section>
				<section className='mt-10'>
					<ul>
						<li className='border-2 bg-white px-3 py-4 border-primary-brown sm:px-5'>
							<p className='text-xl font-bold text-primary-brown sm:text-2xl'>交付書面</p>
						</li>
						{
							state.fund_type === 'R' ?
								<>
									<li
										className='flex items-center justify-between border-b-2 bg-white px-2 py-3 text-base font-bold text-primary-brown border-primary-brown sm:text-primary-text sm:px-5 sm:text-xl'>
										<p>重要事項説明</p>
										<div className='flex flex-col items-end justify-end'>
											<a href={state.paper_file || ''}
											   className='rounded-md border-2 bg-white px-8 py-2 border-primary-brown text-primary-brown'>閲覧</a>
										</div>
									</li>
									{
										state.users_property_management_report?.map((val, i) => (
											<li
												key={i}
												className={`flex items-center justify-between border-b-2 px-2 py-3 border-primary-brown text-base text-primary-brown font-bold sm:text-primary-text sm:px-5 sm:text-xl ${val.last_seen ? 'bg-white' : 'bg-secondary-brown'}`}>
												<p>{val.name}</p>
												<div className='flex flex-col items-end justify-end'>
													{val.last_seen && <time
                            className='text-xs font-normal opacity-50 text-primary-text'>{val.last_seen} 既読</time>}
													<a href={val.url}
													   className={`rounded-md border-2 px-8 py-2 border-primary-brown ${val.last_seen ? 'bg-white text-primary-brown' : 'bg-primary-brown text-white'}`}>閲覧</a>
												</div>
											</li>
										))
									}
								</> :
								<>
									{
										state.for_user &&
                      <li
                          className={`flex items-center justify-between border-b-2 px-2 py-3 border-primary-brown text-base text-primary-brown font-bold sm:text-primary-text sm:px-5 sm:text-xl ${state.for_user.last_seen ? 'bg-white' : 'bg-secondary-brown'}`}>
                          <p>{state.for_user.name}</p>
                          <div className='flex flex-col items-end justify-end'>
		                          {state.for_user.last_seen && <time
                            className='text-xs font-normal opacity-50 text-primary-text'>{state.for_user.last_seen} 既読</time>}
                              <a href={state.for_user.url}
                                 className={`rounded-md border-2 px-8 py-2 border-primary-brown ${state.for_user.last_seen ? 'bg-white text-primary-brown' : 'bg-primary-brown text-white'}`}>閲覧</a>
                          </div>
                      </li>
									}
									{
										state.users_contract_documents &&
                      <li
                          className={`flex items-center justify-between border-b-2 px-2 py-3 border-primary-brown text-base text-primary-brown font-bold sm:text-primary-text sm:px-5 sm:text-xl ${state.users_contract_documents.last_seen ? 'bg-white' : 'bg-secondary-brown'}`}>
                          <p>{state.users_contract_documents.name}</p>
                          <div className='flex flex-col items-end justify-end'>
		                          {state.users_contract_documents.last_seen && <time
                            className='text-xs font-normal opacity-50 text-primary-text'>{state.users_contract_documents.last_seen} 既読</time>}
                              <a href={state.users_contract_documents.url}
                                 className={`rounded-md border-2 px-8 py-2 border-primary-brown ${state.users_contract_documents.last_seen ? 'bg-white text-primary-brown' : 'bg-primary-brown text-white'}`}>閲覧</a>
                          </div>
                      </li>
									}
									{
										state.anonymous_partnership_contracts &&
                      <li
                          className={`flex items-center justify-between border-b-2 px-2 py-3 border-primary-brown text-base text-primary-brown font-bold sm:text-primary-text sm:px-5 sm:text-xl ${state.anonymous_partnership_contracts.last_seen ? 'bg-white' : 'bg-secondary-brown'}`}>
                          <p>{state.anonymous_partnership_contracts.name}</p>
                          <div className='flex flex-col items-end justify-end'>
		                          {state.anonymous_partnership_contracts.last_seen && <time
                            className='text-xs font-normal opacity-50 text-primary-text'>{state.anonymous_partnership_contracts.last_seen} 既読</time>}
                              <a href={state.anonymous_partnership_contracts.url}
                                 className={`rounded-md border-2 px-8 py-2 border-primary-brown ${state.anonymous_partnership_contracts.last_seen ? 'bg-white text-primary-brown' : 'bg-primary-brown text-white'}`}>閲覧</a>
                          </div>
                      </li>
									}
									{
										state.electronic_transaction &&
                      <li
                          className={`flex items-center justify-between border-b-2 px-2 py-3 border-primary-brown text-base text-primary-brown font-bold sm:text-primary-text sm:px-5 sm:text-xl ${state.electronic_transaction.last_seen ? 'bg-white' : 'bg-secondary-brown'}`}>
                          <p>{state.electronic_transaction.name}</p>
                          <div className='flex flex-col items-end justify-end'>
		                          {state.electronic_transaction.last_seen && <time
                            className='text-xs font-normal opacity-50 text-primary-text'>{state.electronic_transaction.last_seen} 既読</time>}
                              <a href={state.electronic_transaction.url}
                                 className={`rounded-md border-2 px-8 py-2 border-primary-brown ${state.electronic_transaction.last_seen ? 'bg-white text-primary-brown' : 'bg-primary-brown text-white'}`}>閲覧</a>
                          </div>
                      </li>
									}
									{
										state.crowd_property_management_report?.map((val, i) => (
											<li
												key={i}
												className={`flex items-center justify-between border-b-2 px-2 py-3 border-primary-brown text-base text-primary-brown font-bold sm:text-primary-text sm:px-5 sm:text-xl ${val.last_seen ? 'bg-white' : 'bg-secondary-brown'}`}>
												<p>{val.name}</p>
												<div className='flex flex-col items-end justify-end'>
													{val.last_seen && <time
                            className='text-xs font-normal opacity-50 text-primary-text'>{val.last_seen} 既読</time>}
													<a href={val.url}
													   className={`rounded-md border-2 px-8 py-2 border-primary-brown ${val.last_seen ? 'bg-white text-primary-brown' : 'bg-primary-brown text-white'}`}>閲覧</a>
												</div>
											</li>
										))
									}
								</>
						}
					</ul>
				</section>
				<Breadcrumb
					items={[...breadcrumbItems, {url: '', name: state.fund_name}]}
					className='mt-10'
				/>
			</div>
		</main>
	);
};
export default InvestmentCompletedDetails;

