import React, {useEffect, useRef, useState} from 'react';
import {Breadcrumb} from '../../components/ui/Breadcrumb';
import {InvestmentPaginationType, InvestmentStatusType} from '../../store/account';
import UserService from '../../infrastructure/api/user';
import useAxios from '../../hooks/useAxios';
import {FundListing} from './FundListing';
import Pagination from '../../components/ui/Pagination';
import {PaginationState, usePagination} from '../../hooks/usePagination';


const breadcrumbItems = [
  {url: '/', name: 'マイページトップ'},
  {url: '/investment-status', name: '出資状況'},
  {url: '/completed-list', name: '運用完了'},
];

export const InvestmentCompletedList: React.FC = () => {
  const [state, setState] = useState<InvestmentPaginationType>({data: [], lastPage: 0});
  const [search, setSearch] = useState<PaginationState>({page: 1});
  const pagination = usePagination<PaginationState>(search, setSearch, state.lastPage);
  const isFirstRun = useRef(false);
  const userService = new UserService(useAxios());

  useEffect(() => {
    if (!isFirstRun.current) {
      fetchFunds();
    }
  }, [search.page]);

  useEffect(() => {
    const {searchParams} = pagination;
    const page = searchParams.get('page');
    isFirstRun.current = false;
    setSearch({page: page ? parseInt(page) : 1});
    fetchFunds();
    return () => {
      isFirstRun.current = true;
    };
  }, []);

  const fetchFunds = () => {
    userService.getCompletedInvestment(search.page)
      .then((data) => {
        setState(data);
      });
  };
  return (
    <main className='mt-4 md:mt-10'>
      <div className='container mx-auto sm:px-4'>
        <section className='text-center text-2xl font-bold text-primary-brown md:text-3xl'>
          <h1>出資状況</h1>
        </section>

        <section className="mt-4 md:mt-10">
          <div
            className="bg-secondary-brown text-primary-brown flex w-full items-center justify-between p-1 max-[450px]:px-3 cursor-pointer transition duration-1000 ease-in-out">
            <p className="font-bold py-2 px-2 sm:px-5 text-lg sm:text-2xl">運用完了ファンド</p>
          </div>
          <div className='mt-4 mb-10 grid gap-5 transition duration-1000 ease-in-out sm:gap-10 lg:grid-cols-2'>
            {
              state.data?.map((fund, index) => (
                <FundListing
                  key={index}
                  fundName={fund.fund_name}
                  slug={fund.id.toString()}
                  image={fund.thumbnail}
                  investmentAmount={fund.amount_of_investment}
                  investmentUnit={fund.kuchi}
                  className='text-secondary-main'
                />
              ))
            }
          </div>
          <div className="my-10">
            <Pagination {...pagination}/>
          </div>

        </section>

        <Breadcrumb
          items={breadcrumbItems}
          className='mt-10'
        />
      </div>
    </main>
  )
}