import React, {useEffect, useState} from 'react';
import {Breadcrumb} from '../../components/ui/Breadcrumb';
import {useParams} from 'react-router-dom';
import UserService from '../../infrastructure/api/user';
import useAxios from '../../hooks/useAxios';
import {PendingApplicationDetailType} from '../../store/account';
import {numberWithCommas} from '../../utlis/numbers';
import {useLiveQuery} from 'dexie-react-hooks';
import {db} from '../../db';
import {InputField} from '../../components/ui/forms/Input';
import {ErrorsType} from '../../components/ui/forms/ErrorList';
import {InvestmentCompleted} from '../fund/fundApplication/InvestmentCompleted';


const breadcrumbItems = [
	{url: '/', name: 'マイページトップ'},
	{url: '/investment-status', name: '出資状況'},
	{url: '/pending-list', name: '申込み未完了ファンド'},
];


interface FundDataTableProp {
	name?: string;
	children?: React.ReactNode;
}

export const FundDataHeader: React.FC<FundDataTableProp> = ({name, children}) => {
	return (
		<tr className='flex mb-[1px]'>
			<th
				className='w-24 px-2 text-left text-white py-1.5 bg-primary-brown border-secondary-brown sm:px-0.5 sm:w-44 sm:border sm:py-2 sm:text-center sm:text-lg'>{name}
			</th>
			<td className='flex-1 border bg-white px-2 py-1.5 border-secondary-brown sm:py-2 sm:text-lg'>
				{children}
			</td>
		</tr>
	);
};

const PendingApplicationDetail: React.FC = () => {
	const {pk} = useParams();
	const userService = new UserService(useAxios());
	const [state, setState] = useState<PendingApplicationDetailType>(null!);
  const userData = useLiveQuery(() => db.user.get(1));
  const [error, setError] = useState<ErrorsType>();
  const [completed, setCompleted] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  
	useEffect(() => {
		if (pk) {
			userService.getPendingApplicationDetail(pk)
				.then((data: PendingApplicationDetailType) => {
					if (data) {
						setState(data);
					}
				});
		}
	}, [pk]);

  useEffect(() => {
    if (state && userData?.user?.deposit && !state.reserved_deposit && userData.user.deposit > 0 && state.status === 'pending' && !state.va_data) {
      const availableDeposit = userData.user.deposit > state.investment_amount - state.reserved_point ? state.investment_amount - state.reserved_point : userData.user.deposit;
      setState((prev) => ({
        ...prev,
        'reserved_deposit': availableDeposit
      }));
    }
  }, [userData?.user?.deposit, state?.status, state?.va_data]);
  
  const handleSubmit = () => {
    setShowModal(false);
    if (pk){
      userService.updatePendingApplication(pk, state?.reserved_deposit || 0)
        .then((data) => {
          setState(data);
          if(data.status === 'ready' && !data.va_data) {
            setCompleted(true);
          }
        })
        .catch((err) => {setError(err);});
    }
  };
  
	const handleDocumentClick = (
    e: React.MouseEvent<HTMLAnchorElement>, 
    name: 'for_user' | 'users_contract_documents' | 'electronic_transaction' | 'anonymous_partnership_contracts'
  ) => {
    setState((prev) => ({
      ...prev,
      'user_finance': {
        ...prev['user_finance'],
        [name]: {
          ...prev['user_finance'][name],
          'last_seen': new Date().toLocaleDateString()
        }
      }
    }))
		const url = e.currentTarget.href;
		const Y = window.outerHeight / 2 - 300;
    const X = window.outerWidth / 2 - 300;
		window.open(url, 'popup', `width=600,height=600,top=${Y},left=${X}`);
		return false;
	};
	
	if (!state) return <></>;
  if (completed) return <InvestmentCompleted fundName={state.user_finance.fund_name} invested={true}/>;
	return (
		<main className='sm:mt-4 md:mt-10'>
			<div className='container mx-auto sm:px-4'>
				<section className='mt-10 sm:mt-14'>
					<h2 className='mb-7 text-xl font-bold text-primary-brown sm:text-3xl'>{state.user_finance.fund_name}</h2>
					<div className='grid grid-cols-1 justify-center gap-5 md:grid-cols-2 lg:gap-8'>
						<figure
							className='relative h-64 overflow-hidden sm:h-80 sm:rounded-2xl lg:h-[300px] xl:h-[350px] max-[500px]:h-full'>
							<img className='h-full w-full object-cover' src={state.user_finance.thumbnail} alt=''/>
						</figure>
						<div>
							<div className='px-1 sm:px-0'>
								<table className='mt-1 w-full border-separate border-spacing-1'>
									<tbody>
									<FundDataHeader name='申込日'>{state.created_time}</FundDataHeader>
									<FundDataHeader name='申込口数'>{state.units}口</FundDataHeader>
									<FundDataHeader name='申込総額'>{numberWithCommas(state.user_finance.amount_of_investment)}円</FundDataHeader>
									<FundDataHeader name='運用期間'><p>{state.user_finance.duration}{state.user_finance.duration_type}</p>
										<small
											className='text-primary-grey'>{state.user_finance.operation_start}〜{state.user_finance.operation_end}</small></FundDataHeader>
									<FundDataHeader name='予定分配率'>{state.user_finance.percent}%(年利)※税引前</FundDataHeader>

									</tbody>
								</table>
							</div>
						</div>
					</div>
				</section>
				<section className='mt-10'>
					<h3 className='rounded-full border-2 bg-primary-dark text-white w-fit p-2 mb-2 border-primary-dark text-lg font-bold italic'>STEP 1</h3>
					<p className='font-bold'>電子文書をすべてご確認ください。</p>
					<ul>
						<li className='border-2 bg-white px-3 py-4 border-primary-brown sm:px-5'>
							<p className='text-xl font-bold text-primary-brown sm:text-2xl'>交付書面</p>
						</li>
							{
								state.user_finance.for_user &&
                  <li
                      className={`flex items-center justify-between border-b-2 px-2 py-3 border-primary-brown text-base text-primary-brown font-bold sm:text-primary-text sm:px-5 sm:text-xl ${state.user_finance.for_user.last_seen ? 'bg-white' : 'bg-secondary-brown'}`}>
                      <p>{state.user_finance.for_user.name}</p>
                      <div className='flex flex-col items-end justify-end'>
                          {state.user_finance.for_user.last_seen && <time
                        className='text-xs font-normal opacity-50 text-primary-text'>{state.user_finance.for_user.last_seen} 既読</time>}
                          <a href={state.user_finance.for_user.url}
                             target='popup'
                             onClick={(e) => {
                               handleDocumentClick(e, 'for_user')
                             }}
                             className={`rounded-md border-2 px-8 py-2 border-primary-brown ${state.user_finance.for_user.last_seen ? 'bg-white text-primary-brown' : 'bg-primary-brown text-white'}`}>閲覧</a>
                      </div>
                  </li>
							}
							{
								state.user_finance.users_contract_documents &&
                  <li
                      className={`flex items-center justify-between border-b-2 px-2 py-3 border-primary-brown text-base text-primary-brown font-bold sm:text-primary-text sm:px-5 sm:text-xl ${state.user_finance.users_contract_documents.last_seen ? 'bg-white' : 'bg-secondary-brown'}`}>
                      <p>{state.user_finance.users_contract_documents.name}</p>
                      <div className='flex flex-col items-end justify-end'>
                          {state.user_finance.users_contract_documents.last_seen && <time
                        className='text-xs font-normal opacity-50 text-primary-text'>{state.user_finance.users_contract_documents.last_seen} 既読</time>}
                          <a href={state.user_finance.users_contract_documents.url}
                             target='popup'
                             onClick={(e) => {
                               handleDocumentClick(e, 'users_contract_documents')
                             }}
                             className={`rounded-md border-2 px-8 py-2 border-primary-brown ${state.user_finance.users_contract_documents.last_seen ? 'bg-white text-primary-brown' : 'bg-primary-brown text-white'}`}>閲覧</a>
                      </div>
                  </li>
							}
							{
								state.user_finance.anonymous_partnership_contracts &&
                  <li
                      className={`flex items-center justify-between border-b-2 px-2 py-3 border-primary-brown text-base text-primary-brown font-bold sm:text-primary-text sm:px-5 sm:text-xl ${state.user_finance.anonymous_partnership_contracts.last_seen ? 'bg-white' : 'bg-secondary-brown'}`}>
                      <p>{state.user_finance.anonymous_partnership_contracts.name}</p>
                      <div className='flex flex-col items-end justify-end'>
                          {state.user_finance.anonymous_partnership_contracts.last_seen && <time
                        className='text-xs font-normal opacity-50 text-primary-text'>{state.user_finance.anonymous_partnership_contracts.last_seen} 既読</time>}
                          <a href={state.user_finance.anonymous_partnership_contracts.url}
                             target='popup'
                             onClick={(e) => {
                               handleDocumentClick(e, 'anonymous_partnership_contracts')
                             }}
                             className={`rounded-md border-2 px-8 py-2 border-primary-brown ${state.user_finance.anonymous_partnership_contracts.last_seen ? 'bg-white text-primary-brown' : 'bg-primary-brown text-white'}`}>閲覧</a>
                      </div>
                  </li>
							}
							{
								state.user_finance.electronic_transaction &&
                  <li
                      className={`flex items-center justify-between border-b-2 px-2 py-3 border-primary-brown text-base text-primary-brown font-bold sm:text-primary-text sm:px-5 sm:text-xl ${state.user_finance.electronic_transaction.last_seen ? 'bg-white' : 'bg-secondary-brown'}`}>
                      <p>{state.user_finance.electronic_transaction.name}</p>
                      <div className='flex flex-col items-end justify-end'>
                          {state.user_finance.electronic_transaction.last_seen && <time
                        className='text-xs font-normal opacity-50 text-primary-text'>{state.user_finance.electronic_transaction.last_seen} 既読</time>}
                          <a href={state.user_finance.electronic_transaction.url}
                             target='popup'
                             onClick={(e) => {
                               handleDocumentClick(e, 'electronic_transaction')
                             }}
                             className={`rounded-md border-2 px-8 py-2 border-primary-brown ${state.user_finance.electronic_transaction.last_seen ? 'bg-white text-primary-brown' : 'bg-primary-brown text-white'}`}>閲覧</a>
                      </div>
                  </li>
							}
					</ul>
				</section>
				<section className='mt-10'>
					<h3 className='rounded-full border-2 bg-primary-dark text-white w-fit p-2 mb-2 border-primary-dark text-lg font-bold italic'>STEP 2</h3>
					{
						state.user_finance.users_contract_documents?.last_seen && state.user_finance.for_user?.last_seen 
            && state.user_finance.electronic_transaction?.last_seen 
            && state.user_finance.anonymous_partnership_contracts?.last_seen ?
            <>
              {
                state.status === 'pending' ? 
                  <div className='my-4 px-2'>
                    <div>
                      <ul>
                        <li><b>出資口数：</b>{state.units}口</li>
                        <li><b>利用ポイント：</b>{numberWithCommas(state.reserved_point) || 0}pt</li>
                        <li><b>申込総額：</b>{numberWithCommas(state.investment_amount || 0)}円</li>
                      </ul>
                    </div>
                    {
                      userData?.user?.deposit && userData.user.deposit > 0 ?
                      <div className='my-2'>
                        <div><b>デポジット：</b> {numberWithCommas(userData.user.deposit)}円</div>
                        <InputField 
                          value={state.reserved_deposit} 
                          onChange={(e) => {
                            setState({...state, 'reserved_deposit': parseInt(e.target.value)});
                          }}
                          type='number'
                          name='reserved_deposit' 
                          label='デポジット利用額をご入力下さい。'
                          errors={error}
                          labelClassName='text-red-500'
                        />
                      </div>
                        : ''
                    }
                    <div className="w-full my-4">
                      <button 
                        type="button"
                        onClick={() => {setShowModal(true)}}
                        className='flex w-4/5 m-auto items-center justify-center rounded-full border-2 px-2 font-bold text-white border-primary-dark py-2.5 bg-primary-dark sm:w-96 sm:border-4 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
                        確認
                      </button>
                    </div>
                  </div>
                  :
                  <>
                    <p className='w-full text-right text-xl font-bold text-red-500'>振込金額：{numberWithCommas(state.transfer_deposit - state.total_transaction_amount)}円</p>
                    <p className='font-bold'>出資申込いただきありがとうございます。振込期限までに、出資金をお振込み下さい。</p>
                    <table className='mt-1 w-full border-collapse border-spacing-1 border table-auto'>
                      <tbody>
                      <tr>
                        <th className="border p-2 bg-gray-100">銀行名</th>
                        <td className="border p-2 bg-white">GMOあおぞらネット銀行</td>
                      </tr>
                      <tr>
                        <th className="border p-2 bg-gray-100">支店名（支店番号）</th>
                        <td
                          className="border p-2 bg-white">{state.va_data?.vaList[0].vaBranchNameKana}（{state.va_data?.vaList[0].vaBranchCode}）
                        </td>
                      </tr>
                      <tr>
                        <th className="border p-2 bg-gray-100">口座種別</th>
                        <td className="border p-2 bg-white">普通</td>
                      </tr>
                      <tr>
                        <th className="border p-2 bg-gray-100">口座番号</th>
                        <td className="border p-2 bg-white">{state.va_data?.vaList[0].vaAccountNumber}</td>
                      </tr>
                      <tr>
                        <th className="border p-2 bg-gray-100">受取人口座名</th>
                        <td className="border p-2 bg-white">{state.va_data?.vaHolderNameKana}</td>
                      </tr>
                      <tr>
                        <th className="border p-2 bg-gray-100">振込金額</th>
                        <td className="border p-2 bg-white">{numberWithCommas(state.transfer_deposit - state.total_transaction_amount)}円</td>
                      </tr>
                      <tr>
                        <th className="border p-2 bg-gray-100">振込期限</th>
                        <td className="border p-2 bg-white">{state.transfer_until}</td>
                      </tr>
                      </tbody>
                    </table>
                  </>
              }
            </>
              :
              <p className='font-bold'>’STEP 1’を終えた後、’STEP2’へお進み下さい。</p>
					}
          {
            showModal && (
              <div className='fixed inset-0 z-50 bg-black bg-opacity-50'>
                <div className='absolute inset-0 flex items-center justify-center'>
                  <div className='bg-white p-5 rounded-lg w-full sm:w-auto'>
                    <h3 className='text-xl font-bold text-primary-brown'>お申込内容確認</h3>
                    <div className='mt-5 flex h-full flex-col sm:gap-5 lg:flex-row'>
                      <div className='flex flex-col'>
                        <div className='flex flex-col bg-white sm:mb-1 sm:flex-row'>
                          <p
                            className='px-4 py-2 font-bold text-white bg-secondary-main sm:w-44 sm:text-center sm:text-xl'>出資口数</p>
                          <p
                            className='flex-1 border bg-white px-4 py-2 border-secondary-brown sm:px-2 sm:text-lg sm:font-bold lg:text-xl'>{state.units}<span>口</span>
                          </p>
                        </div>
                        <div className='flex flex-col sm:flex-row'>
                          <p
                            className='px-4 py-2 font-bold text-white bg-secondary-main sm:w-44 sm:text-center sm:text-xl'>出資金額</p>
                          <p
                            className='flex-1 border bg-white px-4 py-2 border-secondary-brown sm:px-2 sm:text-lg sm:font-bold lg:text-xl'>{numberWithCommas(state.investment_amount)}<span>円</span>
                          </p>
                        </div>
                      </div>
                      <div>
                        <div
                          className='flex h-full flex-col border-2 border-secondary-main sm:mb-1 sm:flex-row sm:items-stretch'>
                          <p
                            className='flex items-start px-4 py-2 font-bold bg-secondary-bright text-secondary-main sm:w-32 sm:items-center sm:justify-center sm:px-2 sm:text-lg lg:text-xl'>内訳</p>
                          <div className='flex w-full flex-col items-stretch justify-center bg-white px-4 sm:px-2'>
                            <p className='flex items-center justify-between py-2 sm:text-lg sm:font-bold lg:text-xl'>
                              <span>デポジット利用</span>
                              <span>{numberWithCommas(state.reserved_deposit) || '0'}<span>円</span></span>
                            </p>
                            <p className='flex items-center justify-between py-2 sm:text-lg sm:font-bold lg:text-xl'>
                              <span>トモタクポイント利用</span>
                              <span>{numberWithCommas(state.reserved_point) || '0'}<span>pt</span></span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="mt-5 mb-10 flex flex-wrap items-center justify-center gap-3 px-2 sm:mt-10 sm:gap-5 sm:px-0 lg:gap-10">
                      <button
                        type="button"
                        onClick={() => setShowModal(false)}
                        className='flex w-4/5 items-center justify-center rounded-full border-2 px-2 font-bold border-primary-brown py-2.5 text-primary-brown sm:w-96 sm:border-4 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full bg-white'>戻る
                      </button>
                      <button
                        type="button"
                        onClick={handleSubmit}
                        className='flex w-4/5 items-center justify-center rounded-full border-2 px-2 font-bold text-white border-primary-dark py-2.5 bg-primary-dark sm:w-96 sm:border-4 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>完了
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </section>
        <Breadcrumb
          items={[...breadcrumbItems, {url: '', name: state.user_finance.fund_name}]}
          className='mt-10'
        />
      </div>
    </main>
  );
};
export default PendingApplicationDetail;

