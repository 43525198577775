import React, { useEffect, useState } from 'react';
import { InputField } from '../../components/ui/forms/Input';
import { Breadcrumb } from '../../components/ui/Breadcrumb';
import { Link, useNavigate } from 'react-router-dom';
import { TwoColumnTableLayout } from '../../components/layouts/TwoColumnTableLayout';
import { RadioButtonWithLabel } from '../../components/ui/forms/RadioInput';
import SelectField from '../../components/ui/forms/SelectField';
import {
  investmentFundsOptions,
  MemberInformationType,
  occupationOptions,
  yearsOfInvestOptions,
} from '../../store/account';
import { useAccount } from '../../hooks/useAccount';
import { ErrorsType } from '../../components/ui/forms/ErrorList';
import BackendService from '../../infrastructure/api';
import UserService from '../../infrastructure/api/user';
import useAxios from '../../hooks/useAxios';


const breadcrumbItems = [
  { url: '/', name: 'マイページトップ' },
  { url: '/account', name: '会員情報' },
  { url: '', name: '会員登録情報変更' },

];


const EditMemberInformation: React.FC = () => {
  const { memberInformation, updateMemberInformation } = useAccount();
  const [state, setState] = useState<MemberInformationType>(memberInformation);
  const [errors, setErrors] = useState<ErrorsType>();
  const axios = useAxios();
  const userService = new UserService(axios);
  const backendService = new BackendService(axios);
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
    if (name === 'zipcode') {
      backendService
        .getAddress(value)
        .then((data) => {
          if (data !== 400)
            setState((prev) => ({ ...prev, ...data }));
        });
    }
  };

  useEffect(() => {
    setState(memberInformation);
  }, [memberInformation]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    userService.updateMemberInfo(state)
      .then(() => {
        updateMemberInformation(state);
        navigate(-1);
      })
      .catch((error) => {
        const errorList = document.querySelectorAll(`[name=${Object.keys(error)[0]}]`);
        if (errorList)
          errorList[0].scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'start',
          });
        setErrors(error);
      });
  };

  return (
    <main className='sm:mt-4 md:mt-14'>
      <form className='container mx-auto sm:px-4' onSubmit={handleSubmit}>
        <section className='text-center text-2xl font-bold text-secondary-main md:text-3xl'>
          <h1>会員登録情報変更</h1>
        </section>
        <TwoColumnTableLayout
          title='会員情報編集'
          className='mt-12'
        >
          <div className='mb-3 flex flex-col flex-wrap px-2 py-5 sm:px-8'>
            <InputField
              name='phone'
              label='電話番号'
              onChange={handleChange}
              value={state.phone}
              width='max-[400px]:w-full lg:w-3/4 sm:mr-10'
              errors={errors}
              required
            />
            <SelectField
              options={occupationOptions}
              valueKey='value'
              labelKey='label'
              value={state.occupation}
              label='職業'
              width='max-[400px]:w-full lg:w-3/4 sm:mr-10'
              required
              name='occupation'
              errors={errors}
              onChange={handleChange}
            />
            <InputField
              name='workplace'
              label='会社名'
              onChange={handleChange}
              value={state.workplace}
              width='max-[400px]:w-full lg:w-3/4 sm:mr-10'
              errors={errors}
            />
            <div className='flex w-full items-end'>
              <InputField
                label='年収'
                name='salary'
                onChange={handleChange}
                value={state.salary}
                className='w-full md:w-[65%]'
                width='max-[400px]:w-full  w-full sm:mr-10'
                errors={errors}
                required
              />
              <span className='ml-2 w-10'> 万円</span>
            </div>
            <SelectField
              options={yearsOfInvestOptions}
              valueKey='value'
              labelKey='label'
              name='investment_year'
              width='max-[400px]:w-full lg:w-3/4 sm:mr-10'
              value={state.investment_year}
              label='投資年数'
              onChange={handleChange}
              errors={errors}
            />
            <RadioButtonWithLabel options={investmentFundsOptions} labelKey='label' valueKey='value'
                                  value={state.investment_funds} label='投資資金' onChange={handleChange}
                                  name='investment_funds' />
          </div>
        </TwoColumnTableLayout>

        <section className='mt-16 mb-10 flex flex-wrap items-center justify-center gap-3 sm:gap-5 lg:gap-10'>
          <Link to='/account'
                className='flex w-4/5 items-center justify-center rounded-full border-2 bg-white px-2 text-sm font-bold border-secondary-main py-2.5 text-secondary-main sm:w-72 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
            会員情報ページに戻る
          </Link>
          <button
            type='submit'
            className='flex w-4/5 items-center justify-center rounded-full border-2 px-2 text-sm font-bold text-white border-primary-dark py-2.5 bg-primary-dark sm:w-72 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
            変更する
          </button>
        </section>
        <Breadcrumb items={breadcrumbItems} />
      </form>
    </main>);
};

export default EditMemberInformation;