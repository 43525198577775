import React from 'react';
import { Breadcrumb } from '../../components/ui/Breadcrumb';
import { RegistrationSteps } from '../../components/ui/RegistrationStep';
import { Link } from 'react-router-dom';

const breadcrumbItems = [
  { url: '/', name: '' },
];


const StepThree: React.FC = () => {
  return (
    <main className='sm:mt-4 md:mt-14'>
      <div className='container mx-auto sm:px-4'>
        <section className='text-center text-2xl font-bold text-secondary-main md:text-3xl'>
          <h1>本人確認手続き完了</h1>
        </section>
        <RegistrationSteps step={3} />
        <section className='w-full px-4 sm:w-[80%] md:w-[70%] lg:w-[60%] m-auto '>
          <div
            className='grid w-full items-center py-3'>
            <p
              className='sm:text-center py-3 text-left opacity-75 md:text-lg lg:text-xl max-[450px]:px-3'>
              本人確認の審査結果はご登録されたメールアドレスにお送りいたします。<br />
              ※マイページのベルマークからもご確認いただけます。<br />
              審査結果まで、今しばらくお待ち下さいませ。<br />
            </p>
          </div>
        </section>
        <div className='mt-12 flex flex-wrap items-center justify-center gap-3 pb-10 sm:gap-5 lg:gap-10'>
          <Link to='/'
                className='flex w-4/5 items-center justify-center rounded-full border-2 px-2 text-sm font-bold text-secondary-main border-secondary-main py-2.5 bg-white sm:w-72 sm:py-4 sm:text-base md:rounded-full md:text-xl lg:w-5/12 max-[400px]:w-full'>
            マイページTOPへ戻る
          </Link>
        </div>
        <Breadcrumb items={breadcrumbItems} />
      </div>
    </main>);
};

export default StepThree;