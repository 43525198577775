import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';

export interface SectionHeaderProps {
  title?: string;
  subTitle?: string;
  image?: string;
  className?: string;
  color?: string;
  width?: string;
  children?: React.ReactNode;
}

export const SectionHeaderLayout: React.FC<SectionHeaderProps> = ({ title, subTitle, image, className, children }) => {
  const [showModel, setShowModel] = useState(true);

  const handleModelClick = () => {
    setShowModel(!showModel);
  };

  return (
    <div className='mt-8'>
      <div
        className={`${className} flex w-full items-center justify-between border-b-4 px-5 pt-1 max-[450px]:px-3 cursor-pointer transition duration-1000 ease-in-out`}
        onClick={handleModelClick}>
        <div className='flex items-center gap-5'>
          <figure className='w-16'>
            <img className='h-full w-full object-cover' src={image} alt='name' />
          </figure>
          <div>
            <p className='text-xl font-bold'>{title}</p>
            <span>{subTitle}</span>
          </div>
        </div>
        <div className='items-end'>
          <ChevronDownIcon className={`h-10 w-10 ${showModel ? 'hidden' : ''}`} />
          <ChevronUpIcon className={`h-10 w-10 ${showModel ? '' : 'hidden'}`} />
        </div>
      </div>
      {showModel && (
        <div className='mt-4 mb-20 px-2 transition duration-1000 ease-in-out sm:px-0'>
          {children}
        </div>
      )}
    </div>
  );
};