import {
  BankAccountType,
  defaultAccountState,
  EmailChangeType,
  InvestorRegistrationType,
  MemberInformationType,
  MyNumberDataType,
  PasswordChangeType,
  PersonalInformationType,
} from '../../store/account';
import { initialDepositWithdraw } from '../../store/deposit';
import { HistorySearchParams } from '../../pages/tradingHistory';
import { Axios } from 'axios';
import {FundApplyType, WFCType} from '../../store/funds';

class UserService {
  csrfToken: string;
  axios: Axios;

  constructor(axios: Axios) {
    this.csrfToken = (sessionStorage.getItem('csrf_token') || '').replace(/(^"|"$)/g, '');
    this.axios = axios;
  }

  whoami() {
    return this.axios.get('/api/whoami/')
      .then((res) => {
        return { ...res.data, isAuthenticated: true };
      })
      .catch(() => {
        return { isAuthenticated: false };
      });
  }

  getCsrf() {
    return this.axios.get('/api/csrf/')
      .then((res) => {
        return res.headers['x-csrftoken'];
      })
      .catch(() => {
        return '';
      });
  }

  investorRegistration(data: InvestorRegistrationType, method: 'confirm' | 'post') {
    return this.axios.post(
      `/api/investor-registration/${method}/`,
      data,
      {
        headers: {
          'X-CSRFToken': this.csrfToken,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw JSON.parse(err.request.response);
      });
  }

  investorRegistrationInit() {
    return this.axios.get('/api/investor-registration/confirm/')
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw JSON.parse(error.request.response);
      });
  }

  getEKYcUrl() {
    return this.axios.post('/api/ekyc/get-url/',
      null,
      {
        headers: {
          'X-CSRFToken': this.csrfToken,
        },
      })
      .then((res) => {
        return res.data.ekycUrl;
      })
      .catch((error) => {
        throw JSON.parse(error.request.response);
      });
  }

  getAccountInfo() {
    return this.axios.get('/api/account/')
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        return defaultAccountState;
      });
  }

  updatePersonalInfo(data: PersonalInformationType) {
    return this.axios.put('/api/account/personal/', data, {
      headers: {
        'X-CSRFToken': this.csrfToken,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw JSON.parse(error.request.response);
      });
  }

  updateMemberInfo(data: MemberInformationType) {
    return this.axios.put('/api/account/member/', data, {
      headers: {
        'X-CSRFToken': this.csrfToken,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw JSON.parse(error.request.response);
      });
  }

  updateBankAccount(data: BankAccountType) {
    return this.axios.put('/api/account/bank-account/', data, {
      headers: {
        'X-CSRFToken': this.csrfToken,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw JSON.parse(error.request.response);
      });
  }

  getDepositWithdraw() {
    return this.axios.get('/api/deposit-withdraw/')
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        return initialDepositWithdraw;
      });
  }

  applyDepositWithdraw(amount: number) {
    return this.axios.post('/api/deposit-withdraw/', { amount: amount }, {
      headers: {
        'X-CSRFToken': this.csrfToken,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw JSON.parse(error.request.response);
      });
  }

  getUnreadNotifications() {
    return this.axios.get('/api/notifications/unread/')
      .then((res) => res.data)
      .catch(() => 0);
  }

  getNotifications() {
    return this.axios.get('/api/notifications/all/')
      .then((res) => res.data)
      .catch(() => []);
  }

  getOTP(data: { username: string, password: string }) {
    return this.axios.post('/api/account/email/change/', data, {
      headers: {
        'X-CSRFToken': this.csrfToken,
      },
    })
      .then((res) => res.data)
      .catch((err) => {
        throw JSON.parse(err.request.response);
      });
  }

  verifyOTP(data: EmailChangeType) {
    return this.axios.post('/api/account/email/change/verify/', data, {
      headers: {
        'X-CSRFToken': this.csrfToken,
      },
    })
      .then((res) => res.data)
      .catch((err) => {
        throw JSON.parse(err.request.response);
      });
  }

  changePassword(data: PasswordChangeType) {
    return this.axios.put('/api/account/password/change/', data, {
      headers: {
        'X-CSRFToken': this.csrfToken,
      },
    })
      .then((res) => res.data)
      .catch((err) => {
        throw JSON.parse(err.request.response);
      });
  }

  getHistories(data: HistorySearchParams, stringParams?: string) {
    return this.axios.get(`/api/history/get/${stringParams ? `?${stringParams}` : ''}`, {
      params: !stringParams ? data : {},
    })
      .then((res) => res.data)
      .catch((err) => {
        throw JSON.parse(err.request.response);
      });
  }

  getInvestmentStatus() {
    return this.axios.get('/api/investment/status/')
      .then((res) => res.data)
      .catch(() => false);
  }
  
  getCompletedInvestment(page: string | number) {
    return this.axios.get(`/api/investment/?page=${page}`)
      .then((res) => res.data)
      .catch(() => false);
  }

  getInvestmentDetail(pk: string) {
    return this.axios.get(`/api/investment/${pk}/`)
      .then((res) => res.data)
      .catch(() => false);
  }

  getDividends() {
    return this.axios.get('/api/user-dividends/')
      .then((res) => res.data)
      .catch(() => false);
  }

  uploadMynumber(data: MyNumberDataType) {
    return this.axios.put('/api/mynumber/', data, {
      headers: {
        'X-CSRFToken': this.csrfToken,
      },
    })
      .then((res) => res.data)
      .catch((err) => {
        throw JSON.parse(err.request.response);
      });
  }
  
  validateApplyFund(data: FundApplyType) {
    return this.axios.post('/api/user/fund/confirm/', data, {
      headers: {
        'X-CSRFToken': this.csrfToken,
      },
    })
      .then((res) => res.data)
      .catch((err) => {
        throw JSON.parse(err.request.response);
      })
  }

  applyFund(data: FundApplyType) {
    return this.axios.post('/api/user/fund/apply/', data, {
      headers: {
        'X-CSRFToken': this.csrfToken,
      },
    })
      .then((res) => res.data)
      .catch((err) => {
        throw JSON.parse(err.request.response);
      })
  }
  
  validateWaitingForCancel(data: WFCType) {
    return this.axios.post('/api/user/fund/wfc/validate/', data, {
      headers: {
        'X-CSRFToken': this.csrfToken,
      },
    })
      .then((res) => res.data)
      .catch((err) => {
        throw JSON.parse(err.request.response);
      })
  }

  applyWaitingForCancel(data: WFCType) {
    return this.axios.post('/api/user/fund/wfc/apply/', data, {
      headers: {
        'X-CSRFToken': this.csrfToken,
      },
    })
      .then((res) => res.data)
      .catch((err) => {
        throw JSON.parse(err.request.response);
      })
  }
  
  getPendingApplications() {
    return this.axios.get('/api/investment/pending/')
      .then((res) => res.data)
      .catch(() => []);
  }
  
  getPendingApplicationDetail(pk: string) {
    return this.axios.get(`/api/investment/pending/${pk}/`)
      .then((res) => res.data)
      .catch(() => false);
  }
  
  updatePendingApplication(pk: string, reservedDeposit: number) {
    return this.axios.patch(`/api/investment/pending/${pk}/`, 
      { 'reserved_deposit':reservedDeposit },
      {
        headers: {
          'X-CSRFToken': this.csrfToken,
        },
      })
      .then((res) => res.data)
      .catch((err) => {
        throw JSON.parse(err.request.response)
      });
  }
}

export default UserService;