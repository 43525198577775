import { useCallback, useContext } from 'react';
import { AccountContext } from '../contexts/account';
import {
  BANK_ACCOUNT_UPDATE,
  LOGIN_INFORMATION_UPDATE,
  MEMBER_INFORMATION_UPDATE,
  PERSONAL_INFORMATION_UPDATE,
} from '../store/constants';
import {
  BankAccountType,
  LoginInformationType,
  MemberInformationType,
  PersonalInformationType,
} from '../store/account';

export const useAccount = () => {
  const [state, dispatch] = useContext(AccountContext);
  const { memberInformation, personalInformation, loginInformation, bankAccount } = state;

  if (!AccountContext) {
    throw new Error('useStepper should be used inside StepperProvider');
  }

  const updateMemberInformation = useCallback((data: MemberInformationType) => {
    dispatch({
      type: MEMBER_INFORMATION_UPDATE,
      payload: { memberInformation: data },
    });
  }, [dispatch]);

  const updatePersonalInformation = useCallback((data: PersonalInformationType) => {
    dispatch({
      type: PERSONAL_INFORMATION_UPDATE,
      payload: { personalInformation: data },
    });
  }, [dispatch]);

  const updateLoginInformation = useCallback((data: LoginInformationType) => {
    dispatch({
      type: LOGIN_INFORMATION_UPDATE,
      payload: { loginInformation: data },
    });
  }, [dispatch]);

  const updateBankAccount = useCallback((data: BankAccountType) => {
    dispatch({
      type: BANK_ACCOUNT_UPDATE,
      payload: { bankAccount: data },
    });
  }, [dispatch]);

  return {
    updateMemberInformation,
    updatePersonalInformation,
    updateLoginInformation,
    updateBankAccount,
    personalInformation,
    bankAccount,
    memberInformation,
    loginInformation,
  };
};