import { useCallback, useContext } from 'react';
import { LoadingContext } from '../contexts/loading';
import { DECREMENT_ACTIVE_LOADING, INCREMENT_ACTIVE_LOADING, START_LOADING, STOP_LOADING } from '../store/constants';

export const useLoading = () => {
  const [state, dispatch] = useContext(LoadingContext);
  const { loading, activeLoading } = state;
  
  if (!LoadingContext) {
    throw new Error('useNotification should be used inside NotificationProvider');
  }

  const startLoading = useCallback(() => {
    dispatch({
      type: START_LOADING,
    });
  }, [dispatch]);
  
const stopLoading = useCallback(() => {
    dispatch({
      type: STOP_LOADING,
    });
  }, [dispatch]);

  const incrementActiveLoading = useCallback(() => {
    dispatch({
      type: INCREMENT_ACTIVE_LOADING
    })
  }, [dispatch])
  
  const decrementActiveLoading = useCallback(() => {
    dispatch({
      type: DECREMENT_ACTIVE_LOADING
    })
  }, [dispatch])
  
  return {
    startLoading,
    stopLoading,
    incrementActiveLoading,
    decrementActiveLoading,
    activeLoading,
    loading
  };
};