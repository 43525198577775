import React, {useEffect, useState} from 'react';
import FundPrivacyCheckLayout from '../layouts/FundPrivacyCheckLayout';
import {AgreementType, PreContractType, PropertyListType} from '../../store/funds';
import FundService from '../../infrastructure/api/funds';
import useAxios from '../../hooks/useAxios';
import unionImg from '../../assets/images/cf_union_img.jpg'
import {numberWithCommas} from '../../utlis/numbers';

export type FundPrivacyConformationProps = {
    title?: string;
    className?: string
    color?: string;
    width?: string;
    children?: React.ReactNode;
    setAgreement?: React.Dispatch<React.SetStateAction<AgreementType>>;
    agreement?: AgreementType;
    slug: string
}


const PropertyList:React.FC<{data: PropertyListType}> =({data}) => {
    return (
        <>
            <table className="mt-10 w-full border-2 border-gray-200 bg-white">
                <caption className="text-start text-lg font-bold">土地</caption>
                <tbody>
                <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                    <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">所在</th>
                    <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">{data.land_address}</td>
                </tr>
                <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                    <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">地番</th>
                    <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">{data.land_lot_num}</td>
                </tr>
                <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                    <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">地目</th>
                    <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">{data.land_ground}</td>
                </tr>
                <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                    <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">地積</th>
                    <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">{data.land_area}</td>
                </tr>
                </tbody>
            </table>
                <table className="mt-10 w-full border-2 border-gray-200 bg-white">
                <caption className="text-start text-lg font-bold">建物</caption>
                    <tbody>
                <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                    <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">所在</th>
                    <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">{data.building_address}</td>
                </tr>
                <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                    <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">家屋番号</th>
                    <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">{data.building_house_num}</td>
                </tr>
                <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                    <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">種類</th>
                    <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">{data.building_type}</td>
                </tr>
                <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                    <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">構造</th>
                    <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">{data.building_structure}</td>
                </tr>
                <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                    <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">床面積</th>
                    <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">{data.building_floor_space}</td>
                </tr>
                <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                    <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">建築時期</th>
                    <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">{data.building_construct_time}</td>
                </tr>
                    </tbody>
            </table>
            <table className="mt-10 w-full border-2 bg-white border-gray-200">
                <caption className="text-start text-lg font-bold">専有部</caption>
                    <tbody>
                    <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                        <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">所在</th>
                        <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">{data.excl_dep_address}</td>
                    </tr>
                    <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                        <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">種類</th>
                        <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">{data.excl_dep_type}</td>
                    </tr>
                    <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                        <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">構造</th>
                        <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">{data.excl_dep_structure}</td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

const FundPrivacyConformation: React.FC<FundPrivacyConformationProps> = ({setAgreement, slug, agreement}) => {
    const [document, setDocument] = useState<PreContractType>();
    const fundService = new FundService(useAxios());
    const handleAgreement = (type: keyof AgreementType) => {
        if (setAgreement)
            setAgreement((prev) => ({...prev, [type]: true}));
    }
    
    useEffect(() => {
        fundService.getContractDocument(slug)
            .then((data) => {
                setDocument(data);
            });
    }, []);
    
    if (!document) return <></>
    
    return (
        <React.Fragment>
            <FundPrivacyCheckLayout isRead={agreement?.first} title='個人情報保護方針' setAgreement={() => {
                handleAgreement('first')
                }}>
                <div className='flex flex-col gap-5'>
                    <p className=''>最終改正年月日 2020年6月10日<br/>
                        株式会社イーダブルジー<br/>
                        代表取締役 田中 克尚
                    </p>
                    <p>
                        当社は、当社が取り扱う全ての個人情報の保護について、社会的使命を十分に認識し、本人の権利の保護、個人情報に関する法規制等を遵守します。また、以下に示す方針を具現化するための個人情報保護マネジメントシステムを構築し、最新のＩＴ技術の動向、社会的要請の変化、経営環境の変動等を常に認識しながら、その継続的改善に、全社を挙げて取り組むことをここに宣言します。
                        個人情報は、不動産売買、不動産管理、損害保険代理業、クラウドファンディングに基づく事業業務における当社の正当な事業遂行上並びに従業員の雇用、人事管理上必要な範囲に限定して、取得・利用及び提供をし、特定された利用目的の達成に必要な範囲を超えた個人情報の取扱い（目的外利用）を行いません。また、目的外利用を行わないための措置を講じます。
                        個人情報保護に関する法令、国が定める指針及びその他の規範を遵守致します。</p>
                    <p>
                        個人情報の漏えい、滅失、き損などのリスクに対しては、合理的な安全対策を講じて防止すべく事業の実情に合致した経営資源を注入し個人情報セキュリティ体制を継続的に向上させます。また、個人情報保護上、問題があると判断された場合には速やかに是正措置を講じます。
                        個人情報取扱いに関する苦情及び相談に対しては、迅速かつ誠実に、適切な対応をさせていただきます。</p>
                    <p>
                        個人情報保護マネジメントシステムは、当社を取り巻く環境の変化を踏まえ、適時・適切に見直してその改善を継続的に推進します。
                        以上</p>
                </div>
            </FundPrivacyCheckLayout>
            <FundPrivacyCheckLayout isRead={agreement?.second} title='契約成立前書面' setAgreement={() => {
                handleAgreement('second')
            }}>
                <div className='flex flex-col gap-5 tracking-widest'>
                    <header>
                        <h2 className='text-center font-bold text-xl'>
                            <br/>
                            {document.fund_name}
                            不動産特定共同事業契約書（匿名組合型）<br/>
                            契約成立前書面
                        </h2>
                    </header>
                    <div>
                        <p className='pb-2'>不動産特定共同事業法（以下「法」といい、同法施行規則を「施行規則」といいます。）第24条に基づき、次のとおり、不動産特定共同事業契約成立前に、重要事項の説明をいたします。</p>
                        <p>この内容は重要ですので、ご熟読のうえ、充分にご理解いただきますようお願い申し上げます。また、詳細につきましては、本書のほか、当社と出資者様の間で締結される予定の不動産特定共同事業契約書（匿名組合契約）もあわせてご確認くださいますようお願い申し上げます。</p>
                    </div>
                    <section className="mt-10">
                        <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-xl font-bold tracking-[1px]"> 1.不動産特定共同事業者に関する事項</h2>
                            
                        <dl className="flex w-full flex-col items-start py-3">
                            <dt>商号</dt>
                            <dd>
                                <span>{document.comp_name}</span>
                            </dd>
                        </dl>
                        <dl className="flex w-full flex-col items-start py-3">
                            <dt className="font-bold">住所</dt>
                            <dd>
                                <span>{document.comp_address}</span>
                            </dd>
                        </dl>
                        <dl className="flex w-full flex-col items-start py-3">
                            <dt className="font-bold">代表者の氏名</dt>
                            <dd>
                                <span>{document.name_of_representative}</span>
                            </dd>
                        </dl>
                        <dl className="flex w-full flex-col items-start py-3">
                            <dt className="font-bold">許可番号</dt>
                            <dd>
                                東京都知事 第133号
                            </dd>
                        </dl>
                        <dl className="flex w-full flex-col items-start py-3">
                            <dt className="font-bold">資本金</dt>
                            <dd>
                                <span>{document.capital}円</span>
                            </dd>
                        </dl>
                        <dl className="flex w-full flex-col items-start py-3">
                            <dt className="font-bold">発行済株式総数の100分の5以上の株式を有する株主の商号若しくは名称又は氏名</dt>
                            <dd>
                                <span>{document.shareholders}</span>
                            </dd>
                        </dl>
                        <dl className="flex w-full flex-col items-start py-3">
                            <dt className="font-bold">不動産特定共同事業者（本事業者）が発行済株式の総数または出資の総額を契約締結法人により保有されている法人であって施行規則第10条各号に掲げる要件に該当するものであるときは、その営む不動産特定共同事業に関して当該契約締結法人が連帯して債務を負担する契約の内容</dt>
                            <dd>
                                該当しません。
                            </dd>
                        </dl>
                        <dl className="flex w-full flex-col items-start py-3">
                            <dt className="font-bold">不動産取引事業者が他に事業を行っているときは、その事業の種類</dt>
                            <dd>
                                宅地建物取引業
                            </dd>
                        </dl>
                        <dl className="flex w-full flex-col items-start py-3">
                            <dt className="font-bold">事業開始日を含む事業年度の直前三年の各事業年度の貸借対照表及び損益計算書の要旨</dt>
                            <dd>
                                <a href='https://mypage.tomotaqu.com/electronic-document#financialDoc' target='_blank'
                                   rel='noreferrer'>こちら</a>をご参照下さいませ。
                            </dd>
                        </dl>
                        <dl className="flex w-full flex-col items-start py-3">
                            <dt className="font-bold">不動産特定共同事業者の役員の氏名</dt>
                            <dd><span><p dangerouslySetInnerHTML={{__html: document.name_of_officers}}/></span>
                            </dd>
                        </dl>
                        <dl className="flex w-full flex-col items-start py-3">
                            <dt className="font-bold">役員の氏名並びに役員が他の法人の常務に従事し、又は事業を営んでいるときは、当該他の法人の商号および業務</dt>
                            <dd><span><p dangerouslySetInnerHTML={{__html: document.other_trade_names}}/></span>
                            </dd>
                        </dl>
                        <dl className="flex w-full flex-col items-start py-3">
                            <dt className="font-bold">業務管理者名簿</dt>
                            <dd>
                                <a href='https://www.tomotaqu.com/business_manager/' target='_blank'
                                   rel='noreferrer'>こちら</a>からご覧ください。
                            </dd>
                        </dl>

                    </section>
                        <section className="mt-10">
                         <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-xl font-bold tracking-[1px]">2.不動産特定共同事業（契約）について</h2>
                        <dl className="py-3">
                            <dt><h3 className="text-lg font-bold">法第2条第3項各号に掲げる契約の種別</h3></dt>
                            <dd>
                                不動産特定共同事業法第2条第3項2号規定する商法上の匿名組合契約（以下、「本契約」ともいう。）<br/><br/>
                                当該種別に応じた不動産特定共同事業の仕組み（下記図）<br/>
                                不動産特定共同事業とは、国土交通大臣又は都道府県知事の許可を受けて不動産特定共同事業を営む者をいう（以下、「本事業者」ともいう。）
                                <figure className="px-10 py-10">
                                    <img src={unionImg} alt=""/>
                                </figure>
                            </dd>
                        </dl>
                        <dl className="py-3">
                            <dt><h3 className="text-lg font-bold">不動産特定共同事業に係る業務（軽微なものを除く）の委託の有無</h3></dt>
                            <dd>
                                当該物件の資産管理業務を委託（マスターリース契約）
                            </dd>
                        </dl>
                        <dl className="py-3">
                            <dt><h3 className="text-lg font-bold">当該業務を委託する場合には委託先の商号、住所及び委託する業務の内容</h3></dt>
                            <dd>
                                商号：株式会社イーズレーベル<br/>
                                所在地：東京都港区六本木7丁目4番1号<br/>
                                委託業務：テナント賃料の管理、施設の清掃などの管理業務
                            </dd>
                        </dl>
                        <dl className="py-3">
                            <dt><h3 className="text-lg font-bold">利害関係人との間の不動産特定共同事業に係る重要な取引の有無並びに当該取引がある場合には利害関係人と不動産特定共同事業者との関係、当該利害関係人の商号、所在地、取引の額及び取引の内容</h3></dt>
                            <dd>
                                あり<br/>
                                関係：子会社<br/>
                                商号：株式会社イーズレーベル<br/>
                                所在地：東京都港区六本木7丁目4番1号<br/>
                                取引額および取引内容：年間
                                <div>マスターリースの対価{document.master_lease} 円にて、マスターリース契約を締結しております。</div>
                            </dd>
                        </dl>
                    </section>
                    
                     <section className="mt-10">
                         <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-xl font-bold tracking-[1px]">3.不動産特定共同事業契約に係る法令に関する事項の概要について</h2>
                        <dl className="py-4">
                        <dt><h3 className="text-lg font-bold">不動産特定共同事業法</h3></dt>
                        <dd>
                            <ul className="ml-10 list-decimal leading-8">
                                <li>
                                    不動産特定共同事業を行うためには、主務大臣（国土交通大臣）又は、都道府県知事の許可を要します。
                                </li>
                                <li>
                                    不動産特定共同事業者には、以下の事項等が義務付けられています。
                                    <ul className="ml-3 list-disc pt-2 leading-8">
                                        <li>業務管理者の設置</li>
                                        <li>許可を受けた約款に基づく契約締結</li>
                                        <li>契約成立前の書面の交付・説明</li>
                                        <li>契約成立時の書面（匿名組合契約書）の交付</li>
                                        <li>事業参加者への財産管理報告書の交付及び情報開示</li>
                                        <li>財産の分別管理</li>
                                        <li>許可を受けている主務大臣又は都道府県知事への事業報告書の提出（年1回）</li>
                                        <li>その他（守秘義務等）</li>
                                    </ul>
                                </li>
                            </ul>
                        </dd>
                        <dt><h3 className="pt-5 text-lg font-bold">出資の受け入れ、預り金、及び金利等の取締に関する法律（出資法）</h3></dt>
                        <dd>
                            不動産特定共同事業者が、不特定かつ多数の者に対し、後日出資の払戻しとして出資金の全額もしくは、これを超える金額に相当する金銭を支払うべき旨を明示し、又は暗黙のうちに示して、出資金の受入れを行うことを禁止しています。本契約は出資金が毀損するリスクを明示しており、これに牴触しません。
                        </dd>
                        <dt><h3 className="pt-5 text-lg font-bold">金融商品取引法</h3></dt>
                        <dd>
                            金融商品取引法第39条（第3項ただし書及び第5項を除く。）及び第40条の規定は、不動産特定共同事業者が行う不動産特定共同事業契約の締結又はその代理若しくは媒介について準用します。
                            <ul className="ml-5 list-disc">
                                <li>損失補填等の禁止</li>
                                <li>適合性の原則等</li>
                            </ul>
                        </dd>
                        <dt><h3 className="pt-5 text-lg font-bold">金融商品の販売等に関する法律</h3></dt>
                        <dd>
                            <ul className="ml-10 list-decimal leading-8">
                                <li>不動産特定共同事業者は、顧客の状況に応じたリスクの説明及び適正な勧誘活動が義務付けられています。</li>
                                <li>不動産特定共同事業者は、リスク説明を怠り元本割れを起こした場合、元本欠損部分を損害の金額と推定して損害賠償を義務付けられています。</li>
                            </ul>
                        </dd>
                        <dt><h3 className="pt-5 text-lg font-bold">犯罪収益移転防止法</h3></dt>
                        <dd>
                            不動産特定共同事業者は、本契約の締結の際に事業参加者の本人確認が義務付けられています。また、本人確認記録の作成、保存、取引記録の作成、保存、疑わしい取引の届出義務を有しています。
                        </dd>
                        <dt><h3 className="pt-5 text-lg font-bold">個人情報の保護に関する法律</h3></dt>
                        <dd>
                            不動産特定共同事業者は個人情報取扱業者として、法律及びガイドラインを遵守し、本契約の締結により知り得た個人情報について、責任を持って管理する等が義務付けられています。
                        </dd>
                    </dl>
                    </section>
                    <section className="mt-10">
                         <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-xl font-bold tracking-[1px]">4.事業参加者の権利及び責任の範囲等に関する次の事項について</h2>
                        <dl>
                        <dt><h3 className="pt-5 text-lg font-bold">出資又は賃貸若しくは賃貸の委任である財産に関する事業参加者の監視権の有無及びその内容</h3></dt>
                        <dd>
                            事業参加者は、本事業者が備え置いている本事業に係る業務及び財産の状況を記載した書類を閲覧する権利、監視権を有しています。
                        </dd>
                        <dt><h3 className="pt-5 text-lg font-bold">事業参加者の第三者に対する責任の範囲</h3></dt>
                        <dd>
                            <ul className="ml-10 list-decimal">
                                <li>本事業に係る第三者に対する債務は本事業者が負担し、事業参加者は第三者に対し、債務は負わないものとします。</li>
                                <li>事業参加者は本事業に伴い発生した第三者への損害について、故意もしくは重過失の場合を除いて、責任を負わないものとします。</li>
                            </ul>
                        </dd>
                        <dt><h3 className="pt-5 text-lg font-bold">収益又は利益の分配及び契約終了時における残余財産の受領権及び出資の返還を受ける権利に関する事項</h3></dt>
                        <dd>
                            <ul className="ml-10 list-decimal">
                                <li>収益及び利益の分配は本契約に従い、各計算期間について利益が生じた場合、金銭の分配を受ける権利を有していますが、各計算期間について損失が生じた場合、本契約に従い金銭の分配はございません。</li>
                                <li>契約終了時における、残余財産は本事業者に帰属し、事業参加者の受領権はございません。</li>
                                <li>契約終了時において、事業参加者は出資の価額を上限に出資金の返還を受ける権利がございますが、元本額の返還は保証されていません。</li>
                            </ul>
                        </dd>
                        <dt><h3 className="pt-5 text-lg font-bold">収益又は利益の分配及び出資の返還について信用補完の有無</h3></dt>
                            <dd>
                                有：信用補完を行う者：<span>{document.comp_name}</span>
                                <span className="pt-3">{document.name_of_officers.split('<br>')[0]}</span><br/>
                                <span className="pt-3">{document.comp_address}</span><br/>
                                本契約は優先劣後構造となっており、事業参加者は優先出資を、本事業者が
                                <span>{document.schedule_sub_invest}</span><br/>
                                （予定）の劣後出資を行います。損益及び金銭の分配における優先順位については「事業参加者に対する収益又は利益の分配に関する事項について」をご確認ください。
                            </dd>
                        </dl>
                    </section>

                     <section className="mt-10">
                         <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-xl font-bold tracking-[1px]">5.対象不動産の特定および当該対象不動産に係る不動産取引の内容に関する次の事項について</h2>
                            <PropertyList data={document.property_list}/>
                        <dl>
                        <dt><h3 className="pt-5 text-lg font-bold">対象不動産に係る不動産取引の取引形態の別</h3></dt>
                        <dd>
                            本事業者が土地・建物を本事業者固有の財産にて所有
                        </dd>

                        <dt><h3 className="pt-5 text-lg font-bold">対象不動産に係る借入及びその予定の有無</h3></dt>
                        <dd>
                            対象不動産に係る借入及びその予定はございません。
                        </dd>

                        <dt><h3 className="pt-5 text-lg font-bold">不動産取引の開始予定日（本事業開始日）</h3></dt>
                        <dd>
                            <span>{document.operation_start_time}</span>
                            <p>但し、申込期間満了前に出資総額に達した場合には、本事業者の判断で申込期間が短縮され、不動産取引の開始予定日を早めることがあります。</p>
                            <p>また、申込期間満了後にクーリングオフにより解約が生じた場合には、本事業者の判断で追加の申込期間が設けられ、不動産取引の開始予定日を遅らせることがあります。</p>
                        </dd>

                        <dt><h3 className="pt-5 text-lg font-bold">不動産取引の終了予定日</h3></dt>
                        <dd>
                            <span>{document.operation_finish_time}</span>
                            <p>但し、当該終了予定日以前に、不動産市況その他の状況を踏まえ、対象物件の売却を完了し、本事業者の判断で不動産取引を終了することがあります。</p>
                            <p>また、上記の契約期間内までに対象不動産全部の売却等が完了しない場合には、本事業者は、本契約の契約期間の満了日の２ヶ月前までに本出資者に書面又はは電磁的方法により通知することにより、６ヵ月を超えない範囲で本契約の契約期間を延長終了することができます。</p>
                        </dd>
                    </dl>
                           
                    </section>
                    <section className="mt-10">
                        <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">6.対象不動産に関する事項</h2>
                        <p><a className="underline underline-offset-4 text-primary-dark" href={document.matters_pdf || ''} target="_blank" rel="noreferrer">別紙添付資料</a>をご参照下さいませ。</p>
                    </section>
                    
                    <section className="mt-10">
                    <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">7.対象不動産の価格及び当該価格の算定方法について</h2>
                    <table className="mt-10 w-full border-2 border-gray-200 bg-white">
                        <tbody>
                        <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                            <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">対象不動産の価格</th>
                            <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">{document.price_of_target_estate}円</td>
                        </tr>
                        <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                            <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">対象不動産の価格の算定方法</th>
                            <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">
                                <p dangerouslySetInnerHTML={{__html: document.calc_desc_of_price}}></p>
                            </td>
                        </tr>
                        <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                            <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">
                                不動産鑑定士による鑑定評価の有無<br/>
                                有の場合は、当該鑑定評価の結果及び方法並びに鑑定評価を行った者の氏名
                            </th>
                            <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">
                                <p dangerouslySetInnerHTML={{__html: document.result_method_appraisal}}></p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </section>

                <section className="mt-10">
                    <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">8.対象不動産に関して不動産特定共同事業者等が賃貸借契約を締結した相手方（以下、「テナント」という。）がある場合にあっては次の事項について</h2>
                    <table className="mt-10 w-full border-2 border-gray-200 bg-white">
                        <tbody>
                        <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                        <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">テナント総数</th>
                            <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">{document.total_tenants}戸</td>
                        </tr>
                        <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                        <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">全賃貸面積</th>
                            <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">
                                {document.total_leased_area}
                            </td>
                        </tr>
                        <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                        <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">賃貸借可能面積</th>
                            <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">
                               {document.leasable_area}
                            </td>
                        </tr>
                        <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                        <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">
                                最近2年の賃貸稼働率<br/>
                                （各年同一日における稼働率）<br/>

                            </th>
                            <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">
                                <p dangerouslySetInnerHTML={{__html: document.rental_occupancy_rate}}></p>
                            </td>
                        </tr>
                        <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                        <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">契約形態</th>
                            <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">
                                {document.type_of_contract}
                            </td>
                        </tr>
                        <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                        <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">テナントの名称・業種</th>
                            <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">
                                {document.tenant_name}
                            </td>
                        </tr>
                        <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                        <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">マスターリース契約額（年間）</th>
                            <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">
                                 {document.master_lease_contract_amount}円
                            </td>
                        </tr>
                        <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                        <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">賃貸面積</th>
                            <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">
                                {document.rental_area}
                            </td>
                        </tr>
                        <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                        <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">契約期間</th>
                            <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">
                                {document.contract_period}
                            </td>
                        </tr>
                        <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                        <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">契約更改の方法</th>
                            <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">
                                {document.contract_renew_methods}
                            </td>
                        </tr>
                        <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                        <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">保証金</th>
                            <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">
                                 {document.deposit}円
                            </td>
                        </tr>
                        <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                        <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">賃貸借契約に関する重要な事項</th>
                            <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">
                                <p dangerouslySetInnerHTML={{__html: document.important_matters}}></p>
                            </td>
                        </tr>
                        <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                        <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">対象不動産に係る賃料の支払状況</th>
                            <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">
                                <p dangerouslySetInnerHTML={{__html: document.payment_status}}></p>
                            </td>
                        </tr>
                        <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                        <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">全賃貸事業費用<br/></th>
                            <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">
                                 <p dangerouslySetInnerHTML={{__html: document.total_rental_business_cost}}></p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </section>

                <section className="mt-10">
                    <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">9.出資を伴う契約の事項</h2>
                    <table className="mt-10 w-full border-2 border-gray-200 bg-white">
                        <tbody>
                        <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                            <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">収益又は利益の分配及び出資の価額の返還を受ける権利の名称</th>
                            <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">
                                 <span>{document.fund_name}</span>
                            </td>
                        </tr>
                        <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                            <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">対象不動産の出資総額</th>
                            <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">
                                金<span>{numberWithCommas(document.total_investment)}</span>円（優先出資及び劣後出資総額の総和）
                            </td>
                        </tr>
                        <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                            <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">優先出資募集総額</th>
                            <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">
                                金<span>{numberWithCommas(document.amount_raised)}</span>円
                            </td>
                        </tr>
                        <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                            <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">募集総口数</th>
                            <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">
                                <span>{numberWithCommas(document.number_kuchi)}</span>口（優先出資総額口数）
                            </td>
                        </tr>
                        <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                            <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">出資単位</th>
                            <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">
                                <span>{document.min_num_units}</span>口以上（1口単位）1口：金<span>{numberWithCommas(document.kuchi)}</span>円
                            </td>
                        </tr>
                        <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                            <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">申込の期間及び方法</th>
                            <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">
                                <p dangerouslySetInnerHTML={{__html: document.application_period_method}}></p>
                            </td>
                        </tr>
                        <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                            <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">申込取扱場所</th>
                            <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">
                                <span>{document.comp_name}</span> <span >{document.comp_address}</span><br/>
                                TEL：<span>{document.comp_tel}</span> メール：<span>{document.comp_email}</span>
                            </td>
                        </tr>
                        <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                            <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">払込の期日及び方法</th>
                            <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">
                                開始前日(<span>{document.transfer_deadline}</span>)まで<br/>
                                払込の方法：本事業者の指定する銀行口座へ振込<br/>
                                振込手数料は事業参加者（優先出資者）の負担といたします。
                            </td>
                        </tr>
                        <tr className="flex flex-col border-b-2 border-gray-200 sm:flex-row text-sm sm:text-base">
                            <th className="w-full border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] md:w-[40%] sm:px-5">出資の募集と対象不動産の取得</th>
                            <td className="w-full px-3 py-2 sm:flex-1 sm:px-5">
                                <p dangerouslySetInnerHTML={{__html: document.recruitment_invest_acquisition}}></p>
                                 <span>本事業の対象不動産はすでに取得済みでございます。</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </section>
                <section className="mt-10">
                <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">10.不動産特定共同事業法施行規則第50条第1号の期間（報告対象期間）に係る同条第3号及び第4号に掲げる事項に対する公認会計士又は監査法人の監査を受ける予定の有無について</h2>
                <dl>
                    <dt><h3 className="pt-5 text-lg font-bold">公認会計士又は監査法人による監査の有無及び受ける予定の有無</h3></dt>
                    <dd>
                        <p dangerouslySetInnerHTML={{__html: document.new_additional_text}}></p>
                    </dd>

                    <dt><h3 className="pt-5 text-lg font-bold">監査を受ける範囲</h3></dt>
                    <dd>
                        <p dangerouslySetInnerHTML={{__html: document.new_additional_text_2}}></p>
                    </dd>
                </dl>
            </section>
            <section className="mt-10">
                 <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-xl font-bold tracking-[1px]">11.事業参加者に対する収益又は利益の分配に関する事項について</h2>
                 <dl>
                <dt><h3 className="pt-5 text-lg font-bold">計算期間</h3></dt>
                <dd>
                    本事業の利益の計算期間は、各計算期日の末日をいい、初回を{document.calc_period_start}から{document.calc_period_finish}とし、以降{document.calc_period_month}か月毎の末日及び本事業の清算手続において本事業に係る一切の債務を弁済した日又は本事業に帰属する財産の全てが本事業に係る債務の弁済に充てられた日とします。
                </dd>
                </dl>
                
                <dl>
                <dt><h3 className="pt-5 text-lg font-bold">支払い方法</h3></dt>
                <dd>
                    本事業者は各計算期日の翌々月の最終営業日までの間で、本事業者が裁量により指定する日（以下「金銭配当日」という。）に利益相当額の金銭を事業参加者が予め指定した金融機関の口座へ振込によりお支払します。<br/>
                    振込手数料は本事業の収益より負担いたします。<br/>
                    但し、各計算期日の利益計算の結果、損失が生じた場合、利益分配金はお支払いしません。
                </dd>

                <dt><h3 className="pt-5 text-lg font-bold">計算方法</h3></dt>
                <dd>
                     <ul className="max-[500px]:ml-5 ml-6 md:ml-10 list-decimal leading-7">
                        <li>
                            本事業者は、各計算期間に、当該計算期間の下記（1）に規定される本事業から生じた収益から下記（2）に規定される本事業から生じた費用を控除することにより、本事業に係る税引前利益（以下「匿名組合利益」という。）又は税引前損失（以下「匿名組合損失」といい、匿名組合利益及び匿名組合損失を総称して「匿名組合損益」という。）を計算いたします。<br/>
                            <ul className="ml-2 sm:ml-5 leading-7">
                                <li>
                                    （1）本事業から生じた収益
                                </li>
                                <li>
                                    ① 対象不動産から生じる賃料収入
                                </li>
                                <li>
                                    ② 対象不動産の売却益
                                </li>
                                <li>
                                    ③ 対象不動産に係る保険金
                                </li>
                                <li>
                                    ④ 本事業に係る金銭の運用から得られる受取利息
                                </li>
                                <li>
                                    ⑤ 匿名組合出資金償還益及び本事業に関連する債務の債務免除益
                                </li>
                                <li>
                                    ⑥ 本事業に係るその他の収益
                                </li>
                                <li>
                                    （2）本事業から生じた費用
                                </li>
                                <li>
                                    ① 対象不動産の取得、管理、修繕及び売却等に要する諸費用
                                </li>
                                <li>
                                    ② 対象不動産の売却損
                                </li>
                                <li>
                                    ③ 対象不動産に係る損害保険料
                                </li>
                                <li>
                                    ④ 対象不動産に係る公租公課
                                </li>
                                <li>
                                    ⑤ 本事業に係る日常的な経理業務や一般管理業務に要する費用その他の一切の営業費用
                                </li>
                                <li>
                                    ⑥ 匿名組合出資金償還損
                                </li>
                                <li>
                                    ⑦ 本事業の遂行に係る本事業者報酬
                                </li>
                            </ul>
                        </li>
                        <li>
                            本事業者は、各計算期間に、当該計算期間の下記（1）に規定される本事業から生じた収益から下記（2）に規定される本事業から生じた費用を控除することにより、本事業に係る税引前利益（以下「匿名組合利益」という。）又は税引前損失（以下「匿名組合損失」といい、匿名組合利益及び匿名組合損失を総称して「匿名組合損益」という。）を計算いたします。
                            <ul className="ml-2 sm:ml-5 leading-7">
                                <li>
                                    （1）当該計算期間について匿名組合損失が生じた場合、当該匿名組合損失を以下の順序で分配します。
                                </li>
                                <li>
                                    ① まず、第2条第3項に基づく本事業に対する出資（以下「劣後出資」という。）に係る損失の分配として、劣後出資の額から当該計算期間の前の計算期間まで（以下「経過済計算期間」という。）に本①に従って本事業者に分配された劣後出資に係る損失（もしあれば）を控除した額（但し、経過済計算期間までに第（2）号③に従って補てんされた金額を加算する。）を限度として、本事業者に帰属させる。
                                </li>
                                <li>
                                    ② 前①による匿名組合損失の分配後になお残損失がある場合、第2条第1項及び他の匿名組合契約に基づく出資（以下「優先出資」という。）に係る損失の分配として、優先出資の総額（以下「優先出資総額」という。）から経過済計算期間に本②に従って優先出資者に分配された優先出資に係る損失（もしあれば）を控除した額（但し、経過済計算期間までに第（2）号②に従って補てんされた金額を加算する。）を限度として、優先出資総額に対する各優先出資者の出資額の割合（以下「優先出資割合」という。）に応じて各優先出資者に帰属させる。
                                </li>
                                <li>
                                    ③ 前②による匿名組合損失の分配後になお残損失がある場合、本事業者がその固有の勘定において残損失を負担する。
                                </li>
                                <li>
                                    （2）当該計算期間について匿名組合利益が生じた場合、当該匿名組合利益を以下の順序で分配します。
                                </li>
                                <li>
                                    ① まず、経過済計算期間に第（1）号③に従って本事業者の固有勘定に分配された匿名組合損失（もしあれば）の合計額（但し、経過済計算期間までに本①に従って補てんされた金額を控除する。）に満つるまでの金額を、本事業者の固有勘定に分配し、本事業者の固有勘定に係る匿名組合損失の補てんに充当するものとする。
                                </li>
                                <li>
                                    ② 前①による匿名組合損失の補てん後になお残利益がある場合、経過済計算期間に第（1）号②に従って優先出資者に分配された優先出資に係る損失（もしあれば）の合計額（但し、経過済計算期間までに本②に従って補てんされた金額を控除する。）に満つるまでの金額を、優先出資割合に応じて各優先出資者に分配し、各優先出資者の優先出資に係る匿名組合損失の補てんに充当するものとする。
                                </li>
                                <li>
                                    ③ 前②による匿名組合損失の補てん後になお残利益がある場合、経過済計算期間に第（1）号①に従って本事業者に分配された劣後出資に係る損失（もしあれば）の合計額（但し、経過済計算期間までに本③に従って補てんされた金額を控除する。）に満つるまでの金額を、本事業者に分配し、本事業者の劣後出資に係る匿名組合損失の補てんに充当するものとする。
                                </li>
                                <li>
                                    ④ 前③による匿名組合損失の補てん後になお残利益がある場合、優先出資に係る利益の分配として、当該計算期間の末日時点における優先出資者の出資額に当該計算期間の実日数を乗じ365で除し{document.percent}％を乗じた金額に満つるまでの金額を、優先出資割合に応じて優先出資者に帰属させる。
                                </li>
                                <li>
                                    ⑤ 前④による匿名組合利益の分配後になお残利益がある場合、劣後出資に係る利益の分配として、残利益を本事業者に帰属させる。
                                </li>
                            </ul>
                        </li>
                        <li>
                            本事業者は、各計算期間末の属する月の2か月後応当月の最終営業日までの間で、本事業者が裁量により指定する日（以下「金銭配当日」という。）に、前項第（2）号④及び⑤に基づき各優先出資者及び本事業者に分配された当該計算期間に係る匿名組合利益（もしあれば。但し、前項第（2）号①ないし③に基づき匿名組合損失の補てんに充当された匿名組合利益は含まれない。）相当額の金銭を各優先出資者に支払い又は本事業者が収受するものとする。
                        </li>
                        <li>
                            前項にかかわらず、本事業者は、対象不動産の一部の売却等が行われた場合には、当該売却等が行われた日を計算期日とみなして、前三項を準用して、遅滞なく、本出資者に対し匿名組合損益及び金銭の分配を行うものとする。
                        </li>
                        <li>
                            本条に基づき分配された匿名組合損失については、同額の出資の払戻しとして会計処理する。また、当該匿名組合損失が本条に基づき匿名組合利益によって補てんされた場合、同額について出資の増加があったものとして会計処理します。
                        </li>
                    </ul>
                </dd>

                <dt><h3 className="pt-5 text-lg font-bold">税務</h3></dt>
                <dd>
                    利益分配金：雑所得（20.42%の源泉徴収が行われます。）<br/>
                    事業参加者へ「支払調書」を発行いたします。※マイナンバーの提出が必要となります。
                </dd>
            </dl>
            </section>
            <section className="mt-10">
                <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">12.不動産特定共同事業契約に係る財産の管理に関する事項について</h2>
                <dl>
                    <dt><h3 className="pt-5 text-lg font-bold">財産の管理等に関する事項</h3></dt>
                    <dd>
                         <ul className="max-[500px]:ml-5 ml-6 md:ml-10 list-decimal leading-7">
                            <li>
                                本事業者は不動産特定共同事業法第27条に基づき、本契約に係る財産を、本事業者の固有財産及び他の不動産特定共同事業契約に係る財産と分別して管理します。
                            </li>
                            <li>
                                匿名組合勘定の分別管理は、信託法（平成18年法律第108号）第34条の分別管理とは異なり、本事業者が破産等した場合には、本契約に係る財産は保全されない可能性があります。
                            </li>
                            <li>
                                本事業者は対象不動産の維持・管理の為に修繕費、損害保険料を本事業から生ずる収益から負担いたします。
                            </li>
                        </ul>
                    </dd>
                </dl>
            </section>

                    <section className="mt-10">
                         <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-xl font-bold tracking-[1px]">13.契約に関する事項</h2>
                        <dl>
                            <dt><h3 className="pt-5 text-lg font-bold">契約期間</h3></dt>
                            <dd>
                            本契約の契約期間は、<span>{document.operation_start_time}</span>から<span>{document.operation_finish_time}</span>までとします。<br/>
                            但し、本契約の契約期間内に対象不動産全部の売却等が完了しない場合には、本事業者は、本契約の契約期間の満了日の2か月前までに本出資者に書面又は電磁的方法により通知をすることにより、6か月を超えない範囲で本契約の契約期間を延長することができます。
                        </dd>
                            <dt><h3 className="pt-5 text-lg font-bold">契約終了時の清算</h3></dt>
                            <dd>
                                <ul className="max-[500px]:ml-5 ml-6 md:ml-10 list-decimal leading-7">
                                <li>
                                    本契約は、以下のいずれかの事由が生じた場合に終了します。かかる事由の発生により本契約が終了した場合、本事業者は、本出資者に直ちに通知するものとします。
                                    <ul className="leading-7">
                                        <li>
                                            （1）本契約の契約期間の満了

                                        </li>
                                        <li>
                                            （2）対象不動産全部の売却等の完了
                                        </li>
                                        <li>
                                            （3）本事業の継続の不能
                                        </li>
                                        <li>
                                            （4）本事業者に係る破産手続開始の決定
                                        </li>
                                        <li>
                                            （5）出資総額が出資予定総額に満たない場合に本事業者が自ら出資を行わないとき、その他、やむを得ない事由があるとき。
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    本契約が終了した場合、本事業者は、本事業において金銭以外の資産があればこれを換価処分した上、本事業に係る資産から本事業者報酬を含む本事業に係る一切の債務を弁済し、速やかに最終の計算期間に係る匿名組合損益及び本出資者に分配すべき匿名組合損益を確定し、本事業に属する金銭から清算手続に要する費用その他の残余財産から支払われるべき費用を控除した金額をもって、以下の順序で優先出資者及び本事業者に対して出資の価額の返還を行うものとします。
                                    <ul className="leading-7">
                                        <li>
                                            （1）優先出資総額に満つるまでの金額をもって、優先出資割合に応じて各事業参加者に対して出資の価額を返還します。
                                        </li>
                                        <li>
                                            （2）（1）の返還後になお残額がある場合、本事業者に対して出資の価額を返還します。
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    出資の価額の返還は本契約終了日の2か月後の末日までに送金いたします。
                                </li>
                            </ul>
                        </dd>

                        <dt><h3 className="pt-5 text-lg font-bold">契約の解除について</h3></dt>
                        <dd>
                            <ul className="max-[500px]:ml-5 ml-6 md:ml-10 list-decimal leading-7">
                                <li>
                                    本出資者は、やむを得ない事由が存在する場合には、本事業者に対して書面によって通知することにより、本契約を解除することができます。また、本出資者が死亡した場合又は後見開始の審判を受けた場合には、その相続人又は成年後見人は、本事業者に対して書面によって通知することにより、本契約を解除することができます。
                                </li>
                                <li>
                                    本出資者が破産手続開始の決定を受けた場合には、本契約は当然に終了します。
                                </li>
                                <li>
                                    前二項の規定によって本契約が終了した場合、本事業者は、第8条第4項に準じて、速やかに本出資者に分配すべき本契約の終了日までの匿名組合損益を算定し、当該損益を分配した場合に生ずる本出資者に対する債権債務を計上した上で、本出資者に対し、出資の価額の返還として、本出資者の出資額から本契約の終了日までに本出資者に分配された匿名組合損失（もしあれば）を控除した額（但し、本契約の終了日までに第8条第4項第（2）号②に従って補てんされた金額を加算する。）（但し、当該額が本事業の純資産額（本事業に係る資産の価額から負債の価額を控除した額をいう。以下同じ。）に本出資者の優先出資割合を乗じた金額を上回る場合には、本事業の純資産額に本出資者の優先出資割合を乗じた金額）を支払うものとします。
                                </li>
                                <li>
                                    前一項による本出資者からの本契約の解除の申し入れがあった場合、本出資者は前三項により算出された出資の価額の3％（別途消費税）を解除手数料として本事業者へ支払うものとします。
                                </li>
                                <li>
                                    本出資者及び本事業者は、本事業に対して出資を行う匿名組合契約の解除が多発したときは、本事業を継続できなくなるおそれがあることを確認します。
                                </li>
                            </ul>
                        </dd>

                        <dt><h3 className="pt-5 text-lg font-bold">クーリングオフ</h3></dt>
                        <dd>
                           <ul className="max-[500px]:ml-5 ml-6 md:ml-10 list-decimal leading-7">
                                <li>
                                    本出資者は、不動産特定共同事業契約成立時の書面の交付を受けた日から起算して8日を経過するまでの間、本事業者に対して書面によって通知することにより、本契約を解除することができます。
                                </li>
                                <li>
                                    前1項に基づく解除は、本出資者が本契約の解除を行う旨の書面を発したときに効力が生じます。本契約の解除によって、事業参加者は何らの手続を要することなく当然に本事業に係る事業参参加者でなかったものとします。
                                </li>
                                <li>
                                    前1項に基づき本契約が解除された場合、本事業者は、本出資者に対し、出資金額を返還するものとし、その解除に伴う損害賠償又は違約金の支払を請求することはできないものとします。
                                </li>
                            </ul>
                        </dd>
                    </dl>
                </section>

                    <section className="mt-10">
                    <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">14.不動産特定共同事業契約の変更について</h2>
                    <dl>
                        <dt><h3 className="pt-5 text-lg font-bold">変更手続き及び開示の方法</h3></dt>
                        <dd>
                            本契約の変更は行いませんので、開示はございません。<br/>
                            事業参加者は、本契約につき下記の内容に変更がある際は、変更を証する書面及び所定の届出を提出することとします。
                            <ul className="ml-5 leading-7">
                                <li>
                                    （1）  事業参加者本人の氏名の変更
                                </li>
                                <li>
                                    （2）  事業参加者の住所、連絡先、メールアドレス
                                </li>
                                <li>
                                    （3）事業参加者の商号及び代表者の変更（法人の場合）
                                </li>
                            </ul>
                        </dd>
                    </dl>
                </section>

                    <section className="mt-10">
                    <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">15.不動産特定共同事業者の報酬に関する事項</h2>
                    <dl>
                        <dt><h3 className="pt-5 text-lg font-bold">計算方法</h3></dt>
                        <dd>
                            <ul className="max-[500px]:ml-5 ml-6 md:ml-10 list-decimal leading-7">
                                <li>
                                    本事業者は、本契約に定める業務執行の対価として以下の金額（以下「本事業者報酬」という。）を本事業に係る資産から本事業者の固有財産とする（但し、第（4）号に定める報酬については本出資者から受領する）ことができるものとする。
                                </li>
                                <li>
                                    対象不動産の全部又は一部の売却等の対価として、第8条第6項及び第10条第2項の金銭の分配時に、売却等の価格の2％本契約上の地位の譲渡に伴う事務手続の対価として、譲渡の完了時に、譲渡価格の3％を支払うものとする。
                                </li>
                                <li>
                                    本契約の解除に伴う事務手数料の対価として、解除の完了時に第12条第3項により算出された出資の価額の3％を解除手数料として本事業者へ支払うものとする。
                                </li>
                            </ul>
                        </dd>

                        <dt><h3 className="pt-5 text-lg font-bold">支払方法</h3></dt>
                        <dd>
                            <ul className="max-[500px]:ml-5 ml-6 md:ml-10 list-decimal leading-7">
                                <li>
                                    本事業者報酬や売却等の報酬については、本事業に関わる費用として本事業に係る財産からお支払いを受けるものとします。
                                </li>
                                <li>
                                    譲渡に伴う事務手数料および出資者による本契約の解除に伴う事務手数料については、本事業者が譲渡価額又は解約価額から差引く方法によりお支払いいただきます。
                                </li>
                                <li>
                                    本事業者の指定する口座へ振込（振込手数料はご負担頂きます）
                                </li>
                            </ul>
                        </dd>
                    </dl>
                </section>

                <section className="mt-10">
                    <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">16.対象不動産の所有権の帰属に関する事項</h2>
                    <dl>
                        <dt><h3 className="pt-5 text-lg font-bold">対象不動産の所有権の帰属</h3></dt>
                        <dd>
                            本事業に取得した対象不動産の建物の所有権は、すべて本事業者に帰属します。
                        </dd>
                    </dl>
                </section>
                   <section className="mt-10">
                    <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">17.不動産特定共同事業の実施により予想される損失発生要因に関する事項</h2>
                    <dl>
                        <dt><h3 className="pt-5 text-lg font-bold">本事業者の業務又は財産の状況変化</h3></dt>
                        <dd>
                            <ul className="max-[500px]:ml-5 ml-6 md:ml-10 list-decimal leading-7">
                                <li>
                                    本事業者は本事業及び本事業以外の事業の実施において、経済市況や景気の影響を受けて財務状況の変化により本事業者が事業の継続ができない場合、又は破綻した際は、本事業は終了しますが、出資の価額の返還において、当初の出資の価額より下回る場合、又は返還されないおそれがあります。
                                </li>
                                <li>
                                    本事業の対象不動産は本事業開始後、借入がございませんが、本事業者は本事業以外の固有財産の不動産に係る借入をする場合又は借入がある場合においても前項同様の要因による影響を受ける場合があります。
                                </li>
                            </ul>
                        </dd>

                        <dt><h3 className="pt-5 text-lg font-bold">契約上の権利</h3></dt>
                        <dd>
                            <ul className="max-[500px]:ml-5 ml-6 md:ml-10 list-decimal leading-7">
                                <li>
                                    本契約の契約上の地位の譲渡は可能ですが、当初の出資の価額を下回る可能性があります。また、地位の譲渡が多発した場合は、本事業者による地位の譲渡が行えない可能性があります。
                                </li>
                                <li>
                                    営業者以外の第三者に譲渡する場合の譲渡の価額は、本出資者と譲渡の相手方にて決めていただく必要がありますが、当初の出資の価額より下回る可能性があります。
                                </li>
                                <li>
                                    対象不動産の賃貸借契約先であるテナントの賃借人が事業の不振による中途解約、賃料の滞納及び、テナントの退去により次の新しいテナントが決定する期間までは賃貸収入がないこと及び、当初の想定賃料の下落の要因により賃貸収入が予定より下回る可能性があります。その場合、当初想定する利益分配金の支払いが下落する可能性、又は支払いができない期間があります。
                                </li>
                                <li>
                                    本事業の対象不動産の所有権は本事業者に帰属しますので、契約期間の満了後、又は売却が完了するまでの期間を除き、地位の譲渡ができない可能性があります。
                                </li>
                                <li>
                                    本契約の解除はクーリングオフ期間及び、やむを得ない事由等が生じた場合を除いて認められておりません。また、出資持分（匿名組合員たる地位）の第三者への譲渡に際しては、本事業者の裁量により、譲渡の可否を判断することから、譲渡することができない場合があります。
                                </li>
                            </ul>
                        </dd>

                        <dt><h3 className="pt-5 text-lg font-bold">金融商品市場の変動及び不動産の滅失、毀損、劣化及び環境等</h3></dt>
                        <dd>
                            <ul className="max-[500px]:ml-5 ml-6 md:ml-10 list-decimal leading-7">
                                <li>
                                    対象不動産は経済市況や景気の影響等を受けて価額が変動する場合があり、売却時に当初の価格より下落した場合、出資の価額の返還において、当初の出資の価額を下回る可能性があります。
                                </li>
                                <li>
                                    対象不動産の全部、一部が天災等の災害によって滅失、毀損、劣化した場合や土壌汚染等の隠れたる瑕疵が見つかった場合等で本事業の継続が著しく困難であると本事業者が合理的に判断した場合、又は対象不動産を途中で売却した場合には本契約を終了する場合がありますが、出資の価額の返還において、当初の出資の価額より下回る場合、又は返還されないおそれがあります。
                                </li>
                                <li>
                                    本事業に生じた余裕金は、法施行規則第11条第2項第14号に掲げる方法により運用するものとします。そのため金融機関の破綻等により損失を被ることがあります。
                                </li>
                                <li>
                                    地位の譲渡については、十分な市場が存在せず流動性が低いものであります。
                                </li>
                                <li>
                                    本不動産特定共同事業者が組合員たる地位を買い取る価格は、対象不動産から生じる不動産の事業損益および経済的要因により変動するため、当初出資金を割り込むことがございます。
                                </li>
                                <li>
                                    本契約は、対象不動産の不動産取引から生じる損益の分配を受ける商法上の匿名組合契約でございます。
                                </li>
                            </ul>
                        </dd>

                        <dt><h3 className="pt-5 text-lg font-bold">本契約上の権利を行使することができる期間の制限または本契約の解除をすることができる期間の制限について</h3></dt>
                        <dd>
                            本契約解除について、クーリングオフ（法第26条に基づく解除）については解約できる期間に制限があります。
                        </dd>

                        <dt><h3 className="pt-5 text-lg font-bold">税制変更</h3></dt>
                        <dd>
                            税制の変更により、事業参加者及び本事業者の税負担の増加により、当初の想定する利益分配が下落することがございます。
                        </dd>
                    </dl>
                </section>

                <section className="mt-10">
                    <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">18.不動産特定共同事業に係る不動産取引から損失が生じた場合における当該損失の負担に関する事項</h2>
                    <dl>
                        <dt><h3 className="pt-5 text-lg font-bold">損失の分担の責任</h3></dt>
                        <dd>
                            <ul className="max-[500px]:ml-5 ml-6 md:ml-10 list-decimal leading-7">
                                <li>
                                    本事業者は、事業参加者に対し、優先出資金の元本の返還を保証する義務を負いません。
                                </li>
                                <li>
                                    本出資者の損失の分担額は、優先出資金を限度とします。
                                </li>
                            </ul>
                        </dd>
                    </dl>
                </section>

                <section className="mt-10">
                    <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">19.業務及び財産の状況に係る情報の開示に関する事項</h2>
                    <dl>
                        <dt><h3 className="pt-5 text-lg font-bold">業務及び財産の状況に係る情報の開示に関する事項</h3></dt>
                        <dd>
                           <ul className="max-[500px]:ml-5 ml-6 md:ml-10 list-decimal leading-7">
                                <li>
                                    本事業者は、毎年1回12月末日までに、法第28条第2項に定める本事業に係る財産の管理の状況について報告書を作成し、本出資者に対し書面により交付し、又は電磁的方法により提供するものとします。
                                </li>
                                <li>
                                    本事業者は、本出資者が請求する場合には、財産の管理の状況について説明するものとします。
                                </li>
                                <li>
                                    本事業者は、法第29条に定める本事業に係る業務及び財産の状況を記載した書類を事業所ごとに備え置き、本出資者の請求に応じてこれを閲覧させるものとします。
                                </li>
                                <li>
                                    本事業者は、法第30条第1項に定める本事業に係る事業参加者名簿を作成して保存し、本出資者の請求に応じてこれを閲覧させるものとします。
                                </li>
                            </ul>
                        </dd>
                    </dl>
                </section>

                <section className="mt-10">
                    <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">20.対象不動産の売却に関する事項</h2>
                    <dl>
                        <dt><h3 className="pt-5 text-lg font-bold">対象不動産の売却に関する事項</h3></dt>
                        <dd>
                            <ul className="max-[500px]:ml-5 ml-6 md:ml-10 list-decimal leading-7">
                                <li>
                                    本事業者は、対象不動産の売却が相当と判断するときは、売却することができるものとします。但し、売却により事業参加者の出資の価額が当初の出資額を下回る場合は、契約期間満了時まではできないものとします。
                                </li>
                                <li>
                                    本事業者は、対象不動産の全部の売却処分が終了した場合には、速やかに事業参加者に出資の価額を返還します。
                                </li>
                            </ul>
                        </dd>
                    </dl>
                </section>

                <section className="mt-10">
                    <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">21.事業参加者の契約上の権利及び義務の譲渡に関する事項</h2>
                    <dl>
                        <dt><h3 className="pt-5 text-lg font-bold">事業参加者の契約上の権利及び義務の譲渡の可否、条件、方法</h3></dt>
                        <dd>
                            <ul className="max-[500px]:ml-5 ml-6 md:ml-10 list-decimal leading-7">
                                <li>
                                    本出資者は、本事業者が定めた事前の書面又は電磁的方法による承諾がある場合に限り、本契約上の地位を譲渡することができます。但し、本事業者は、当該承諾を正当な理由なく拒否できないものとします。
                                </li>
                                <li>
                                    本出資者は、第1項に基づき本契約上の地位を譲渡する場合、本事業者に対し、当該譲渡の代理又は媒介に係る契約の締結を申し込むことができます。かかる申込みがあった場合、本事業者は、当該契約の締結を正当な理由なく拒否できないものとする。なお、本出資者が本事業者に対して当該譲渡の代理又は媒介に係る業務を委託する場合、本出資者は、本事業者と別途合意するところにより、本事業者に対し、当該業務に係る報酬を支払うものとします。
                                </li>
                                <li>
                                    地位の譲渡による、新たな事業参加者は本契約と同一条件にて承継することを同意し、本契約を締結するものとします。
                                </li>
                                <li>
                                    本事業者に譲渡をする場合は、本事業者が相当と認めた場合に限るものとし、本契約上の地位の譲渡が多発した場合は、本事業者への譲渡が行えない場合があり、本事業を継続ができなくなるおそれがございます。
                                </li>
                            </ul>
                        </dd>

                        <dt><h3 className="pt-5 text-lg font-bold">譲渡に係る手数料</h3></dt>
                        <dd>
                            本出資者は本契約上の地位を譲渡した場合、本事業者に対し本契約上の地位の譲渡に伴う事務手続の対価として、譲渡価格の3％（別途消費税）を支払うものとします。
                        </dd>

                        <dt><h3 className="pt-5 text-lg font-bold">譲渡価格（出資の価額）の算定方法</h3></dt>
                        <dd>
                            <ul className="max-[500px]:ml-5 ml-6 md:ml-10 list-decimal leading-7">
                                <li>
                                    本事業者以外の第三者に譲渡する場合は、事業参加者と新たな事業参加者で合意した譲渡価額。
                                </li>
                                <li>
                                    本事業者に譲渡する場合、当初の出資の価額を上限に事業参加者と本事業者で合意した出資の価額。
                                </li>
                            </ul>
                        </dd>

                        <dt><h3 className="pt-5 text-lg font-bold">支払方法及び支払時期</h3></dt>
                        <dd>
                            <ul className="max-[500px]:ml-5 ml-6 md:ml-10 list-decimal leading-7">
                                <li>
                                    本事業者以外の第三者に譲渡する場合は、事業参加者と新たな出資者で定めた期日とします。
                                </li>
                                <li>
                                    （1）本事業者に譲渡する場合、本事業者が別途定める必要書類を、受領し承諾した期日（譲渡日）から起算して2か月後応当月の最終営業日までの間で、本事業者が裁量により指定する日に利益相当額の金銭及び出資の価額を事業参加者が予め指定した金融機関の口座へ振込によりお支払いします。<br/>
                                    （2）振込手数料は本事業の収益より負担いたします。<br/>
                                    （3）各計算期日の利益計算の結果、損失が生じた場合、利益分配金はお支払いいたしません。
                                </li>
						    </ul>
                        </dd>
                    </dl>
                </section>

                <section className="mt-10">
                    <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">22.業務上の余裕金の運用に関する事項について</h2>
                    <dl>
                        <dt><h3 className="pt-5 text-lg font-bold">業務上の余裕金の運用</h3></dt>
                        <dd>
                            本事業に関し生じた余裕金を、法施行規則第11条第2項第14号に掲げる方法により運用するものとします。
                        </dd>
                    </dl>
                </section>

                <section className="mt-10">
                    <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">23.対象不動産の変更に係る手続きに関する事項について</h2>
                    <dl>
                        <dt><h3 className="pt-5 text-lg font-bold">対象不動産の変更</h3></dt>
                        <dd>
                            変更はございません。
                        </dd>
                    </dl>
                </section>

                <section className="mt-10">
                    <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">24.不動産特定共同事業に基づき出資された財産のうち、不動産特定共同事業の業務に係る金銭以外の金銭の運用に関る事項について</h2>
                    <dl>
                        <dt><h3 className="pt-5 text-lg font-bold">金銭以外の運用</h3></dt>
                        <dd>
                            運用はごぜいません。
                        </dd>
                    </dl>
                </section>

                <section className="mt-10">
                    <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">25.当該不動産特定共同事業に関する訴訟について管轄権を有する裁判所の名称及び所在地</h2>
                    <dl>
                        <dt><h3 className="pt-5 text-lg font-bold">裁判所の名称</h3></dt>
                        <dd>
                            東京地方裁判所
                        </dd>

                        <dt><h3 className="pt-5 text-lg font-bold">裁判所の所在地</h3></dt>
                        <dd>
                            東京都千代田区霞が関1丁目1−4
                        </dd>
                    </dl>
                </section>
                <section className="mt-10">
                    <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">26.施行規則第54条第2号に規定する措置の概要及び当該不動産特定共同事業契約に関する当該措置の実施結果の概要</h2>
                    <dl>
                        <dt><h3 className="pt-5 text-lg font-bold">財務状況</h3></dt>
                        <dd>
                            本事業者の商品企画責任者が、財務諸表等により本事業者の財務状況を把握し、事業計画と本事業の適否を判断します。
                        </dd>

                        <dt><h3 className="pt-5 text-lg font-bold">事業計画の内容</h3></dt>
                        <dd>
                            不動産特定共同事業については、十分な調査と審査が実施されるような体制が構築されており、具体的には、本事業者の購買専門部署が、物件の立地や周辺環境、登記情報、賃料相場、入居者の需要を調査した上で、収支計画等を作成し、事業の適否を審査します。
                        </dd>

                        <dt><h3 className="pt-5 text-lg font-bold">資金調達額及び資金使途</h3></dt>
                        <dd>
                            調達する資金の調達額及びその使途の妥当性（事業計画との整合性）について確認を行います。
                        </dd>

                        <dt><h3 className="pt-5 text-lg font-bold">分別管理の状況</h3></dt>
                        <dd>
                            預金通帳の保存状況など分別管理の状況について確認を行います。
                        </dd>

                        <dt><h3 className="pt-5 text-lg font-bold">電子取引業務の対象とすることの適否の判断</h3></dt>
                        <dd>
                            上記資料等を基に、本事業者の商品企画担当者は、販売計画書および運用計画書を作成し、当該計画や募集の合理性等を検討し、不動産特定共同事業の適否を審査します。ここで適正と判断された場合、商品企画責任者の審査の上、最終的な投資および電子取引業務の対象とすることの適否が判断されます。
                        </dd>

                        <dt><h3 className="pt-5 text-lg font-bold">上記措置の実施結果</h3></dt>
                        <dd>
                            本事業者は、本事業に関し、上記各措置を適切に実施しています。
                        </dd>
                    </dl>
                </section>

                <section className="mt-10">
                    <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">27.電子取引業務における概要</h2>
                    <dl className="leading-7">
                        <dt><h3 className="pt-5 text-lg font-bold">電子取引業務として行う旨</h3></dt>
                        <dd>
                            本事業者は、本事業に関し、電子取引業務として行います。
                        </dd>

                        <dt><h3 className="pt-5 text-lg font-bold">電子取引業務に関する照会に係る連絡方法</h3></dt>
                        <dd>
                            下記URL内の問い合わせページにて必要事項を入力の上、送信します。<br/>
                            <a className="underline underline-offset-4 text-primary-main" href="https://www.tomotaqu.com/">https://www.tomotaqu.com/</a>
                        </dd>

                        <dt><h3 className="pt-5 text-lg font-bold">不動産特定共同事業者等と電子取引業務を行う不動産特定共同事業者等との利害関係の有無</h3></dt>
                        <dd>
                            無（自己募集のため、該当ございません。）
                        </dd>

                        <dt><h3 className="pt-5 text-lg font-bold">「審査結果」の公表</h3></dt>
                        <dd>
                            電子取引業務を行う不動産特定共同事業者等は、審査の概要及び当該実施結果の概要について、契約成立前書面の説明事項として申込者に対し説明するものとし（規則第43条第1項第43号）、また、投資家の判断に重要な影響を与える事項として、自らのホームページ等において、投資家が閲覧することができる状態に置く必要がある（規則第55条）。これらに加えて、電子取引業務を行う不動産特定共同事業者等は、不動産特定共同事業者等について、審査の内容（各審査項目の確認方法及びその確認結果）、当該審査の過程において把握した留意点及び当該審査の結果の判断に至る理由を、その運営するホームページに掲載する等の方法により、顧客等に対して公表することが期待される。
                        </dd>
                    </dl>
                </section>
                <section className="mt-10">
                    <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">28.その他</h2>
                    <dl>
                        <dt></dt>
                        <dd>
                            <p dangerouslySetInnerHTML={{__html: document.others}}></p>
                        </dd>
                    </dl>
                </section>
                <p><br/>以下余白</p>
                </div>
            </FundPrivacyCheckLayout>
            
            <FundPrivacyCheckLayout isRead={agreement?.third} title='電子取引業務に係る重要事項' setAgreement={() => {
                handleAgreement('third')
                }}>
                <div className='flex flex-col gap-5'>
                    <header>
                        <h2 className='text-center font-bold text-xl'>
                            <br/>
                            電子取引業務に係る重要事項
                        </h2>
                    </header>
                    <div className="mb-10 sm:px-5 md:px-10 tracking-[1.5px] leading-7">
                        <section className="mt-10">
                            <p>
                                不動産特定共同事業法（以下「法」といい、同法施行規則を「施行規則」といいます。）第31条の2及び金融商品の販売等に関する法律（以下「金融商品販売法」といいます。）第3条の規定に基づき、商品の概要と重要事項の説明を致します。この内容は重要ですので、充分にご理解いただきますようお願い申し上げます。
                            </p>
                            <p className="mt-3">
                                なお、不動産特定共同事業商品の契約に際しては、本内容のほかに、契約成立前書面及び本契約の内容も十分にご確認及びご理解いただきますよう、お願い申し上げます。
                            </p>
                        </section>
            
                        <section className="mt-10">
                            <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">不動産特定共同事業者に関する事項</h2>
                            <dl className="sm:text-lg py-3 mt-3">
                                <dt className="font-bold">商号</dt>
                                <dd>
                                     <span>{document.comp_name}</span>
                                </dd>
                            </dl>
                            <dl className="sm:text-lg py-3">
                                <dt className="font-bold">住所</dt>
                                <dd>
                                    <span>{document.comp_address}</span>
                                </dd>
                            </dl>
                            <dl className="sm:text-lg py-3">
                                <dt className="font-bold">代表者の氏名</dt>
                                <dd>
                                    <span>{document.name_of_representative}</span>
                                </dd>
                            </dl>
                            <dl className="sm:text-lg py-3">
                                <dt className="font-bold">許可番号</dt>
                                <dd>
                                    東京都知事 第133号
                                </dd>
                            </dl>
                            <dl className="sm:text-lg py-3">
                                <dt className="font-bold">資本金</dt>
                                <dd>
                                    <span>{numberWithCommas(document.capital)}円</span>
                                </dd>
                            </dl>
                            <dl className="sm:text-lg py-3">
                                <dt className="font-bold">発行済株式総数の100分の5以上の株式を有する株主の商号若しくは名称又は氏名</dt>
                                <dd>
                                    <span>{document.shareholders}</span>
                                </dd>
                            </dl>
                            <dl className="sm:text-lg py-3">
                                <dt className="font-bold">不動産特定共同事業者（本事業者）が発行済株式の総数または出資の総額を契約締結法人により保有されている法人であって施行規則第10条各号に掲げる要件に該当するものであるときは、その営む不動産特定共同事業に関して当該契約締結法人が連帯して債務を負担する契約の内容</dt>
                                <dd>
                                    該当しません。
                                </dd>
                            </dl>
                            <dl className="sm:text-lg py-3">
                                <dt className="font-bold">不動産取引事業者が他に事業を行っているときは、その事業の種類</dt>
                                <dd>
                                    宅地建物取引業
                                </dd>
                            </dl>
                            <dl className="sm:text-lg py-3">
                                <dt className="font-bold">事業開始日を含む事業年度の直前三年の各事業年度の貸借対照表及び損益計算書の要旨</dt>
                                <dd>
                                    <a href="https://mypage.tomotaqu.com/electronic-document#financialDoc" rel="noreferrer" target="_blank">こちら</a>をご参照下さいませ。
                                </dd>
                            </dl>
                            <dl className="sm:text-lg py-3">
                                <dt className="font-bold">不動産特定共同事業者の役員の氏名</dt>
                                <dd>
                                    <span><p dangerouslySetInnerHTML={{__html:document.name_of_officers}}/></span>
                                </dd>
                            </dl>
                            <dl className="sm:text-lg py-3">
                                <dt className="font-bold">役員の氏名並びに役員が他の法人の常務に従事し、又は事業を営んでいるときは、当該他の法人の商号および業務</dt>
                                <dd>
                                    <span><p dangerouslySetInnerHTML={{__html:document.other_trade_names}}/></span>
                                </dd>
                            </dl>
                            <dl className="sm:text-lg py-3">
                                <dt className="font-bold">業務管理者名簿</dt>
                                <dd>
                                    <a className="text-primary-dark underline underline-offset-4" href="https://www.tomotaqu.com/business_manager/" rel="noreferrer" target="_blank">こちら</a>からご覧ください。
                                </dd>
                            </dl>
                        </section>
            
                        <section className="mt-10">
                            <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">対象不動産の特定および当該対象不動産に係る不動産取引の内容に関する次の事項について</h2>
                                <PropertyList data={document.property_list} />
                                <dl>
                                <dt className="text-lg font-bold mt-5">対象不動産に係る不動産取引の取引形態の別</dt>
                                <dd>
                                    本事業者が土地・建物を本事業者固有の財産にて所有
                                </dd>
            
                                <dt className="text-lg font-bold mt-5">対象不動産に係る借入及びその予定の有無</dt>
                                <dd>
                                    対象不動産に係る借入及びその予定はございません。
                                </dd>
            
                                <dt className="text-lg font-bold mt-5">不動産取引の開始予定日（本事業開始日）</dt>
                                <dd>
                                    <span>{document.operation_start_time}</span>
                                    <p className="mb-3">但し、申込期間満了前に出資総額に達した場合には、本事業者の判断で申込期間が短縮され、不動産取引の開始予定日を早めることがあります。</p>
                                    <p>また、申込期間満了後にクーリングオフにより解約が生じた場合には、本事業者の判断で追加の申込期間が設けられ、不動産取引の開始予定日を遅らせることがあります。</p>
                                </dd>
            
                                <dt className="text-lg font-bold mt-5">不動産取引の終了予定日</dt>
                                <dd>
                                    <span>{document.operation_finish_time}</span>
                                    <p className="mb-3">但し、当該終了予定日以前に、不動産市況その他の状況を踏まえ、対象物件の売却を完了し、本事業者の判断で不動産取引を終了することがあります。</p>
                                    <p>また、上記の契約期間内までに対象不動産全部の売却等が完了しない場合には、本事業者は、本契約の契約期間の満了日の２ヶ月前までに本出資者に書面又はは電磁的方法により通知することにより、６ヵ月を超えない範囲で本契約の契約期間を延長終了することができます。</p>
                                </dd>
                            </dl>
                        </section>
            
                        <section className="mt-10">
                            <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">対象不動産に関する事項</h2>
                            <p><a className="text-primary-dark underline underline-offset-4" href={document.matters_pdf || ''} rel="noreferrer" target="_blank">別紙添付資料</a>をご参照下さいませ。</p>
                        </section>
            
                        <section className="mt-10">
                            <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">対象不動産の価格及び当該価格の算定方法について</h2>
            
                            <dl>
                                <dt className="text-lg font-bold mt-5"></dt>
                                <dd>
                                    <table className="mt-10 w-full border-2 border-gray-200 bg-white">
                                        <tbody>
                                        <tr className="border-b-2 border-gray-200 text-sm sm:text-base">
                                            <th className="w-full block sm:table-cell sm:border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] sm:px-5">対象不動産の価格</th>
                                             <td colSpan={2} className="sm:border-r-2 block sm:table-cell border-gray-200 w-full sm:w-[65%] py-2 px-2">
                                                 <span>{document.price_of_target_estate}</span>円
                                            </td>
                                        </tr>
                                        <tr className="border-b-2 border-gray-200 text-sm sm:text-base">
                                            <th className="w-full block sm:table-cell sm:border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] sm:px-5">対象不動産の価格の算定方法</th>
                                             <td colSpan={2} className="sm:border-r-2 block sm:table-cell border-gray-200 w-full sm:w-[65%] py-2 px-2">
                                                <span style={{ whiteSpace: 'pre-wrap'}}>{document.calc_desc_of_price}</span>
                                            </td>
                                        </tr>
                                        <tr className="border-b-2 border-gray-200 text-sm sm:text-base">
                                            <th className="w-full block sm:table-cell sm:border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] sm:px-5">
                                                不動産鑑定士による鑑定評価の有無<br/>
                                                有の場合は、当該鑑定評価の結果及び方法並びに鑑定評価を行った者の氏名
                                            </th>
                                             <td colSpan={2} className="sm:border-r-2 block sm:table-cell border-gray-200 w-full sm:w-[65%] py-2 px-2">
                                                <span style={{ whiteSpace: 'pre-wrap'}}>{document.result_method_appraisal}</span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </dd>
                            </dl>
                        </section>

                    <section className="mt-10">
                        <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">出資を伴う契約の事項</h2>

						<table className="mt-10 w-full border-2 border-gray-200 bg-white">
                            <tbody>
							<tr className="border-b-2 border-gray-200 text-sm sm:text-base">
								<th className="w-full block sm:table-cell sm:border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] sm:px-5">収益又は利益の分配及び出資の価額の返還を受ける権利の名称</th>
								 <td colSpan={2} className="sm:border-r-2 block sm:table-cell border-gray-200 w-full sm:w-[65%] py-2 px-2">
									<span>{document.fund_name}</span>
								</td>
							</tr>
							<tr className="border-b-2 border-gray-200 text-sm sm:text-base">
								<th className="w-full block sm:table-cell sm:border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] sm:px-5">対象不動産の出資総額</th>
								 <td colSpan={2} className="sm:border-r-2 block sm:table-cell border-gray-200 w-full sm:w-[65%] py-2 px-2">
									金<span>{document.total_investment}</span>円（優先出資及び劣後出資総額の総和）
								</td>
							</tr>
							<tr className="border-b-2 border-gray-200 text-sm sm:text-base">
								<th className="w-full block sm:table-cell sm:border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] sm:px-5">優先出資募集総額</th>
								 <td colSpan={2} className="sm:border-r-2 block sm:table-cell border-gray-200 w-full sm:w-[65%] py-2 px-2">
									金<span>{document.amount_raised}</span>円
								</td>
							</tr>
							<tr className="border-b-2 border-gray-200 text-sm sm:text-base">
								<th className="w-full block sm:table-cell sm:border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] sm:px-5">募集総口数</th>
								 <td colSpan={2} className="sm:border-r-2 block sm:table-cell border-gray-200 w-full sm:w-[65%] py-2 px-2">
									<span>{document.number_kuchi}</span>口（優先出資総額口数）
								</td>
							</tr>
							<tr className="border-b-2 border-gray-200 text-sm sm:text-base">
								<th className="w-full block sm:table-cell sm:border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] sm:px-5">出資単位</th>
								 <td colSpan={2} className="sm:border-r-2 block sm:table-cell border-gray-200 w-full sm:w-[65%] py-2 px-2">
									<span>{document.min_num_units}</span>口以上（1口単位）1口：金<span>{numberWithCommas(document.kuchi)}</span>円
								</td>
							</tr>
							<tr className="border-b-2 border-gray-200 text-sm sm:text-base">
								<th className="w-full block sm:table-cell sm:border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] sm:px-5">申込の期間及び方法</th>
								 <td colSpan={2} className="sm:border-r-2 block sm:table-cell border-gray-200 w-full sm:w-[65%] py-2 px-2">
									 <span style={{whiteSpace:'pre-wrap'}}>{document.application_period_method}</span>
								</td>
							</tr>
							<tr className="border-b-2 border-gray-200 text-sm sm:text-base">
								<th className="w-full block sm:table-cell sm:border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] sm:px-5">申込取扱場所</th>
								 <td colSpan={2} className="sm:border-r-2 block sm:table-cell border-gray-200 w-full sm:w-[65%] py-2 px-2">
									<span>{document.comp_name}</span><span>{document.comp_address}</span><br/>
                                        TEL：<span>{document.comp_tel}</span>メール：<span>{document.comp_email}</span>
								</td>
							</tr>
							<tr className="border-b-2 border-gray-200 text-sm sm:text-base">
								<th className="w-full block sm:table-cell sm:border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] sm:px-5">払込の期日及び方法</th>
								 <td colSpan={2} className="sm:border-r-2 block sm:table-cell border-gray-200 w-full sm:w-[65%] py-2 px-2">
									開始前日（<span>{document.transfer_deadline})</span>まで<br/>
									払込の方法：本事業者の指定する銀行口座へ振込<br/>
									振込手数料は事業参加者（優先出資者）の負担といたします。
								</td>
							</tr>
							<tr className="border-b-2 border-gray-200 text-sm sm:text-base">
								<th className="w-full block sm:table-cell sm:border-r-2 border-gray-200 bg-gray-100 px-3 py-3 text-start sm:w-[35%] sm:px-5">出資の募集と対象不動産の取得</th>
								 <td colSpan={2}  className="sm:border-r-2 block sm:table-cell border-gray-200 w-full sm:w-[65%] py-2 px-2">
									 <span style={{whiteSpace:'pre-wrap'}}>{document.recruitment_invest_acquisition}</span>
								</td>
							</tr>
                            </tbody>
						</table>
			        </section>

                    <section className="mt-10">
                        <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">不動産特定共同事業契約に係る財産の管理に関する事項について</h2>
                        <dl>
                            <dt className="text-lg font-bold mt-5">財産の管理等に関する事項</dt>
                            <dd>
                                <ul className="list-decimal leading-7 ml-10">
                                    <li>
                                        本事業者は不動産特定共同事業法第27条に基づき、本契約に係る財産を、本事業者の固有財産及び他の不動産特定共同事業契約に係る財産と分別して管理します。
                                    </li>
                                    <li>
                                        匿名組合勘定の分別管理は、信託法（平成18年法律第108号）第34条の分別管理とは異なり、本事業者が破産等した場合には、本契約に係る財産は保全されない可能性があります。
                                    </li>
                                    <li>
                                        本事業者は対象不動産の維持・管理の為に修繕費、損害保険料を本事業から生ずる収益から負担いたします。
                                    </li>
                                </ul>
                            </dd>
                        </dl>
                    </section>
        
                    <section className="mt-10">
                        <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">契約に関する事項</h2>
                        <dl>
                            <dt className="text-lg font-bold mt-5">契約期間</dt>
                            <dd>
                                本契約の契約期間は、<span>{document.operation_start_time}</span>から<span>{document.operation_finish_time}</span>までとします。<br/>
                                但し、本契約の契約期間内に対象不動産全部の売却等が完了しない場合には、本事業者は、本契約の契約期間の満了日の2か月前までに本出資者に書面又は電磁的方法により通知をすることにより、6か月を超えない範囲で本契約の契約期間を延長することができます。
                            </dd>
        
                            <dt className="text-lg font-bold mt-5">契約終了時の清算</dt>
                            <dd>
                                <ul className="list-decimal ml-10 leading-7">
                                    <li>
                                        本契約は、以下のいずれかの事由が生じた場合に終了します。かかる事由の発生により本契約が終了した場合、本事業者は、本出資者に直ちに通知するものとします。
                                        <ul>
                                            <li>
                                                （1）本契約の契約期間の満了
                                            </li>
                                            <li>
                                                （2）対象不動産全部の売却等の完了
                                            </li>
                                            <li>
                                                （3）本事業の継続の不能
                                            </li>
                                            <li>
                                                （4）本事業者に係る破産手続開始の決定
                                            </li>
                                            <li>
                                                （5）出資総額が出資予定総額に満たない場合に本事業者が自ら出資を行わないとき、その他、やむを得ない事由があるとき。
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="pt-3">
                                        本契約が終了した場合、本事業者は、本事業において金銭以外の資産があればこれを換価処分した上、本事業に係る資産から本事業者報酬を含む本事業に係る一切の債務を弁済し、速やかに最終の計算期間に係る匿名組合損益及び本出資者に分配すべき匿名組合損益を確定し、本事業に属する金銭から清算手続に要する費用その他の残余財産から支払われるべき費用を控除した金額をもって、以下の順序で優先出資者及び本事業者に対して出資の価額の返還を行うものとします。
                                        <ul className="dBulnormal">
                                            <li className="dBlinormal">
                                                （1）優先出資総額に満つるまでの金額をもって、優先出資割合に応じて各事業参加者に対して出資の価額を返還します。
                                            </li>
                                            <li className="dBlinormal">
                                                （2）（1）の返還後になお残額がある場合、本事業者に対して出資の価額を返還します。
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="pt-3">
                                        出資の価額の返還は本契約終了日の2か月後の末日までに送金いたします。
                                    </li>
                                </ul>
                            </dd>
        
                            <dt className="text-lg font-bold mt-5">契約の解除について</dt>
                            <dd>
                                <ul className="list-decimal leading-7 ml-10">
                                    <li>
                                        本出資者は、やむを得ない事由が存在する場合には、本事業者に対して書面によって通知することにより、本契約を解除することができます。また、本出資者が死亡した場合又は後見開始の審判を受けた場合には、その相続人又は成年後見人は、本事業者に対して書面によって通知することにより、本契約を解除することができます。
                                    </li>
                                    <li>
                                        本出資者が破産手続開始の決定を受けた場合には、本契約は当然に終了します。
                                    </li>
                                    <li>
                                        前二項の規定によって本契約が終了した場合、本事業者は、第8条第4項に準じて、速やかに本出資者に分配すべき本契約の終了日までの匿名組合損益を算定し、当該損益を分配した場合に生ずる本出資者に対する債権債務を計上した上で、本出資者に対し、出資の価額の返還として、本出資者の出資額から本契約の終了日までに本出資者に分配された匿名組合損失（もしあれば）を控除した額（但し、本契約の終了日までに第8条第4項第（2）号②に従って補てんされた金額を加算する。）（但し、当該額が本事業の純資産額（本事業に係る資産の価額から負債の価額を控除した額をいう。以下同じ。）に本出資者の優先出資割合を乗じた金額を上回る場合には、本事業の純資産額に本出資者の優先出資割合を乗じた金額）を支払うものとします。
                                    </li>
                                    <li>
                                        前一項による本出資者からの本契約の解除の申し入れがあった場合、本出資者は前三項により算出された出資の価額の3％（別途消費税）を解除手数料として本事業者へ支払うものとします。
                                    </li>
                                    <li>
                                        本出資者及び本事業者は、本事業に対して出資を行う匿名組合契約の解除が多発したときは、本事業を継続できなくなるおそれがあることを確認します。
                                    </li>
                                </ul>
                            </dd>
        
                            <dt className="text-lg font-bold mt-5">クーリングオフ</dt>
                            <dd>
                                <ul className="list-decimal leading-7 ml-10">
                                    <li>
                                        本出資者は、不動産特定共同事業契約成立時の書面の交付を受けた日から起算して8日を経過するまでの間、本事業者に対して書面によって通知することにより、本契約を解除することができます。
                                    </li>
                                    <li>
                                        前1項に基づく解除は、本出資者が本契約の解除を行う旨の書面を発したときに効力が生じます。本契約の解除によって、事業参加者は何らの手続を要することなく当然に本事業に係る事業参参加者でなかったものとします。
                                    </li>
                                    <li>
                                        前1項に基づき本契約が解除された場合、本事業者は、本出資者に対し、出資金額を返還するものとし、その解除に伴う損害賠償又は違約金の支払を請求することはできないものとします。
                                    </li>
                                </ul>
                            </dd>
                        </dl>
                    </section>
        
                    <section className="mt-10">
                        <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">不動産特定共同事業契約の変更について</h2>
                        <dl>
                            <dt className="text-lg font-bold mt-5">変更手続き及び開示の方法</dt>
                            <dd>
                                本契約の変更は行いませんので、開示はございません。<br/>
                                事業参加者は、本契約につき下記の内容に変更がある際は、変更を証する書面及び所定の届出を提出することとします。
                                <ul className="leading-7 ml-3">
                                    <li>
                                        （1）  事業参加者本人の氏名の変更
                                    </li>
                                    <li>
                                        （2）  事業参加者の住所、連絡先、メールアドレス
                                    </li>
                                    <li>
                                        （3）事業参加者の商号及び代表者の変更（法人の場合）
                                    </li>
                                </ul>
                            </dd>
                        </dl>
                    </section>
        
                    <section className="mt-10">
                        <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">不動産特定共同事業者の報酬に関する事項</h2>
                        <dl>
                            <dt className="text-lg font-bold mt-5">計算方法</dt>
                            <dd>
                                <ul className="list-decimal leading-7 ml-10">
                                    <li>
                                        本事業者は、本契約に定める業務執行の対価として以下の金額（以下「本事業者報酬」という。）を本事業に係る資産から本事業者の固有財産とする（但し、第（4）号に定める報酬については本出資者から受領する）ことができるものとする。
                                    </li>
                                    <li>
                                        対象不動産の全部又は一部の売却等の対価として、第8条第6項及び第10条第2項の金銭の分配時に、売却等の価格の2％本契約上の地位の譲渡に伴う事務手続の対価として、譲渡の完了時に、譲渡価格の3％を支払うものとする。
                                    </li>
                                    <li>
                                        本契約の解除に伴う事務手数料の対価として、解除の完了時に第12条第3項により算出された出資の価額の3％を解除手数料として本事業者へ支払うものとする。
                                    </li>
                                </ul>
                            </dd>
        
                            <dt className="text-lg font-bold mt-5">支払方法</dt>
                            <dd>
                                <ul className="list-decimal leading-7 ml-10">
                                    <li>
                                        本事業者報酬や売却等の報酬については、本事業に関わる費用として本事業に係る財産からお支払いを受けるものとします。
                                    </li>
                                    <li>
                                        譲渡に伴う事務手数料および出資者による本契約の解除に伴う事務手数料については、本事業者が譲渡価額又は解約価額から差引く方法によりお支払いいただきます。
                                    </li>
                                    <li>
                                        本事業者の指定する口座へ振込（振込手数料はご負担頂きます）
                                    </li>
                                </ul>
                            </dd>
                        </dl>
                    </section>
        
                    <section className="mt-10">
                        <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">対象不動産の所有権の帰属に関する事項</h2>
                        <dl>
                            <dt className="text-lg font-bold mt-5">対象不動産の所有権の帰属</dt>
                            <dd>
                                本事業に取得した対象不動産の建物の所有権は、すべて本事業者に帰属します。
                            </dd>
                        </dl>
                    </section>
        
                    <section className="mt-10">
                        <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">不動産特定共同事業の実施により予想される損失発生要因に関する事項</h2>
                        <dl>
                            <dt className="text-lg font-bold mt-5">本事業者の業務又は財産の状況変化</dt>
                            <dd>
                                <ul className="list-decimal leading-7 ml-10">
                                    <li>
                                        本事業者は本事業及び本事業以外の事業の実施において、経済市況や景気の影響を受けて財務状況の変化により本事業者が事業の継続ができない場合、又は破綻した際は、本事業は終了しますが、出資の価額の返還において、当初の出資の価額より下回る場合、又は返還されないおそれがあります。
                                    </li>
                                    <li>
                                        本事業の対象不動産は本事業開始後、借入がございませんが、本事業者は本事業以外の固有財産の不動産に係る借入をする場合又は借入がある場合においても前項同様の要因による影響を受ける場合があります。
                                    </li>
                                </ul>
                            </dd>
        
                            <dt className="text-lg font-bold mt-5">契約上の権利</dt>
                            <dd>
                                <ul className="list-decimal leading-7 ml-10">
                                    <li>
                                        本契約の契約上の地位の譲渡は可能ですが、当初の出資の価額を下回る可能性があります。また、地位の譲渡が多発した場合は、本事業者による地位の譲渡が行えない可能性があります。
                                    </li>
                                    <li>
                                        営業者以外の第三者に譲渡する場合の譲渡の価額は、本出資者と譲渡の相手方にて決めていただく必要がありますが、当初の出資の価額より下回る可能性があります。
                                    </li>
                                    <li>
                                        対象不動産の賃貸借契約先であるテナントの賃借人が事業の不振による中途解約、賃料の滞納及び、テナントの退去により次の新しいテナントが決定する期間までは賃貸収入がないこと及び、当初の想定賃料の下落の要因により賃貸収入が予定より下回る可能性があります。その場合、当初想定する利益分配金の支払いが下落する可能性、又は支払いができない期間があります。
                                    </li>
                                    <li>
                                        本事業の対象不動産の所有権は本事業者に帰属しますので、契約期間の満了後、又は売却が完了するまでの期間を除き、地位の譲渡ができない可能性があります。
                                    </li>
                                    <li>
                                        本契約の解除はクーリングオフ期間及び、やむを得ない事由等が生じた場合を除いて認められておりません。また、出資持分（匿名組合員たる地位）の第三者への譲渡に際しては、本事業者の裁量により、譲渡の可否を判断することから、譲渡することができない場合があります。
                                    </li>
                                </ul>
                            </dd>
        
                            <dt className="text-lg font-bold mt-5">金融商品市場の変動及び不動産の滅失、毀損、劣化及び環境等</dt>
                            <dd>
                                <ul className="list-decimal leading-7 ml-10">
                                    <li>
                                        対象不動産は経済市況や景気の影響等を受けて価額が変動する場合があり、売却時に当初の価格より下落した場合、出資の価額の返還において、当初の出資の価額を下回る可能性があります。
                                    </li>
                                    <li>
                                        対象不動産の全部、一部が天災等の災害によって滅失、毀損、劣化した場合や土壌汚染等の隠れたる瑕疵が見つかった場合等で本事業の継続が著しく困難であると本事業者が合理的に判断した場合、又は対象不動産を途中で売却した場合には本契約を終了する場合がありますが、出資の価額の返還において、当初の出資の価額より下回る場合、又は返還されないおそれがあります。
                                    </li>
                                    <li>
                                        本事業に生じた余裕金は、法施行規則第11条第2項第14号に掲げる方法により運用するものとします。そのため金融機関の破綻等により損失を被ることがあります。
                                    </li>
                                    <li>
                                        地位の譲渡については、十分な市場が存在せず流動性が低いものであります。
                                    </li>
                                    <li>
                                        本不動産特定共同事業者が組合員たる地位を買い取る価格は、対象不動産から生じる不動産の事業損益および経済的要因により変動するため、当初出資金を割り込むことがございます。
                                    </li>
                                    <li>
                                        本契約は、対象不動産の不動産取引から生じる損益の分配を受ける商法上の匿名組合契約でございます。
                                    </li>
                                </ul>
                            </dd>
        
                            <dt className="text-lg font-bold mt-5">本契約上の権利を行使することができる期間の制限または本契約の解除をすることができる期間の制限について</dt>
                            <dd>
                                本契約解除について、クーリングオフ（法第26条に基づく解除）については解約できる期間に制限があります。
                            </dd>
        
                            <dt className="text-lg font-bold mt-5">税制変更</dt>
                            <dd>
                                税制の変更により、事業参加者及び本事業者の税負担の増加により、当初の想定する利益分配が下落することがございます。
                            </dd>
                        </dl>
                    </section>
        
                    <section className="mt-10">
                        <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">不動産特定共同事業に係る不動産取引から損失が生じた場合における当該損失の負担に関する事項</h2>
                        <dl>
                            <dt className="text-lg font-bold mt-5">損失の分担の責任</dt>
                            <dd>
                                <ul className="list-decimal leading-7 ml-10">
                                    <li>
                                        本事業者は、事業参加者に対し、優先出資金の元本の返還を保証する義務を負いません。
                                    </li>
                                    <li>
                                        本出資者の損失の分担額は、優先出資金を限度とします。
                                    </li>
                                </ul>
                            </dd>
                        </dl>
                    </section>
        
                    <section className="mt-10">
                        <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">業務及び財産の状況に係る情報の開示に関する事項</h2>
                        <dl>
                            <dt className="text-lg font-bold mt-5">業務及び財産の状況に係る情報の開示に関する事項</dt>
                            <dd>
                                <ul className="list-decimal leading-7 ml-10">
                                    <li>
                                        本事業者は、毎年1回12月末日までに、法第28条第2項に定める本事業に係る財産の管理の状況について報告書を作成し、本出資者に対し書面により交付し、又は電磁的方法により提供するものとします。
                                    </li>
                                    <li>
                                        本事業者は、本出資者が請求する場合には、財産の管理の状況について説明するものとします。
                                    </li>
                                    <li>
                                        本事業者は、法第29条に定める本事業に係る業務及び財産の状況を記載した書類を事業所ごとに備え置き、本出資者の請求に応じてこれを閲覧させるものとします。
                                    </li>
                                    <li>
                                        本事業者は、法第30条第1項に定める本事業に係る事業参加者名簿を作成して保存し、本出資者の請求に応じてこれを閲覧させるものとします。
                                    </li>
                                </ul>
                            </dd>
                        </dl>
                    </section>
        
                    <section className="mt-10">
                        <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">対象不動産の売却に関する事項</h2>
                        <dl>
                            <dt className="text-lg font-bold mt-5">対象不動産の売却に関する事項</dt>
                            <dd>
                                <ul className="list-decimal leading-7 ml-10">
                                    <li>
                                        本事業者は、対象不動産の売却が相当と判断するときは、売却することができるものとします。但し、売却により事業参加者の出資の価額が当初の出資額を下回る場合は、契約期間満了時まではできないものとします。
                                    </li>
                                    <li>
                                        本事業者は、対象不動産の全部の売却処分が終了した場合には、速やかに事業参加者に出資の価額を返還します。
                                    </li>
                                </ul>
                            </dd>
                        </dl>
                    </section>
        
                    <section className="mt-10">
                        <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">事業参加者の契約上の権利及び義務の譲渡に関する事項</h2>
                        <dl>
                            <dt className="text-lg font-bold mt-5">事業参加者の契約上の権利及び義務の譲渡の可否、条件、方法</dt>
                            <dd>
                                <ul className="list-decimal leading-7 ml-10">
                                    <li>
                                        本出資者は、本事業者が定めた事前の書面又は電磁的方法による承諾がある場合に限り、本契約上の地位を譲渡することができます。但し、本事業者は、当該承諾を正当な理由なく拒否できないものとします。
                                    </li>
                                    <li>
                                        本出資者は、第1項に基づき本契約上の地位を譲渡する場合、本事業者に対し、当該譲渡の代理又は媒介に係る契約の締結を申し込むことができます。かかる申込みがあった場合、本事業者は、当該契約の締結を正当な理由なく拒否できないものとする。なお、本出資者が本事業者に対して当該譲渡の代理又は媒介に係る業務を委託する場合、本出資者は、本事業者と別途合意するところにより、本事業者に対し、当該業務に係る報酬を支払うものとします。
                                    </li>
                                    <li>
                                        地位の譲渡による、新たな事業参加者は本契約と同一条件にて承継することを同意し、本契約を締結するものとします。
                                    </li>
                                    <li>
                                        本事業者に譲渡をする場合は、本事業者が相当と認めた場合に限るものとし、本契約上の地位の譲渡が多発した場合は、本事業者への譲渡が行えない場合があり、本事業を継続ができなくなるおそれがございます。
                                    </li>
                                </ul>
                            </dd>
        
                            <dt className="text-lg font-bold mt-5">譲渡に係る手数料</dt>
                            <dd>
                                本出資者は本契約上の地位を譲渡した場合、本事業者に対し本契約上の地位の譲渡に伴う事務手続の対価として、譲渡価格の3％（別途消費税）を支払うものとします。
                            </dd>
        
                            <dt className="text-lg font-bold mt-5">譲渡価格（出資の価額）の算定方法</dt>
                            <dd>
                                <ul className="list-decimal ml-10 leading-7">
                                    <li>
                                        本事業者以外の第三者に譲渡する場合は、事業参加者と新たな事業参加者で合意した譲渡価額。
                                    </li>
                                    <li>
                                        本事業者に譲渡する場合、当初の出資の価額を上限に事業参加者と本事業者で合意した出資の価額。
                                    </li>
                                </ul>
                            </dd>
        
                            <dt className="text-lg font-bold mt-5">支払方法及び支払時期</dt>
                            <dd>
                                <ul className="list-decimal ml-10 leading-7">
                                    <li>
                                        本事業者以外の第三者に譲渡する場合は、事業参加者と新たな出資者で定めた期日とします。
                                    </li>
                                    <li>
                                        （1）本事業者に譲渡する場合、本事業者が別途定める必要書類を、受領し承諾した期日（譲渡日）から起算して2か月後応当月の最終営業日までの間で、本事業者が裁量により指定する日に利益相当額の金銭及び出資の価額を事業参加者が予め指定した金融機関の口座へ振込によりお支払いします。<br/>
                                        （2）振込手数料は本事業の収益より負担いたします。<br/>
                                        （3）各計算期日の利益計算の結果、損失が生じた場合、利益分配金はお支払いいたしません。
                                    </li>
                                </ul>
                            </dd>
                        </dl>
                    </section>
        
                    <section className="mt-10">
                        <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">業務上の余裕金の運用に関する事項について</h2>
                        <dl>
                            <dt className="text-lg font-bold mt-5">業務上の余裕金の運用</dt>
                            <dd>
                                本事業に関し生じた余裕金を、法施行規則第11条第2項第14号に掲げる方法により運用するものとします。
                            </dd>
                        </dl>
                    </section>
        
                    <section className="mt-10">
                        <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">対象不動産の変更に係る手続きに関する事項について</h2>
                        <dl>
                            <dt className="text-lg font-bold mt-5">対象不動産の変更</dt>
                            <dd>
                                変更はございません。
                            </dd>
                        </dl>
                    </section>
        
                    <section className="mt-10">
                        <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">不動産特定共同事業に基づき出資された財産のうち、不動産特定共同事業の業務に係る金銭以外の金銭の運用に関る事項について</h2>
                        <dl>
                            <dt className="text-lg font-bold mt-5">金銭以外の運用</dt>
                            <dd>
                                運用はごぜいません。
                            </dd>
                        </dl>
                    </section>
        
                    <section className="mt-10">
                        <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">当該不動産特定共同事業に関する訴訟について管轄権を有する裁判所の名称及び所在地</h2>
                        <dl>
                            <dt className="text-lg font-bold mt-5">裁判所の名称</dt>
                            <dd>
                                東京地方裁判所
                            </dd>
        
                            <dt className="text-lg font-bold mt-5">裁判所の所在地</dt>
                            <dd>
                                東京都千代田区霞が関1丁目1−4
                            </dd>
                        </dl>
                    </section>
        
                    <section className="mt-10">
                        <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">施行規則第54条第2号に規定する措置の概要及び当該不動産特定共同事業契約に関する当該措置の実施結果の概要</h2>
                        <dl>
                            <dt className="text-lg font-bold mt-5">財務状況</dt>
                            <dd>
                                本事業者の商品企画責任者が、財務諸表等により本事業者の財務状況を把握し、事業計画と本事業の適否を判断します。
                            </dd>
        
                            <dt className="text-lg font-bold mt-5">事業計画の内容</dt>
                            <dd>
                                不動産特定共同事業については、十分な調査と審査が実施されるような体制が構築されており、具体的には、本事業者の購買専門部署が、物件の立地や周辺環境、登記情報、賃料相場、入居者の需要を調査した上で、収支計画等を作成し、事業の適否を審査します。
                            </dd>
        
                            <dt className="text-lg font-bold mt-5">資金調達額及び資金使途</dt>
                            <dd>
                                調達する資金の調達額及びその使途の妥当性（事業計画との整合性）について確認を行います。
                            </dd>
        
                            <dt className="text-lg font-bold mt-5">分別管理の状況</dt>
                            <dd>
                                預金通帳の保存状況など分別管理の状況について確認を行います。
                            </dd>
        
                            <dt className="text-lg font-bold mt-5">電子取引業務の対象とすることの適否の判断</dt>
                            <dd>
                                上記資料等を基に、本事業者の商品企画担当者は、販売計画書および運用計画書を作成し、当該計画や募集の合理性等を検討し、不動産特定共同事業の適否を審査します。ここで適正と判断された場合、商品企画責任者の審査の上、最終的な投資および電子取引業務の対象とすることの適否が判断されます。
                            </dd>
        
                            <dt className="text-lg font-bold mt-5">上記措置の実施結果</dt>
                            <dd>
                                本事業者は、本事業に関し、上記各措置を適切に実施しています。
                            </dd>
                        </dl>
                    </section>
        
                    <section className="mt-10">
                        <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">電子取引業務における概要</h2>
                        <dl>
                            <dt className="text-lg font-bold mt-5">電子取引業務として行う旨</dt>
                            <dd>
                                本事業者は、本事業に関し、電子取引業務として行います。
                            </dd>
        
                            <dt className="text-lg font-bold mt-5">電子取引業務に関する照会に係る連絡方法</dt>
                            <dd>
                                下記URL内の問い合わせページにて必要事項を入力の上、送信します。<br/>
                                <a className="text-primary-main underline-offset-4 underline" href="https://www.tomotaqu.com/">https://www.tomotaqu.com/</a>
                            </dd>
        
                            <dt className="text-lg font-bold mt-5">不動産特定共同事業者等と電子取引業務を行う不動産特定共同事業者等との利害関係の有無</dt>
                            <dd>
                                無（自己募集のため、該当ございません。）
                            </dd>
        
                            <dt className="text-lg font-bold mt-5">「審査結果」の公表</dt>
                            <dd>
                                電子取引業務を行う不動産特定共同事業者等は、審査の概要及び当該実施結果の概要について、契約成立前書面の説明事項として申込者に対し説明するものとし（規則第43条第1項第43号）、また、投資家の判断に重要な影響を与える事項として、自らのホームページ等において、投資家が閲覧することができる状態に置く必要がある（規則第55条）。これらに加えて、電子取引業務を行う不動産特定共同事業者等は、不動産特定共同事業者等について、審査の内容（各審査項目の確認方法及びその確認結果）、当該審査の過程において把握した留意点及び当該審査の結果の判断に至る理由を、その運営するホームページに掲載する等の方法により、顧客等に対して公表することが期待される。
                            </dd>
                        </dl>
                    </section>
        
                    <section className="mt-10">
                        <h2 className="border-l-4 sm:border-l-8 border-secondary-main px-2 sm:px-3 py-1 text-start text-lg sm:text-xl md:text-2xl font-bold tracking-[1px]">その他</h2>
                        <dl>
                            <dt className="text-lg font-bold mt-5"></dt>
                            <dd>
                                <p dangerouslySetInnerHTML={{__html: document.others}}></p>
                            </dd>
                        </dl>
                    </section>
        
                    <p><br/>以下余白</p>
                </div>
                </div>
            </FundPrivacyCheckLayout>
        </React.Fragment>

    );
};

export default FundPrivacyConformation;